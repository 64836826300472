import React from 'react';
import { FormGroup, Col, InputGroup, Input, InputGroupAddon, Button, ButtonGroup } from 'reactstrap';



const SearchComp = (props) => {
    return(
        <FormGroup row>
            <Col md={(props.btnAdd || props.handleCancel) ? "8" : "12"}>
                <InputGroup>                    
                    <Input type="text" id="input1-group2" placeholder={props.placeHolder} name="search" value={props.value} onChange={props.onChange} onKeyDown={props.onKeyDown}/>
                    <InputGroupAddon addonType="append">
                        <Button type="button" size="sm" color="primary" onClick={props.onClick}><i className="fa fa-search"></i> {props.btnTitle ? props.btnTitle : 'Cari'}</Button>
                    </InputGroupAddon>
                    { props.onFilter &&
                        <InputGroupAddon addonType="append">
                            <Button type="button" size="sm" color="warning" onClick={props.onFilter}><i className="fa fa-filter"></i> {props.btnFilter ? props.btnFilter : ''}</Button>
                        </InputGroupAddon>
                    }
                </InputGroup>
            </Col>
            { (props.btnAdd || props.handleCancel) &&
                <Col md={4}>
                    <ButtonGroup>
                        { props.btnAdd &&
                            <Button color="primary" onClick={props.btnAdd}><i className='fa fa-plus'></i></Button>
                        }
                        { props.handleCancel &&
                            <Button color="warning" onClick={props.handleCancel}>Cancel</Button>                    
                        }
                    </ButtonGroup>
                </Col>
            }
        </FormGroup>
    )
}
export default SearchComp;