import { useCallback, useEffect, useState } from "react";
import config from "../../../config";
import { useGlobalFuntction } from "../../../generalFunction/globalFunction";
import api from "../../../services/api";
import { pageObj } from "../../../utils/pageObj";
import Moment from 'moment';
import { useParams } from "react-router";
import { useGlobalModalFunction } from "../../../generalFunction/globalModalFunction";


export function usePengeluaran(props) {
    const {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, fetchAccount, fetchProdukSatuan, getPermission,
    } = useGlobalFuntction();
    const pageTipe = props && props.pageTipe ? props.pageTipe : 'pengeluaran_finance';
    const jenis_transaksi = props && props.jenis_transaksi ? props.jenis_transaksi : 'pengeluaran';
    
    const [listData, setListData] = useState([]);
    const tipe = props && props.tipe ? props.tipe : 'list';
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null);
    const [listDetail, setListDetail] = useState([]);
    const [pengeluaranDetail, setPengeleruanDetail] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const { modalState, modalDispatch, showConfirmCallToAction, setshowConfirmCallToAction } = useGlobalModalFunction();
    const [formData, setFormData] = useState({
        id: {
            value: '',
            required: false,
            showError: false,
            errorMsg: '',
            show: false,
            tipe: 'text',
            label: 'id',
            name: 'id',
            readOnly: true,
        },
        tanggal_value: {
            value: '',
            required: false,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'date',
            label: 'Tanggal',
            name: 'tanggal_value',            
        },
        perusahaan: {
            value: '',
            required: true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'select',
            label: 'Perusahaan',
            name: 'perusahaan',
            obj: [],
        },
        jenis_transaksi: {
            value: jenis_transaksi === 'penerimaan' ? 2 : 1,
            required: true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'select',
            label: 'Jenis Transaksi',
            name: 'jenis_transaksi',
            obj: [
                { value: 1, label: 'Pengeluaran' },
                { value: 4, label: 'Pengeluaran Operasional' }
            ],
        },        
        kendaraan_pemakaian_display: {
            value: 0,
            required: true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'textarea',
            label: 'Keterangan Kendaraan',
            name: 'kendaraan_pemakaian_display',
            readOnly: true,
            rows: 4
        },
        tanggal: {
            value: 0,
            required: true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'text',
            label: 'Tanggal',
            name: 'tanggal',
            readOnly: false,            
        },
        kendaraan: {
            value: 0,
            required: true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'text',
            label: 'Kendaraan',
            name: 'kendaraan',
            readOnly: true,            
        },
        jenis_pengeluaran: {
            value: '',
            required: true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'select',
            label: 'Jenis Pengeluaran',
            name: 'jenis_pengeluaran',
            obj: [
                { value: '', label: '-- Pilih Jenis Pengeluaran --' },
                { value: 1, label: 'Cash / Bank' },
                { value: 2, label: 'Hutang Pihak Ke Tiga' },
            ],
        },
        kontak: {
            value: null,
            required: false,
            showError: false,
            errorMsg: '',
            show: false,
            tipe: 'text',
            label: 'Pihak Ke 3',
            name: 'kontak',
            readOnly: true,
        },
        kontak_display: {
            value: '',
            required: false,
            showError: false,
            errorMsg: '',
            show: false,
            tipe: 'input_button',
            label: 'Pihak Ke 3',
            name: 'kontak_display',
            readOnly: true,
        },
        bank: {
            value: '',
            required: false,
            showError: false,
            errorMsg: '',
            show: false,
            tipe: 'select',
            label: 'Bank',
            name: 'bank',
            obj: [],
        },
        penerima: {
            value: '',
            required: jenis_transaksi === 'penerimaan' ? false : true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'text',
            label: 'Penerima',
            name: 'penerima',
        },
        jenis_bayar: {
            value: 'Cash',
            required: jenis_transaksi === 'penerimaan' ? false : true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'select',
            label: 'Jenis Pembayaran',
            name: 'jenis_bayar',
            obj: [
                { value: 'Cash', label: 'Cash' },
                { value: 'Cek', label: 'Cek' },
                { value: 'Giro', label: 'Giro' }
            ]
        },
        nomor_cek: {
            value: '',
            required: false,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'text',
            label: 'Nomor Cek',
            name: 'nomor_cek',
        },

    })
    
    const [initFormInsert, setInitFormInsert] = useState({
        account: {
            value: 0,
            required: true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'select_group',
            label: 'Account',
            name: 'account',
            obj: [],
        },
        id: {
            value: 0,
            required: true,
            showError: false,
            errorMsg: '',
            show: false,
            readOnly: true,
            tipe: 'text',
            label: 'ID',
            name: 'id',
        },
        keterangan: {
            value: '',
            required: true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'text',
            label: 'Keterangan',
            name: 'keterangan',
        },
        tipe_biaya: {
            value: 'BBM',
            required: false,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'select',
            label: 'Tipe Biaya',
            name: 'tipe_biaya',
            obj: [
                { value: '1', label: 'BBM' },
                { value: '2', label: 'Uang Makan' },
                { value: '3', label: 'Lainnya' }
            ]
        },
        jumlah: {
            value: 0,
            required: true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'number',
            label: 'jumlah',
            name: 'jumlah',
            className: 'text-right'
        },
        jenis_transaksi_tmp: {
            value: 0,
            required: false,
            showError: false,
            errorMsg: '',
            show: false,
            tipe: 'number',
            label: 'jenis_transaksi_tmp',
            name: 'jenis_transaksi_tmp',
            className: 'text-right'
        },

    });
    const [listInsert, setListInsert] = useState([]);

    const fetchData = useCallback(async (
        q = '',
        startDatee = null,
        endDatee = null,
        perusahaan = '',
        offset = 0,
        limit = config.itemPerPage
    ) => {
        setIsLoading(true);
        try {
            let startDateValue = null
            let url = `${config.endPoint.pengeluaran}?q=${q}&jenis_transaksi=${jenis_transaksi}&perusahaan=${perusahaan}`
            if (startDatee !== null && startDatee !== '') {
                startDateValue = Moment(startDatee).format('YYYY-MM-DD');
                url += `&start_date=${startDateValue}`;
            }
            let endDateValue = null
            if (endDatee !== null && endDatee !== '') {
                endDateValue = Moment(endDatee).format('YYYY-MM-DD');
                url += `&end_date=${endDateValue}`;
            }

            const res = await api.get(url, { params: { limit: limit, offset: offset } }).then(res => res.data);
            if (res) {
                setListData(res.results);
                setPageValue(pageValue => ({
                    ...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            setIsLoading(false);
        } catch (error) {
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message) {
                msg = response.data.message;
            }
            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
    }, [setPageValue, setIsLoading, setAlertValue, jenis_transaksi])


    const fetchPerusahaan = useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await api.get(`${config.endPoint.perusahaan}`).then(res => res.data);
            if (res) {
                let tmp = [];
                if (res.results.length !== 1) {
                    tmp.push({ value: '', label: '-- Pilih Perusahaan --' })
                }
                res.results.map(post => tmp.push({ value: post.id, label: post.nama }))
                setFormData(formData => ({
                    ...formData,
                    perusahaan: {
                        ...formData.perusahaan,
                        obj: tmp,
                        value: res.results.length === 1 ? res.results[0].id : '',
                        show: res.results.length === 1 ? false : true,
                    }
                }))
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch data', color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
    }, [setAlertValue, setIsLoading])


    useEffect(() => {
        if (tipe === 'list') {
            fetchPerusahaan();
            if (pageValue.perusahaanObj.length === 1) {
                fetchData('', null, null, pageValue.perusahaanObj[0].value)
            }
            // else{
            //     fetchData();
            // }
        }
    }, [fetchData, tipe, fetchPerusahaan, pageValue.perusahaanObj]);

    const handleChangeSearchDate = (e, name) => {
        if (name === 'startDate') {
            fetchData(pageValue.search, e, endDate, pageValue.perusahaan, 0, config.itemPerPage);
            setStartDate(e);
        } else {
            fetchData(pageValue.search, startDate, e, pageValue.perusahaan, 0, config.itemPerPage);
            setEndDate(e);
        }
    }

    const handlePagination = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage);        
        setPageValue(pageValue => ({ ...pageValue, offset: myOffset, page: page }));
        fetchData(pageValue.search, startDate, endDate, pageValue.perusahaan, myOffset, config.itemPerPage);
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({ ...pageValue, [name]: value }));
        if (name === 'perusahaan') {
            fetchData(pageValue.search, startDate, endDate, value, 0, config.itemPerPage)
            setPageValue(pageValue => ({ ...pageValue, offset: 0, page: 1 }))
        }
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({ ...pageValue, offset: 0, page: 1 }));
        fetchData(pageValue.search, startDate, endDate, pageValue.perusahaan, 0, config.itemPerPage);
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    const handleDetail = (post) => {
        setListDetail(post.detail);
        setPengeleruanDetail(post);
        setModalValue(modalValue => ({ ...modalValue, tipe: 'detail', show: true, title: jenis_transaksi === 'pengeluaran' ? 'Detail Pengeluaran' : 'Detail Penerimaan' }));
    }

    // ================================= create / edit =====================================
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({
            ...formData,
            [name]: { ...formData[name], value: value }
        }))
        if (name === 'perusahaan') {
            fetchBank(value);
            setFormData(formData => ({ ...formData, bank: { ...formData.bank, value: '' } }))
        }
        if (name === 'jenis_pengeluaran') {
            if ([1, '1'].includes(value)) {
                setFormData(formData => ({
                    ...formData,
                    bank: { ...formData.bank, show: true, required: true },
                    kontak: { ...formData.kontak, required: false },
                    kontak_display: { ...formData.kontak_display, required: false, show: false }
                }))
            } else {
                setFormData(formData => ({
                    ...formData,
                    bank: { ...formData.bank, show: false, required: false },
                    kontak: { ...formData.kontak, required: true },
                    kontak_display: { ...formData.kontak_display, required: true, show: true }
                }))
            }
        }
    }

    const handleChangeDate = (e, name) => {
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : e}
        }))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === '') {
            setFormData(formData => ({ ...formData, [name]: { ...formData[name], showError: true } }))
        } else {
            setFormData(formData => ({ ...formData, [name]: { ...formData[name], showError: false } }))
        }
    }

    const fetchBank = useCallback(async (perusahaan = '') => {
        setIsLoading(true);
        try {
            const res = await api.get(`${config.endPoint.bank}?perusahaan=${perusahaan}`).then(res => res.data);
            let tmp = [{ value: '', label: '-- Pilih Kas / Bank --' }]
            if (res) {
                res.results.map(post => tmp.push({ label: post.nama, value: post.id }));
            }
            setFormData(formData => ({ ...formData, bank: { ...formData.bank, obj: tmp } }))
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch bank', color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
    }, [setIsLoading, setAlertValue]);

    useEffect(() => {
        if (tipe !== 'print') {
            fetchBank();
        }
    }, [fetchBank, tipe])

    const handleAdd = async () => {
        setModalValue(modalValue => ({ ...modalValue, tipe: 'create', show: true, title: jenis_transaksi === 'penerimaan' ? 'Tambah Penerimaan' : 'Tambah Pengeluaran' }));
        setFormData(formData => ({
            ...formData,
            penerima: { ...formData.penerima, value: '' },
            jenis_bayar: { ...formData.jenis_bayar, value: 'Cash' },
            nomor_cek: { ...formData.nomor_cek, value: '' },
            tanggal_value : {...formData.tanggal_value, value : ''},
            jenis_transaksi: { ...formData.jenis_transaksi, value:  jenis_transaksi === 'penerimaan' ? 2 : 1},            
            kendaraan: { ...formData.kendaraan, required: false, show: false },
            bank: { ...formData.bank, show: true, required: true, value: '' },
            jenis_pengeluaran: { ...formData.jenis_pengeluaran, show: false, value: 1 },
            kontak: { ...formData.kontak, required: false },
            kontak_display: { ...formData.kontak_display, required: false, show: false }
        }));
        setListInsert([]);

        let tmpAccountHpp;
        if (jenis_transaksi === 'penerimaan') {
            tmpAccountHpp = await fetchAccount('pendapatan,pendapatanlain-lain');
        } else {
            tmpAccountHpp = await fetchAccount('hpp,biaya,kas,hutang,aktiva,Biayalain-lain');
        }
        let tmpResAccountHpp = [];

        tmpAccountHpp.map(parent => {
            let tmpChild = []
            parent.child.map(child => {
                tmpChild.push({ value: child.id, label: `${child.noakun} - ${child.nmakun}` })
                return true;
            });
            tmpResAccountHpp.push({
                label: `${parent.noakun} - ${parent.nmakun}`,
                value: parent.id,
                child: tmpChild
            })
            return true;
        });
        setInitFormInsert(initFormInsert => ({
            ...initFormInsert,
            account: { ...initFormInsert.account, obj: tmpResAccountHpp },
            tipe_biaya: { ...initFormInsert.tipe_biaya, required: false }
        }))

    }

    const handleEdit = async (post) => {        
        setModalValue(modalValue => ({
            ...modalValue,
            tipe: ['pengeluaran_accounting', 'penerimaan_accounting'].includes(pageTipe) ? 'posting' : 'edit',
            show: true,
            title: pageTipe === 'pengeluaran_accounting' ? 'Posting Pengeluaran' : 'Edit Pengeluaran',
            content: post.jenis_transaksi === 4 ? 'kendaraan' : 'pengeluaran'
        }));
        setFormData(formData => ({
            ...formData,
            jenis_pengeluaran: { 
                ...formData.jenis_pengeluaran, 
                value: post.jenis_pengeluaran_display,
                readOnly: post.jenis_transaksi === 4 ? true : ''
            },
            penerima: { 
                ...formData.penerima, 
                value: post.penerima, 
                readOnly: ['pengeluaran_accounting', 'penerimaan_accounting'].includes(pageTipe) || post.jenis_transaksi === 4 ? true : ''
            },
            jenis_bayar: { ...formData.jenis_bayar, value: post.jenis_bayar, readOnly: ['pengeluaran_accounting', 'penerimaan_accounting'].includes(pageTipe) ? true : '' },
            nomor_cek: { ...formData.nomor_cek, value: post.nomor_cek, readOnly: ['pengeluaran_accounting', 'penerimaan_accounting'].includes(pageTipe) ? true : '' },
            id: { ...formData.id, value: post.id, readOnly: ['pengeluaran_accounting', 'penerimaan_accounting'].includes(pageTipe) ? true : '' },
            perusahaan: { ...formData.perusahaan, value: post.perusahaan || '' },
            jenis_transaksi: { ...formData.jenis_transaksi, value: post.jenis_transaksi },           
            kendaraan: {
                ...formData.kendaraan,
                required: post.jenis_transaksi === 4 ? true : false,
                show: post.jenis_transaksi === 4 ? true : false,
                value: post.kendaraan_display
            },
            kendaraan_pemakaian_display: {
                ...formData.kendaraan_pemakaian_display,
                required: post.jenis_transaksi === 4 ? true : false,
                show: post.jenis_transaksi === 4 ? true : false,
                value: post.kendaraan_pemakaian_display
            },
            bank: {
                ...formData.bank,
                required: post.jenis_pengeluaran_display === 1 ? true : false,
                show: post.jenis_pengeluaran_display === 1 ? true : false,
                value: post.bank,
                readOnly: post.jenis_transaksi === 4 ? true : ''
            },
            kontak: {
                ...formData.kontak,
                required: post.jenis_pengeluaran_display === 2 ? true : false,
                value : post.kontak || ''
            },
            kontak_display: {
                ...formData.kontak_display,
                required: post.jenis_pengeluaran_display === 2 ? true : false,
                show: post.jenis_pengeluaran_display === 2 ? true : false,
                value : post.kontak_display || ''
            }
        }));
        fetchBank(post.perusahaan);
        setPengeleruanDetail(post);
        setIsLoading(true);
        setIsEdit(true);
        try {
            // hpp pada form insert
            let tmpAccountHpp;
            if (jenis_transaksi === 'penerimaan') {
                tmpAccountHpp = await fetchAccount('pendapatan,pendapatanlain-lain');
            } else {
                tmpAccountHpp = await fetchAccount('hpp,biaya,kas');
            }
            // let tmpAccountHpp = await fetchAccount('hpp,biaya,kas');
            let tmpResAccountHpp = [];
            let tmpInsert = [];

            tmpAccountHpp.map(parent => {
                let tmpChild = []
                parent.child.map(child => {
                    tmpChild.push({ value: child.id, label: `${child.noakun} - ${child.nmakun}` })
                    return true;
                });
                tmpResAccountHpp.push({
                    label: `${parent.noakun} - ${parent.nmakun}`,
                    value: parent.id,
                    child: tmpChild
                })
                return true;
            });


            let formInsert = { ...initFormInsert };
            formInsert = { ...formInsert, account: { ...formInsert.account, obj: tmpResAccountHpp } }
            // let detailArray = post.detail.filter(x => parseInt(x.debet) > 0)
            let detailArray = post.drtrx === 1 ? post.detail.filter(x => x.account !== post.account_bank) : post.detail.filter(x => parseInt(x.debet) > 0)
            detailArray.map(detail => {
                let tmpObj = { ...formInsert };
                tmpObj = {
                    ...tmpObj,
                    account: { 
                        ...tmpObj.account, 
                        value: detail.account || '' 
                    },
                    keterangan: { 
                        ...tmpObj.keterangan, 
                        value: detail.discription, 
                        readOnly: ['pengeluaran_accounting', 'penerimaan_accounting'].includes(pageTipe) ||  post.jenis_transaksi === 4 ? true : '' ? true : false 
                    },
                    jumlah: { 
                        ...tmpObj.jumlah, 
                        value: jenis_transaksi === 'pengeluaran' ? detail.debet : detail.kredit, 
                        readOnly: ['pengeluaran_accounting', 'penerimaan_accounting'].includes(pageTipe) ||  post.jenis_transaksi === 4 ? true : '' ? true : false 
                    },
                    id: { 
                        ...tmpObj.id, 
                        value: detail.id, 
                        readOnly: ['pengeluaran_accounting', 'penerimaan_accounting'].includes(pageTipe) ? true : false 
                    },
                    tipe_biaya: {
                        ...initFormInsert.tipe_biaya,
                        required: post.jenis_transaksi === 4 ? true : false,
                        value: detail.jenis_biaya ? detail.jenis_biaya : '1',
                        readOnly: post.jenis_transaksi === 4 ? true : ''
                    },
                    jenis_transaksi_tmp: {
                        ...initFormInsert.jenis_transaksi_tmp,                        
                        value: post.jenis_transaksi,
                    }
                }
                tmpInsert.push(tmpObj)
                return true;
            })
            setListInsert(tmpInsert);
            setInitFormInsert(initFormInsert => ({
                ...initFormInsert,
                account: { ...initFormInsert.account, obj: tmpResAccountHpp }
            }));
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch account', color: 'danger' }))
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
    };

    const handleAddInsert = () => {
        let array = [...listInsert];
        array.push(initFormInsert)
        setListInsert(array);
    }

    const handleChangeInsert = (e, index) => {
        const { name, value } = e.target;
        let nilai = value
        let array = [...listInsert];
        if (array[index][name].tipe === 'number') {
            nilai = nilai.replace(/,/g, '');
        }
        array[index] = { ...array[index], [name]: { ...array[index][name], value: nilai } };
        setListInsert(array);
    }

    const handleDeleteInsert = (index) => {
        let array = [...listInsert];
        array.splice(index, 1);
        setListInsert(array);
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            let errorCount = 0;
            let tmpForm = {};
            let objForm = { ...formData };
            Object.entries(objForm).map(([index, post]) => {
                if (post.required && post.value === '') {
                    errorCount++;
                    post.showError = true;
                } else {
                    tmpForm[index] = post.value
                }
                return true;
            })

            if (errorCount > 0) {
                setIsLoading(false);
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Lengkapi Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }))
                }, config.timeOutValue);
                return;
            }

            errorCount = 0;
            let tmpArray = [...listInsert];
            let array = [] // untuk menyimpan listInsert hanya value;
            tmpArray.map(value => {
                let obj = { ...value };
                let tmpArrayForm = {};
                Object.entries(obj).map(([index, post]) => {
                    if (post.required && post.value === '') {
                        errorCount++;
                        post.showError = true;
                    } else {
                        tmpArrayForm[index] = post.value
                    }
                    return true;
                });
                array.push(tmpArrayForm)
                return true;
            })

            if (errorCount > 0) {
                setIsLoading(false);
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Lengkapi Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }))
                }, config.timeOutValue);
                return;
            }

            tmpForm['tanggal_value'] = Moment(tmpForm['tanggal_value']).format('YYYY-MM-DD')

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            newForm.append('listInsert', JSON.stringify(array));
            let res;
            // console.log('pageTipe is >>>>', tmpForm['jenis_transaksi']);
            // return;
            if (modalValue.tipe === 'posting') {
                res = await api.put(`${config.endPoint.pengeluaranPosting}${formData.id.value}/`, newForm).then(res => res.data);
            } else {
                if (isEdit) {
                    res = await api.put(`${config.endPoint.pengeluaran}${formData.id.value}/`, newForm).then(res => res.data);
                } else {
                    res = await api.post(`${config.endPoint.pengeluaran}`, newForm).then(res => res.data);
                }
            }
            setIsLoading(false);
            if (res) {
                setIsLoading(false);
                setFormData(formData => ({ ...formData, id: { ...formData.id, value: '' } }));
                fetchData();
                handleCloseModal();
            }
        } catch (error) {
            setIsLoading(false);
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message) {
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
    }


    // ==================== posting ===========================
    const handleUnposting = async (post) => {
        setListDetail(post.detail);
        setPengeleruanDetail(post);
        setModalValue(modalValue => ({ ...modalValue, tipe: 'unposting', show: true, title: jenis_transaksi === 'pengeluaran' ? 'Unposting Pengeluaran' : 'Unposting Penerimaan' }));
    }

    // =================== unposting ==========================
    const handleSubmitUnposting = async () => {
        setIsLoading(true);
        try {
            const res = await api.put(`${config.endPoint.pengeluaranUnPosting}${pengeluaranDetail.id}/`).then(res => res.data);
            if (res) {
                handleCloseModal();
                fetchData(pageValue.search, startDate, endDate, pageValue.perusahaan, pageValue.offset, config.itemPerPage);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message) {
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
    }

    // ==================== print / cetak ===========================
    const { pengeluaranId } = useParams();
    const handlePrint = (post) => {
        window.open(`#/laporan/cetak/pengeluaran/${post.id}/`, "_blank")
    }

    const fetchDataPrint = useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await api.get(`${config.endPoint.pengeluaran}${pengeluaranId}/`).then(res => res.data);
            if (res) {
                setPengeleruanDetail(res.results);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: false, text: 'Unable to fetch perusahaan', color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
    }, [setIsLoading, setAlertValue, pengeluaranId])

    useEffect(() => {
        if (tipe === 'print') {
            fetchDataPrint();
        }
    }, [tipe, fetchDataPrint])


    // ========================= pengeluaran kendaraaan ===================
    const handleAddPengeluaranKendaraan = async () => {
        setFormData(formData => ({
            ...formData,
            bank: { ...formData.bank, value: '' },
            penerima: { ...formData.penerima, value: '' },
            jenis_bayar: { ...formData.jenis_bayar, value: 'Cash' },
            nomor_cek: { ...formData.nomor_cek, value: '' },
            jenis_transaksi: { ...formData.jenis_transaksi, value: 4 },            
            kendaraan: { ...formData.kendaraan, required: true, show: true },

        }));
        setListInsert([]);

        let tmpAccountHpp;
        if (jenis_transaksi === 'penerimaan') {
            tmpAccountHpp = await fetchAccount('pendapatan,pendapatanlain-lain');
        } else {
            tmpAccountHpp = await fetchAccount('hpp,biaya,kas,hutang,aktiva,Biayalain-lain');
        }
        let tmpResAccountHpp = [];

        tmpAccountHpp.map(parent => {
            let tmpChild = []
            parent.child.map(child => {
                tmpChild.push({ value: child.id, label: `${child.noakun} - ${child.nmakun}` })
                return true;
            });
            tmpResAccountHpp.push({
                label: `${parent.noakun} - ${parent.nmakun}`,
                value: parent.id,
                child: tmpChild
            })
            return true;
        });
        setInitFormInsert(initFormInsert => ({
            ...initFormInsert,
            account: { ...initFormInsert.account, obj: tmpResAccountHpp },
            tipe_biaya: { ...initFormInsert.tipe_biaya, required: true }
        }))
        setModalValue(modalValue => ({
            ...modalValue,
            tipe: 'create',
            show: true,
            title: 'Tambah Pengeluaran Kendaraan',
            content: 'kendaraan',
        }));
    }

    

    const closeModalHandler = () => {
        modalDispatch({
            type: 'CLOSE'
        })
    }    


    // ============== delete ==================================
    const handleDelete = (post) => {
        setModalValue(modalValue => ({
            ...modalValue,
            show: true,
            tipe: 'delete',
            title: 'Konfirmasi Hapus'
        }));

        setListDetail(post.detail);
        setPengeleruanDetail(post);
    }

    const handleSubmitDelete = async () => {
        setIsLoading(true);
        try {
            const res = await api.delete(`${config.endPoint.pengeluaran}${pengeluaranDetail.id}/`).then(res => res.data);
            if (res) {
                handleCloseModal();
                fetchData(pageValue.search, startDate, endDate, pageValue.perusahaan, pageValue.offset, config.itemPerPage);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message) {
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
    }

    // ======= kontak  ========================================
    const handleShowKontak = () => {
        modalDispatch({
            type: 'KONTAK',
            onClickTr: handleClickKontak,
            handleClose: closeModalHandler
        })
    }

    const handleClickKontak = (post) => {
        setFormData(formData => ({
            ...formData,
            kontak: { ...formData.kontak, value: post.id },
            kontak_display: { ...formData.kontak_display, value: post.nama },
        }));
        closeModalHandler();
    }

    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, fetchAccount, fetchProdukSatuan, getPermission,
        listData, handlePagination, handleChangeSearchDate, handleChangeSearch, handleSearch, handleKeyDownSearch,
        startDate, endDate, listDetail, pengeluaranDetail, handleDetail,
        handleChange, handleBlur, formData,
        handleEdit, handleAdd, handleAddInsert, listInsert, handleChangeInsert, handleDeleteInsert, handleSubmit,
        handleUnposting, handleSubmitUnposting, handlePrint, handleAddPengeluaranKendaraan,
        modalState, modalDispatch, closeModalHandler, showConfirmCallToAction, setshowConfirmCallToAction,
        handleDelete, handleSubmitDelete, handleShowKontak,handleChangeDate
    }
}