import React, { Fragment } from 'react';
import { Alert, Button, ButtonGroup, Card, CardBody, CardHeader,  Col, Modal, ModalBody, ModalFooter, ModalHeader, Row,  } from 'reactstrap';
import { useKontrak } from './kontrakFunction';
import { CustomModal } from '../../containers/Components/CustomModal';
import LoaderComponent from '../../containers/Components/LoaderComponent';
import JadwalComp from './Component/JadwalComp';
import KontrakTableComp from './Component/KontrakTableComp';


const KontrakPage  = (props) => {
    const hideButton = props && props.hideButton ? props.hideButton : false;
    const status = props && props.status ? props.status : null;
    const {  
        alertValue,  handleCloseAlert, modalValue,  isLoading, 
        pageValue, handleCloseModal,
        handleChangeSearch, handleSearch, handleKeyDownSearch, handlePagination,
        listData,  columnHeader, handleAdd,
        modalState, closeModalHandler,         
        handleCreateJadwal, info, listJadwal, handleAddKendaraanJadwal, handleChangeDateJadwal,
        handleShowKendaraan, handleShowSupir, handleDeleteJadwal, handleChangeJadwal,
        handleSubmitJadwal, handleShowCetakDO, handlePrintDo, handleClosing, handleSubmitClosing,
        handleEdit, view, handleDetail
    } = useKontrak({tipe: 'list', status : status});
    

    return(
        <Fragment>
            { isLoading && <LoaderComponent/>}
            <Alert isOpen={alertValue.show} toggle={handleCloseAlert} color={alertValue.color}>
                {alertValue.text}
            </Alert>
            <Modal isOpen={modalValue.show} size={['cetak_do', 'jadwal', 'closing'].includes(modalValue.tipe) ? 'xl' : 'lg'} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>{modalValue.title}</ModalHeader>
                <ModalBody>
                    <Alert isOpen={alertValue.show} color={alertValue.color} toggle={handleCloseAlert}>
                        {alertValue.text}
                    </Alert>
                    { ['jadwal', 'cetak_do', 'closing'].includes(modalValue.tipe) &&
                        <JadwalComp
                            info={info}
                            listJadwal={listJadwal}
                            handleAddKendaraanJadwal={handleAddKendaraanJadwal}
                            handleChangeDateJadwal={handleChangeDateJadwal}
                            handleShowKendaraan={handleShowKendaraan}
                            handleShowSupir={handleShowSupir}
                            handleDeleteJadwal={handleDeleteJadwal}
                            handleChangeJadwal={handleChangeJadwal}
                            tipeView={modalValue.tipe}
                            handlePrintDo={handlePrintDo}
                            // handleSubmitJadwal={handleSubmitJadwal}
                        />
                    }
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                        <Button color='secondary' onClick={handleCloseModal} size='sm'>Cancel</Button>
                        { modalValue.tipe === 'jadwal' &&
                            <Button color='primary' onClick={handleSubmitJadwal} size='sm'>Simpan</Button>                    
                        }
                        { modalValue.tipe === 'closing' &&
                            <Button color='danger' onClick={handleSubmitClosing} size='sm'>Closing</Button>                    
                        }
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
            <CustomModal
                show={modalState.show}
                size={modalState.size}
                close={closeModalHandler}
                confirmButtonText={modalState.confirmButtonText}
                confirmButtonAction={modalState.confirmButtonAction}
                withCard={modalState.withCard}
                heading={modalState.heading}
            >
                { modalState.withCard ?
                    <>
                        <ModalHeader toggle={closeModalHandler}>
                            {modalState.heading}
                        </ModalHeader>
                        <ModalBody>
                            <>
                                <p>{modalState.content}</p>
                            </>
                        </ModalBody>
                    </>
                    :
                    <>
                        {modalState.content}
                    </>
                }
            </CustomModal>
            <Card>
                <CardHeader>
                    <Row>
                        <Col lg={6}>Kontrak</Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    { view === 'list' &&
                        <KontrakTableComp
                            handleChangeSearch={handleChangeSearch}
                            handleSearch={handleSearch}
                            handleKeyDownSearch={handleKeyDownSearch}
                            pageValue={pageValue}
                            handleAdd={handleAdd}
                            columnHeader={columnHeader}
                            listData={listData}
                            hideButton={hideButton}
                            handleCreateJadwal={handleCreateJadwal}
                            handleShowCetakDO={handleShowCetakDO}
                            handleClosing={handleClosing}
                            handleEdit={handleEdit}
                            clickTr={props.clickTr}
                            handlePagination={handlePagination}
                            handleDetail={handleDetail}
                        />
                    }                    
                </CardBody>               
            </Card>
        </Fragment>
    )
}
export default KontrakPage