import React, { useState, useEffect, useCallback, useReducer } from 'react';
import config from '../config';
import * as ut from '../utils/utils';
import { useContextLogBiayaKendaraan } from './logBiayaKendaraanContext';
import { useContextLogKendaraan } from './logKendaraanContext';
import { useContextDpKontrak } from './uangMukaKontrakContext';


const UserContext = React.createContext([{}, () => {}]);

  
const UserProvider = (props) => {      
    const [state, setState] = useState({
        listPenjualan : ut.getLocalStorage("listPenjualan", []),
        listOutlet : []
    });
    const {logKendaraanStore, logKendaraanDispatch} = useContextLogKendaraan();
    const {logBiayaKendaraanStore, logBiayaKendaraanDispatch} = useContextLogBiayaKendaraan();
    const { dpKontrakStore, dpKontrakDispatch} = useContextDpKontrak();
    useEffect(() => {
        ut.setLocalStorage("listPenjualan", state.listPenjualan);        
    }, [state.listPenjualan]);

    const setListPenjualanValue = useCallback(() => {
        // ut.setLocalStorage("listPenjualan", post);        
        const myData = ut.getLocalStorage('listPenjualan');
        setState(state => ({...state, listPenjualan : myData}))
    },[]);
    
    const restoreOutlet = useCallback(() => {
        // const myOutlet = localStorage.getItem(config.localStorageKey.OUTLET_LIST)
        const myOutlet = ut.getLocalStorage(config.localStorageKey.OUTLET_LIST, []);
        let tmp = [];        
        myOutlet.map(post => {            
            tmp.push({label : post.name, value : post.id})
            return true;
        })
        setState(state => ({...state, listOutlet: tmp}))        
    },[])

    const stateFunction = {
        setListPenjualanValue : setListPenjualanValue,
    }

    useEffect(() => {
        restoreOutlet();
    },[restoreOutlet])

    const alertReducer = (state, action) => {
        switch (action.type) {
            case 'SHOW' :
                return {                    
                    isOpen : true,
                    text : action.text,
                    color : action.color,
                }
            case 'HIDE' :
                return {
                    isOpen : false,
                    text : '',
                    color : 'danger'
                }
            default :
                return {
                    isOpen: false,
                    text : '',
                    color : 'danger'
                }
        }
    }

    const [alertState, alertDispatch] = useReducer(alertReducer, {
        isOpen : false,
        text : '',
        color : 'danger',
    })

    const loadingReducer = (state, action) => {
        switch (action.type) {
            case 'SHOW' :
                return {
                    isLoading : true,
                }
            case 'HIDE' : 
                return {
                    isLoading : false,
                }
            default :
                return {
                    isLoading: false
                }
        }
    }

    const [loadingState, loadingDispatch] = useReducer(loadingReducer, {
        isLoading : false
    })
    const [kendaraanStore, kendaraanDispatch] = useReducer(
        (prevState, action) => {
            switch (action.type){
                case 'FETCH' :
                    return {
                        ...prevState, 
                        data : action.data,
                        offset : action.offset,
                        lastPage : action.lastPage,
                        page : action.page,
                        pageObj : action.pageObj,
                        search : action.search,
                        ready : true
                    }                
                case 'SEARCH' : 
                    return {
                        ...prevState,
                        search : action.search,
                    }
                default :
                    return {
                        ...prevState,
                        data : null,
                        offset : 0,
                        lastPage : 1,
                        page : 1,
                        pageObj: [],
                        search : '',
                        ready : false
                    }
            }
        },
        {
            data : null,
            offset: 0,
            lastpage: 1,
            page : 1,
            pageObj: [],
            search: '',
            ready : false
        }
    )



  

    return (
        <UserContext.Provider value={{            
            stateContext: [state, setState], 
            stateFunction: stateFunction,
            alertState : [alertState, alertDispatch],
            loadingState : [loadingState, loadingDispatch],
            kendaraanStore : [kendaraanStore, kendaraanDispatch],
            logKendaraanStore : [logKendaraanStore, logKendaraanDispatch],
            logBiayaKendaraanStore : [logBiayaKendaraanStore, logBiayaKendaraanDispatch],
            dpKontrakStore : [dpKontrakStore, dpKontrakDispatch],
        }}>
            {props.children}
        </UserContext.Provider>
    )
}
export { UserContext, UserProvider};
