import { useCallback, useEffect, useState } from "react";
import config from "../../../config";
import { useGlobalFuntction } from "../../../generalFunction/globalFunction";
import api from "../../../services/api";
import { pageObj } from "../../../utils/pageObj";
import Moment from 'moment';

export function useSupir(props) {
    const {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal
    } = useGlobalFuntction();
    const [listData, setListData] = useState([]);
    const [view, setView] = useState('list');
    const columnHeader = [
        {
            Header: 'Foto',
            accessor: 'foto_supir',
            sort: true,
            tipe: 'image',
            className: 'text-center',
            classNameImage:'circle-image-list'
        },
        {
            Header: 'Nama',
            accessor: 'nama',
            sort: true,
            className: 'text-center middle-center',
        },
        {
            Header: 'Alamat',
            accessor: 'alamat',
            sort: true,
            className: 'text-center middle-center',
        },
        {
            Header: 'Telp',
            accessor: 'telp1',
            sort: true,
            className: 'text-center middle-center',
        },
        {
            Header: 'Umur',
            accessor: 'umur',
            sort: true,
            className: 'text-center middle-center',
        },
        {
            Header: 'Masa Berlaku SIM',
            accessor: 'masa_berlaku_sim_display',
            sort: true,
            className: 'text-center middle-center',
            badge: true
        },
    ]

    const fetchData = useCallback(async (q = '', offset = 0, limit = config.itemPerPage) => {
        setIsLoading(true);
        try {
            let params = {
                q: q,
                offset: offset,
                limit: limit,
                tipe_kontak: 'supir'
            }
            const res = await api.get(`${config.endPoint.kontak}`, { params: params }).then(res => res.data);
            if (res) {
                setListData(res.results);
                setPageValue(pageValue => ({
                    ...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch data', color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }, [setIsLoading, setAlertValue, setPageValue]);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({ ...pageValue, [name]: value }))
    }

    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage);
        setPageValue(pageValue => ({ ...pageValue, offset: 0, page: 1 }))
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    const handleAdd = () => {
        handleCancel();
        setView('create');
    }

    const handlePagination = (page) => {
		let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
		setPageValue(pageValue => ({...pageValue, page: page, offset: myOffset}));		
		fetchData(pageValue.search, myOffset, config.itemPerPage)
	}

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            text : `Apakah anda yakin akan menghapus data supir an. ${post.nama} ?`,
            id : post.id
        }))
    }

    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.kontak}${modalValue.id}`).then(res => res.data);
            if (res){                
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Data Supir berhasil dihapus', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
            }
            handleCloseModal();
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }
    

    // ===================== form data ========================
    const [formData, setFormData] = useState({
        nama: {
            name: 'nama',
            label: 'Nama',
            value: '',
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            tipe: 'text',
            col: 6,
        },
        alamat: {
            name: 'alamat',
            label: 'Alamat',
            value: '',
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            tipe: 'text',
            col: 6,
        },
        telp1: {
            name: 'telp1',
            label: 'HP',
            value: '',
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            tipe: 'text',
            col: 6,
        },
        tanggal_lahir: {
            name: 'tanggal_lahir',
            label: 'Tanggal Lahir',
            value: '',
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            tipe: 'date',
            col: 6,
        },
        noktp: {
            name: 'noktp',
            label: 'Nomor KTP',
            value: '',
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            tipe: 'text',
            col: 6
        },
        nosim: {
            name: 'nosim',
            label: 'nomor SIM',
            value: '',
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            tipe: 'text',
            col: 6
        },
        masa_berlaku_sim: {
            name: 'masa_berlaku_sim',
            label: 'Masa berlaku SIM',
            value: '',
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            tipe: 'date',
            col: 6
        },
        foto_supir_url: {
            name: 'foto_supir_url',
            label: 'Foto Supir',
            value: '',
            tipe: 'foto',
            required: false,
            showError: false,
            show: true,
        },
        foto_ktp_url: {
            name: 'foto_ktp_url',
            label: 'Foto KTP',
            value: '',
            tipe: 'foto',
            required: false,
            showError: false,
            show: true,
        },
        foto_sim_url: {
            name: 'foto_sim_url',
            label: 'Foto SIM',
            value: '',
            tipe: 'foto',
            required: false,
            showError: false,
            show: true,
        },
        tipe_kontak: {
            name: 'tipe_kontak',
            label: 'Foto SIM',
            value: 'supir',
            tipe: 'text',
            required: false,
            showError: false,
            show: false,
        },
        id: {
            name: 'id',
            label: 'ID',
            value: '0',
            tipe: 'text',
            required: false,
            showError: false,
            show: false,
            readOnly: true,
        },
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({
            ...formData,
            [name]: { ...formData[name], value: value }
        }))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === '') {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: true }
            }))
        } else {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: false }
            }))
        }
    }

    const handleChangeDate = (e, name) => {
        setFormData(formData => ({
            ...formData,
            [name]: { ...formData[name], value: e }
        }))
    }

    const handleInsertFoto = (e, name) => {
        var nfile = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(nfile);
        reader.onloadend = function () {
            setFormData(formData => ({ ...formData, [name]: { ...formData[name], value: reader.result } }))
        }
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let countError = 0;
            let array = {...formData};
            let tmpData = {};

            Object.entries(array).map(([index, post]) => {
                if (post.required && (post.value === '' || post.value === null)){
                    post.showError = true;
                    countError++;
                }else{
                    tmpData[index] = post.value;
                }
                return true;
            })

            if (countError > 0){
                setFormData(array);
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi data', color: 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                return;
            }

            tmpData['tanggal_lahir'] = Moment(tmpData['tanggal_lahir']).format('YYYY-MM-DD')
            tmpData['masa_berlaku_sim'] = Moment(tmpData['masa_berlaku_sim']).format('YYYY-MM-DD')

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpData))
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.kontak}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.kontak}`, newForm).then(res => res.data);
            }
            if (res){
                setView('list');
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Data Supir berhasil ditambahkan', color : 'success'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleEdit = (post) => {
        setIsEdit(true);
        setView('create');
        setFormData(formData => ({...formData,
            nama : {...formData.nama, value : post.nama},
            alamat : {...formData.alamat, value : post.alamat},
            telp1 : {...formData.telp1, value : post.telp1},
            tanggal_lahir : {...formData.tanggal_lahir, value : post.tanggal_lahir ? Moment(post.tanggal_lahir, 'YYYY-MM-DD').toDate() : ''},
            noktp : {...formData.noktp, value : post.noktp},
            nosim : {...formData.nosim, value : post.nosim},
            masa_berlaku_sim : {...formData.masa_berlaku_sim, value : post.masa_berlaku_sim ? Moment(post.masa_berlaku_sim, 'YYYY-MM-DD').toDate() : ''},
            foto_supir_url : {...formData.foto_supir_url, value : post.foto_supir || ''},
            foto_ktp_url : {...formData.foto_ktp_url, value : post.foto_ktp || ''},
            foto_sim_url : {...formData.foto_sim_url, value : post.foto_sim || ''},
            id : {...formData.id, value : post.id || ''},
        }));
    }

    const handleCancel = () => {
        setView('list');
        setFormData(formData => ({...formData,
            nama : {...formData.nama, value : ''},
            alamat : {...formData.alamat, value : ''},
            telp1 : {...formData.telp1, value : ''},
            tanggal_lahir : {...formData.tanggal_lahir, value : ''},
            noktp : {...formData.noktp, value : ''},
            nosim : {...formData.nosim, value : ''},
            masa_berlaku_sim : {...formData.masa_berlaku_sim, value : ''},
            foto_supir_url : {...formData.foto_supir_url, value : ''},
            foto_ktp_url : {...formData.foto_ktp_url, value : ''},
            foto_sim_url : {...formData.foto_sim_url, value : ''},
            id : {...formData.id, value : ''},
        }));
        setIsEdit(false);
    }

    

    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, listData, handleChangeSearch, handleSearch, handleKeyDownSearch,
        handleAdd, columnHeader, view, formData, handleChange, handleBlur, handleChangeDate,
        handleInsertFoto, handleSubmit, handleCancel, isEdit, handlePagination, handleEdit,
        handleSubmitDelete, handleDelete
    }
}