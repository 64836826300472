import {  useState } from "react";
import { useGlobalFuntction } from "../../generalFunction/globalFunction";

export function useKontrakView(props){
    const { 
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal,
    } = useGlobalFuntction();
    const [listData, setListData] = useState([]);
    const [info, setListInfo] = useState(props && props.infoKontrak ? props.infoKontrak : null);
    // const kontrakId = props && props.kontrakId ? props.kontrakId : 0

    
    
    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, listData, setListData,
        info, setListInfo
    }
}