import { useCallback, useEffect, useState } from 'react';
import config from '../../config';
import { useGlobalFuntction } from '../../generalFunction/globalFunction';
import api from '../../services/api';
import { pageObj } from '../../utils/pageObj';
import { useGlobalModalFunction } from '../../generalFunction/globalModalFunction';
import Moment from 'moment';
import { useHistory } from 'react-router';
import axios from 'axios';

export function useKontrak(props) {
    const status_kontrak = props && props.status ? props.status : null;
    const {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal
    } = useGlobalFuntction();
    const [view, setView] = useState('list');

    const history = useHistory();
    const { modalState, modalDispatch, showConfirmCallToAction, setshowConfirmCallToAction } = useGlobalModalFunction()
    const [listData, setListData] = useState([]);

    const [info, setInfo] = useState(null);
    const [listJadwal, setListJadwal] = useState([]);
    const initKendaraan = {
        id: {
            value: 0,
            required: true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'text',
            label: 'id',
            name: 'id',
        },
        tanggal: {
            value: '',
            required: true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'date',
            label: 'Tanggal',
            name: 'tanggal',
        },
        kendaraan_display: {
            value: '',
            required: true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'input_button',
            label: 'Kendaraan',
            name: 'kendaraan_display',
            readOnly: true,
        },
        kendaraan: {
            value: '',
            required: true,
            showError: false,
            errorMsg: '',
            show: false,
            tipe: 'text',
            label: 'Kendaraan',
            name: 'kendaraan',
        },
        supir_display: {
            value: '',
            required: true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'input_button',
            label: 'Kendaraan',
            name: 'supir_display',
            readOnly: true,
        },
        supir: {
            value: '',
            required: true,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'text',
            label: 'Supir',
            name: 'supir',
        },
        // uang_makan : {
        //     value : '',
        //     required : false,
        //     showError : false,
        //     errorMsg : '',
        //     show: true,
        //     tipe: 'number',
        //     label : 'Uang Makan',
        //     name : 'uang_makan',
        //     className : 'text-right',
        // },
        // bbm : {
        //     value : '',
        //     required : false,
        //     showError : false,
        //     errorMsg : '',
        //     show: true,
        //     tipe: 'number',
        //     label : 'BBM',
        //     name : 'bbm',
        //     className : 'text-right',
        // },
        jumlah_rit: {
            value: '',
            required: false,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'number',
            label: 'Jumlah Rit',
            name: 'jumlah_rit',
            className: 'text-right'
        },
        jumlah_muatan: {
            value: '',
            required: false,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'number',
            label: 'Jumlah Muatan Per rit',
            name: 'jumlah_muatan',
            className: 'text-right'
        },
        can_delete: {
            value: true,
            required: false,
            showError: false,
            errorMsg: '',
            show: true,
            tipe: 'number',
            label: 'Jumlah Muatan Per rit',
            name: 'can_delete',
            className: 'text-right'
        },
    }
    

    const columnHeader = [
        {
            Header: 'Nomor',
            accessor: 'nomor',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Jenis Kontrak',
            accessor: 'jenis_transaksi_display',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Rekanan',
            accessor: 'rekanan_display',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Pembeli',
            accessor: 'pembeli_display',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Tanggal Kontrak',
            accessor: 'start_date_kontrak_display',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Status',
            accessor: 'state_display',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Tanggal Berakhir Kontrak',
            accessor: 'end_date_kontrak_display',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Nilai Kontrak',
            accessor: 'nilai_kontrak',
            sort: true,
            className: 'text-center',
        },
    ]


    const fetchData = useCallback(async (q = '', offset = 0, limit = config.itemPerPage) => {
        setIsLoading(true);
        try {
            let url = `${config.endPoint.kontrak}`
            let params = {
                limit: limit,
                offset: offset,
                q: q,                
            }
            if (status_kontrak){
                params['status'] = status_kontrak
            }
            const res = await api.get(url, { params: params }).then(res => res.data);
            setIsLoading(false);
            let tmp = []
            if (res) {
                res.results.map(post => {
                    let obj = { ...post };
                    obj['nilai_kontrak'] = post.jenis_transaksi === 2 ? post.biaya_angkut : post.nilai_kontrak;
                    tmp.push(obj)
                    return true;
                })
                setPageValue(pageValue => ({
                    ...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            setListData(tmp);
        } catch (error) {
            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch data', color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
    }, [setAlertValue, setPageValue, setIsLoading, status_kontrak])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({
            ...pageValue,
            [name]: value
        }));
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({ ...pageValue, page: 1, offset: 0 }));
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    const handleAdd = (post) => {
        // setView('create');
        sessionStorage.removeItem('kontrakId')
        history.push('/transaksi/kontrak/create');
    }

    const handlePagination = (post) => {
        const myOffset = (parseInt(post) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({ ...pageValue, page: post, offset: myOffset }))
        fetchData(pageValue.search, myOffset, config.itemPerPage)
    }

    const handleChangeSearchDate = (e, name) => {
        if (name === 'startDate') {
            fetchData(pageValue.search, 0, config.itemPerPage);
            // setStartDate(e);
        } else {
            fetchData(pageValue.search, 0, config.itemPerPage);
            // setEndDate(e);
        }
    }


    const closeModalHandler = () => {
        modalDispatch({
            type: 'CLOSE'
        })
    }



    // ============ jadwal =================
    const handleCreateJadwal = (post) => {
        // setListJadwal([]);
        // setInfo(post);
        // setModalValue(modalValue => ({...modalValue,
        //     show: true,
        //     tipe : 'jadwal',
        //     title : 'Buat Jadwal'
        // }))
        // let tmp = []
        // post.kontrak_jadwal_display.map(post => {            
        //     let obj = {...initKendaraan}
        //     obj['id'] = {...obj['id'], value : post.id};
        //     obj['supir'] = {...obj['supir'], value : post.supir};
        //     obj['supir_display'] = {...obj['supir_display'], value : post.supir_display};
        //     obj['kendaraan_display'] = {...obj['kendaraan_display'], value : post.kendaraan_display};
        //     obj['kendaraan'] = {...obj['kendaraan'], value : post.kendaraan};
        //     // obj['bbm'] = {...obj['bbm'], value : post.bbm};
        //     // obj['uang_makan'] = {...obj['uang_makan'], value : post.uang_makan};
        //     obj['tanggal'] = {...obj['tanggal'], value : Moment(post.tanggal, 'DD-MM-YYYY').toDate()};
        //     obj['jumlah_rit'] = {...obj['jumlah_rit'], value : post.jumlah_rit};
        //     obj['jumlah_muatan'] = {...obj['jumlah_muatan'], value : post.jumlah_muatan};
        //     obj['can_delete'] = {...obj['can_delete'], value : post.can_delete};
        //     tmp.push(obj)
        //     return true;
        // })
        // setListJadwal(tmp);    
        sessionStorage.setItem('kontrakId', post.id);
        history.push('/transaksi/kontrak/jadwal')
    }

    const handleShowCetakDO = (post) => {
        setListJadwal([]);
        setInfo(post);
        setModalValue(modalValue => ({
            ...modalValue,
            show: true,
            tipe: 'cetak_do',
            title: 'Cetak DO'
        }))
        let tmp = []
        post.kontrak_jadwal_display.map(post => {
            let obj = { ...initKendaraan }
            console.log('post show cekta do is >>>>>', post)
            obj['id'] = { ...obj['id'], value: post.id };
            obj['supir'] = { ...obj['supir'], value: post.supir };
            obj['supir_display'] = { ...obj['supir_display'], value: post.supir_display };
            obj['kendaraan_display'] = { ...obj['kendaraan_display'], value: post.kendaraan_display };
            obj['kendaraan'] = { ...obj['kendaraan'], value: post.kendaraan };
            // obj['bbm'] = {...obj['bbm'], value : post.bbm};
            // obj['uang_makan'] = {...obj['uang_makan'], value : post.uang_makan};
            obj['tanggal'] = { ...obj['tanggal'], value: Moment(post.tanggal, 'DD-MM-YYYY').toDate() };
            obj['jumlah_rit'] = { ...obj['jumlah_rit'], value: post.jumlah_rit };
            obj['jumlah_muatan'] = { ...obj['jumlah_muatan'], value: post.jumlah_muatan };
            obj['nomor_do'] = post.nomor_do
            tmp.push(obj)
            return true;
        })
        setListJadwal(tmp);
    }

    // =================== jadwal =======================
    const handleAddKendaraanJadwal = () => {
        const array = [...listJadwal];
        array.push(initKendaraan);
        setListJadwal(array);
    }

    const handleChangeDateJadwal = (e, name, index) => {
        let array = [...listJadwal]
        array[index] = { ...array[index], [name]: { ...array[index][name], value: e } };
        setListJadwal(array);
    }

    const handleShowKendaraan = (index) => {
        modalDispatch({
            type: 'KENDARAAN',
            onClickTr: (post) => handleClickKendaraan(post, index),
            handleClose: closeModalHandler,
        })
    }

    const handleClickKendaraan = (post, index) => {
        let array = [...listJadwal];
        array[index] = {
            ...array[index],
            kendaraan_display: { ...array[index]['kendaraan_display'], value: post.nopol },
            kendaraan: { ...array[index]['kendaraan'], value: post.id }
        }
        setListJadwal(array);
        closeModalHandler();
    }

    const handleShowSupir = (index) => {
        modalDispatch({
            type: 'SUPIR',
            onClickTr: (post) => handleClickSupir(post, index),
            handleClose: closeModalHandler,
        })
    }

    const handleClickSupir = (post, index) => {
        let array = [...listJadwal];
        array[index] = {
            ...array[index],
            supir_display: { ...array[index]['supir_display'], value: post.nama },
            supir: { ...array[index]['supir'], value: post.id }
        }
        setListJadwal(array);
        closeModalHandler();
    }

    const handleDeleteJadwal = (index) => {
        let array = [...listJadwal];
        array.splice(index, 1);
        setListJadwal(array);
    }

    const handleChangeJadwal = (e, index) => {
        const { name, value } = e.target;
        let array = [...listJadwal];
        let nilai = value;
        if (array[index][name].tipe === 'number') {
            nilai = nilai.replace(/,/g, '');
        }
        array[index] = { ...array[index], [name]: { ...array[index][name], value: nilai } }
        setListJadwal(array);
    }

    const handleSubmitJadwal = async () => {
        setIsLoading(true);
        try {
            let arrayForm = [];
            let array = [...listJadwal];
            let countError = 0;

            array.map(post => {
                let obj = { ...post };
                let tmpForm = {};
                Object.entries(obj).map(([index, value]) => {
                    if (value.required && value.value === '') {
                        value['showError'] = true;
                        countError++;
                    } else {
                        tmpForm[index] = value.value;
                    }
                    return true;
                })
                tmpForm['tanggal'] = Moment(tmpForm['tanggal']).format('YYYY-MM-DD')
                arrayForm.push(tmpForm)
                return true;
            })

            if (countError > 0) {
                setIsLoading(false);
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Lengkapi Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }))
                }, config.timeOutValue);
                return;
            }

            let newForm = new FormData();
            newForm.append('listJadwal', JSON.stringify(arrayForm));

            const res = await api.put(`${config.endPoint.saveKontrakJadwal}${info.id}/`, newForm).then(res => res.data);
            if (res) {
                setModalValue(modalValue => ({ ...modalValue, show: false }));
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message) {
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
    }

    // const handlePrintDo = async (post) => {
    //     setIsLoading(true);
    //     try {
    //         const res = await api.get(`${config.endPoint.kontrakJadwal}${post.id.value}/`).then(res => res.data);
    //         setIsLoading(false);
    //         if (res) {
    //             localStorage.setItem(config.localStorageKey.DO_KENDARAAN, JSON.stringify(res.results))
    //         }
    //         const resPerusahaan = await api.get(`${config.endPoint.perusahaan}1/`).then(res => res.data);
    //         if (resPerusahaan) {
    //             localStorage.setItem(config.localStorageKey.PERUSAHAAN, JSON.stringify(resPerusahaan.results))
    //         }
    //         window.open(`#/transaksi/cetak-do`, "_blank")
    //     } catch (error) {
    //         setIsLoading(false);
    //         let msg = "Unable to fetch Data"
    //         let { response } = error;
    //         if (response && response.data && response.data.message) {
    //             msg = response.data.message;
    //         }
    //         setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
    //         setTimeout(() => {
    //             setAlertValue(alertValue => ({ ...alertValue, show: false }))
    //         }, config.timeOutValue)
    //     }
    // }

    const handlePrintDo = async (post) => {
        setIsLoading(true);
        try {
            await axios({
                url : `${config.apiBaseUrl[process.env.NODE_ENV]}${config.endPoint.kontrakCetakDo}${post.id.value}`,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));                
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${post.nomor_do}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
                setIsLoading(false);
            })
        } catch (error) {
            setIsLoading(false);
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message) {
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
    }

    const handleClosing = (post) => {
        sessionStorage.setItem('kontrakId', post.id);
        history.push('/transaksi/kontrak/closing');
    }

    const handleSubmitClosing = async () => {
        setIsLoading(true);
        try {
            let arrayForm = [];
            let array = [...listJadwal];
            let countError = 0;

            array.map(post => {
                let obj = { ...post };
                let tmpForm = {};
                Object.entries(obj).map(([index, value]) => {
                    if (value.required && value.value === '') {
                        value['showError'] = true;
                        countError++;
                    } else {
                        tmpForm[index] = value.value;
                    }
                    return true;
                })
                tmpForm['tanggal'] = Moment(tmpForm['tanggal']).format('YYYY-MM-DD')
                arrayForm.push(tmpForm)
                return true;
            })

            if (countError > 0) {
                setIsLoading(false);
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Lengkapi Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }))
                }, config.timeOutValue);
                return;
            }

            let newForm = new FormData();
            newForm.append('listJadwal', JSON.stringify(arrayForm));

            const res = await api.put(`${config.endPoint.closingKontrak}${info.id}/`, newForm).then(res => res.data);
            if (res) {
                setModalValue(modalValue => ({ ...modalValue, show: false }));
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message) {
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
    }

    const handleEdit = (post) => {
        sessionStorage.setItem('kontrakId', post.id);
        history.push('/transaksi/kontrak/edit');
    }

    const handleDetail = (post) => {        
        sessionStorage.setItem('kontrakId', post.id);
        history.push('/transaksi/kontrak/detail')
    }

    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal,
        handleChangeSearch, handleSearch, handleKeyDownSearch, handlePagination,
        handleAdd, handleChangeSearchDate, columnHeader,
        modalState, modalDispatch, showConfirmCallToAction, setshowConfirmCallToAction, closeModalHandler,
        handleCreateJadwal, info, listJadwal, handleAddKendaraanJadwal, handleChangeDateJadwal,
        handleShowKendaraan, handleShowSupir, handleDeleteJadwal, handleChangeJadwal,
        handleSubmitJadwal, handleShowCetakDO, handlePrintDo, handleClosing, handleSubmitClosing, listData,
        handleEdit, view, setView, handleDetail
    }
}