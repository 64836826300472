import { useCallback, useEffect, useState } from 'react';
import config from '../../../config';
import { useGlobalFuntction } from '../../../generalFunction/globalFunction';
import api from '../../../services/api';
import { pageObj } from '../../../utils/pageObj';
import { useGlobalModalFunction } from '../../../generalFunction/globalModalFunction';


export function useTangki(props){
    const {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal
    } = useGlobalFuntction();


    const { modalState, modalDispatch, showConfirmCallToAction, setshowConfirmCallToAction } = useGlobalModalFunction()
    const [listData, setListData] = useState([]);
    const [view, setView] = useState('list');
    const [tangkiId, setTangkiId] = useState(0);
    const [formData, setFormData] = useState({          
        kode : {
            value : '',
            required : true,
            showError : false,
            errorMsg : '',
            show: true,
            tipe: 'text',
            label : 'Kode',
            name : 'kode',            
        },
        kapasitas : {
            value : '',
            required : true,
            showError : false,
            errorMsg : '',
            show: true,
            tipe: 'number',
            label : 'Kapasitas (dalam kg)',
            name : 'kapasitas',            
        },
    })
    const columnHeader = [
        {
            Header: 'Kode',
            accessor: 'kode',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Kapasitas',
            accessor: 'kapasitas',
            sort: true,
            className: 'text-center',
        },
    ]

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        try{
            let url = `${config.endPoint.tangki}`
            let params = {
                limit : limit,
                offset: offset,
                q : q
            }
            const res = await api.get(url, {params : params}).then(res => res.data);
            setIsLoading(false); 
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setAlertValue, setPageValue, setIsLoading]) 

    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue,
            [name] : value
        }));
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, page : 1, offset: 0}));
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handleAdd = (post) => {
        setView('create');
    }

    const handlePagination = (post) => {
        const myOffset = (parseInt(post) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : post, offset: myOffset}))
        fetchData(pageValue.search,  myOffset, config.itemPerPage)
    }

    const handleChangeSearchDate = (e, name) => {
        if (name === 'startDate'){
            fetchData(pageValue.search,  0, config.itemPerPage);
             // setStartDate(e);
        }else{
            fetchData(pageValue.search,  0, config.itemPerPage);
             // setEndDate(e);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        let nilai = value;
        if (formData[name].tipe === 'number'){
            nilai = nilai.replace(/,/g, '');
        }
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : nilai}
        }));
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && (value === '' || value === null)){
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError: true}
            }))
        }else{
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError: false}
            }))
        }
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let countError = 0;
            let form = {...formData};
            let tmpForm = {}
            Object.entries(form).map(([index, post]) => {
                if (post.required && (post.value === '' || post.value === null)){
                    post.showError = true;
                    countError++;
                }else{
                    tmpForm[index] = post.value;
                }
                return true;
            });
            if (countError > 0){
                setFormData(form);
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color: 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                return;
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            let res;
            if (view === 'edit'){
                res = await api.put(`${config.endPoint.tangki}${tangkiId}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.tangki}`, newForm).then(res => res.data);
            }
            if (res){
                setView('list');
                fetchData();
                setPageValue(pageValue => ({...pageValue, search: '', page : 1, offset : 0}))
            }
            setIsLoading(false);
        }catch(error){
            const { response } = error;
            let msg = '';
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            if (response && response.data){
                Object.entries(response.data).map(([index, post]) => {
                    msg += `<p>${index} : ${post.toLocaleString()}</p>`
                    return true;
                });
            }

            if (msg === ''){
                msg = "Unable to fetch data"
            }
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    // const handleShowKontak = () => {
        //     modalDispatch({
            //         type: 'KONTAK',
            //         onClickTr: handleClickKontak,
            //         handleClose: closeModalHandler,
            //         params : {
                //             bagian_id : formData.bagian.value
            //         }
        //     })
    // }
    
    const closeModalHandler = () => {
        modalDispatch({
            type: 'CLOSE'
        })
    }

    const handleCancel = () => {
        setView('list');
    }

    const handleEdit = (post) => {
        setView('edit');
        setTangkiId(post.id)
        setFormData(formData => ({...formData,
            kode : {...formData.kode, value : post.kode},
            kapasitas : {...formData.kapasitas, value : post.kapasitas},            
        }))
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue, 
            show: true, 
            text : `Apakah anda yakin akan menhapus data tangki ${post.kode} dengan kapasitas ${post.kapasitas}?`,
            title : 'Konfirmasi',
            id : post.id
        }));
    }

    const handleSubmitDelete = async(id=0) => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.tangki}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData();
                handleCloseModal();
            }
            setIsLoading(false);
        }catch(error){
            const { response } = error;
            let msg = '';
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            Object.entries(response.data.error).map(([index, post]) => {
                msg += `<p>${index} : ${post.toLocaleString()}</p>`
                return true;
            });

            if (msg === ''){
                msg = "Unable to fetch data"
            }
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal,
        handleChangeSearch, handleSearch, handleKeyDownSearch, handlePagination,
        formData, handleChange, handleBlur,
        handleSubmit,handleAdd, listData, view, handleChangeSearchDate, columnHeader,
        modalState, modalDispatch, showConfirmCallToAction, setshowConfirmCallToAction, closeModalHandler, handleCancel,
        handleEdit, handleDelete, handleSubmitDelete
    }
}