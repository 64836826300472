import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
const AlertAbsoluteComp = (props) => {
    const { 
        isOpen, color, text , toggle
    } = props;
    return(
        <Fragment>
            { isOpen &&
                <div className={`alert-absolute ${color}`}>
                    <div className='alert-text'>
                        {text}                
                    </div>
                    <button type="button" className="close close-button" aria-label="Close" onClick={toggle}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            }
        </Fragment>
    )
}
AlertAbsoluteComp.propTypes = {
    isOpen : PropTypes.bool.isRequired,
    color : PropTypes.string.isRequired,
    text : PropTypes.string,
    toggle : PropTypes.func.isRequired
}
export default AlertAbsoluteComp;
