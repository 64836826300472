import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

const PagePagination = (props) => {
    const { data, handlePagination, page, lastPage } = props;
    return(
        <Fragment>
            { data.length > 1 &&
                <Pagination>
                    { page > 6 &&
                        <PaginationItem>
                            <PaginationLink tag="button" onClick={() => handlePagination(1)}>
                                {`<<`}
                            </PaginationLink>
                        </PaginationItem>
                    }
                    { data.map((post, index) => {
                        return (
                            <PaginationItem key={index} className={post === page ? 'active' : ''}>
                                <PaginationLink tag="button" onClick={() => handlePagination(post)}>
                                    {post}
                                </PaginationLink>
                            </PaginationItem>
                        )
                    })}
                    { lastPage !== page &&
                        <PaginationItem>
                            <PaginationLink tag="button" onClick={() => handlePagination(lastPage)}>
                                {`>>`}
                            </PaginationLink>
                        </PaginationItem>
                    }
                </Pagination>
            }
        </Fragment>
    )
}
PagePagination.propTypes = {        
    data : PropTypes.arrayOf(PropTypes.any).isRequired,
    handlePagination: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    lastPage: PropTypes.number.isRequired,
}
export default PagePagination;