import React, { Fragment } from 'react';
import {  Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { CustomModal } from '../../../../containers/Components/CustomModal';
import LoaderComponent from '../../../../containers/Components/LoaderComponent';
import PagePagination from '../../../../containers/Components/PagePagination';
import SparePartFormComp from './SparepartFormComp';
import { useMasterSparePart } from './sparePartFunction';
import SparepartListComp from './SparepartListComp';
import SparepartSatuanFormComp from './SparepartSatuanFormComp';

const SparePartPage = (props) => {
    const { 
        isLoading, listData, modalValue, handleCloseModal,
        handleSubmitDelete, view, handleEdit, handleDelete, pageValue, handleSearch,
        handleChangeSearch, handleKeyDownSearch, handleAdd, handleSubmit, handleCancel,
        formData, handleChange, handleBlur, modalState, closeModalHandler, handleShowKategori,
        handleShowSatuan, handleShowAccount, formTab, handleChangeFormTab,
        formTmpSatuan, handleChangeFormSatuan, handleBlurFormSatuan,
        listSatuan, handleDeleteSatuan, satuanDefault, handleShowSatuanFormSatuan,
        handleSubmitSatuan, handleSubmitDeleteSatuan, isEdit, handlePagination
    } = useMasterSparePart();

    return(
        <Fragment>
            <CustomModal
                show={modalState.show}
                size={modalState.size}
                close={closeModalHandler}
                confirmButtonText={modalState.confirmButtonText}
                confirmButtonAction={modalState.confirmButtonAction}
                withCard={modalState.withCard}
                heading={modalState.heading}
            >
                { modalState.withCard ?
                    <>
                        <ModalHeader toggle={closeModalHandler}>
                            {modalState.heading}
                        </ModalHeader>
                        <ModalBody>
                            <>
                                <p>{modalState.content}</p>
                            </>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" size="sm" onClick={closeModalHandler}>Close</Button>
                        </ModalFooter>
                    </>
                    :
                    <>
                        {modalState.content}
                        <ModalFooter>
                            <Button color="secondary" size="sm" onClick={closeModalHandler}>Close</Button>
                        </ModalFooter>
                    </>
                }
            </CustomModal>
            { isLoading && <LoaderComponent/>}
            <Modal toggle={handleCloseModal} isOpen={modalValue.show} size="md">
                <ModalHeader toggle={handleCloseModal}>{modalValue.title}</ModalHeader>
                <ModalBody>
                    {modalValue.text}
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                        { modalValue.tipe === 'delete_satuan' ?
                            <Button size="sm" color="danger" onClick={handleSubmitDeleteSatuan}><i className='fa fa-trash'></i> Hapus</Button>
                            :
                            <Button size="sm" color="danger" onClick={handleSubmitDelete}><i className='fa fa-trash'></i> Hapus</Button>
                        }                        
                        <Button size="sm" color="secondary" onClick={handleCloseModal}><i className='fa fa-undo'></i> Cancel</Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
            <Card>
                <CardHeader>
                    { view === 'list' && 'Daftar Sparepart'}
                    { view === 'create' && 'Tambah Sparepart'}
                </CardHeader>
                <CardBody>
                    { view === 'list' &&
                        <Fragment>
                            <SparepartListComp
                                listData={listData}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                pageValue={pageValue}
                                handleSearch={handleSearch}
                                handleChangeSearch={handleChangeSearch}
                                handleKeyDownSearch={handleKeyDownSearch}
                                handleAdd={handleAdd}
                                clickTr={props.clickTr || null}
                            />
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePagination}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage && pageValue.lastPage}
                            />
                        </Fragment>
                    }
                    { view === 'create' &&
                        <Fragment>
                            { isEdit &&
                                <>
                                    <Button color={"primary"} size="sm" outline={formTab === 'form' ? false : true} onClick={() => handleChangeFormTab('form')}>Detail</Button>
                                    <Button style={{marginLeft: 5}} color={"primary"} size="sm" outline={formTab === 'satuan' ? false : true} onClick={() => handleChangeFormTab('satuan')}>Satuan</Button>
                                    <div style={{marginBottom: 20}}/>
                                </>
                            }
                            { formTab === 'form' &&
                                <SparePartFormComp
                                    formData={formData}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    handleShowKategori={handleShowKategori}
                                    handleShowSatuan={handleShowSatuan}
                                    handleShowAccount={handleShowAccount}                            
                                />
                            }
                            { formTab === 'satuan' &&
                                <Row>
                                    <Col lg={6} className='offset-lg-3'>
                                        <SparepartSatuanFormComp
                                            formSatuan={formTmpSatuan}
                                            handleChange={handleChangeFormSatuan}
                                            handleBlur={handleBlurFormSatuan}
                                            handleDeleteSatuan={handleDeleteSatuan}
                                            listSatuan={listSatuan}
                                            satuanDefault={satuanDefault}
                                            handleShowSatuanFormSatuan={handleShowSatuanFormSatuan}
                                            handleSubmitSatuan={handleSubmitSatuan}
                                        />
                                    </Col>
                                </Row>
                            }
                        </Fragment>
                    }
                </CardBody>
                { view === 'create' &&
                    <CardFooter>
                        <div className="float-right">
                            <ButtonGroup>
                                <Button size="sm" color="primary" onClick={handleSubmit}>Simpan</Button>
                                <Button size="sm" color="secondary" onClick={handleCancel}>Cancel</Button>
                            </ButtonGroup>
                        </div>
                    </CardFooter>
                }
            </Card>
        </Fragment>
    )
}

export default SparePartPage;