import React, { Fragment } from "react"
import { Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import AlertAbsoluteComp from "../../../containers/Components/AlertAbsoluteComp";
import DataTableComp from "../../../containers/Components/DataTableComp";
import LoaderComponent from "../../../containers/Components/LoaderComponent";
import PagePagination from "../../../containers/Components/PagePagination";
import SearchComp from "../../../containers/Components/SearchComp";
import SupirForm from "./SupirForm";
import { useSupir } from "./supirFunction"


const SupirPage = (props) => {
    const { 
        isLoading, alertValue, handleCloseAlert, listData, handleChangeSearch, 
        handleSearch, handleKeyDownSearch, handleAdd, view, columnHeader,
        pageValue, formData, handleChange, handleChangeDate, handleBlur,
        handleInsertFoto, handleSubmit, handleCancel, handlePagination, handleEdit,
        modalValue, handleCloseModal, handleDelete, handleSubmitDelete
    } = useSupir();

    const myButtonGroup = (props) => {
        return(
            <ButtonGroup>
                <Button className='btn-edit' color='success' onClick={() => handleEdit(props.data)}><i className='fa fa-edit'></i></Button>
                <Button className='btn-edit' color='danger' onClick={() => handleDelete(props.data)}><i className='fa fa-trash'></i></Button>
            </ButtonGroup>
        )
    }

    return(
        <Fragment>
            <Modal isOpen={modalValue.show} toggle={handleCloseModal} size="md">
                <ModalHeader>Konfirmasi</ModalHeader>
                <ModalBody>
                    {modalValue.text}
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                        <Button size="sm" color="danger" onClick={handleSubmitDelete}>Hapus</Button>
                        <Button size="sm" color="secondary" onClick={handleCloseModal}>Cancel</Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
            { isLoading && <LoaderComponent/>}
            <Card>
                <CardHeader>Daftar Supir</CardHeader>
                <CardBody>
                    <AlertAbsoluteComp 
                        isOpen={alertValue.show} 
                        toggle={handleCloseAlert} 
                        color={alertValue.color} 
                        text={alertValue.text}                         
                    />
                    { view === 'list' &&
                        <Fragment>
                            <Row>
                                <Col lg={6}>
                                    <SearchComp
                                        value={pageValue.search}
                                        onChange={handleChangeSearch}
                                        onClick={handleSearch}
                                        onKeyDown={handleKeyDownSearch}
                                    />                                    
                                </Col>
                                <Col lg={6}>
                                    <Button onClick={handleAdd} color="primary"><i className='fa fa-plus'></i></Button>
                                </Col>
                            </Row>
                            <DataTableComp
                                columns={columnHeader}
                                data={listData}
                                pageOffset={pageValue.offset}
                                MyButtonGroup={myButtonGroup}
                                actionGroup={true}
                                countNum={true}
                                buttonGroupClassName="middle-center"
                                clickTr={props.clickTr}
                            />
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePagination}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </Fragment>
                    }
                    { view === 'create' &&
                        <Fragment>
                            <SupirForm
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleChangeDate={handleChangeDate}
                                formData={formData}
                                handleInsertFoto={handleInsertFoto}
                            />                            
                        </Fragment>
                    }
                </CardBody>
                { view === 'create' &&
                    <CardFooter>
                        <div className="float-right">
                            <ButtonGroup>
                                <Button size="sm" onClick={handleSubmit} color="primary" outline disabled={isLoading}>Simpan</Button>
                                <Button size="sm" onClick={handleCancel} color="secondary" >Cancel</Button>
                            </ButtonGroup>
                        </div>
                    </CardFooter>
                }
            </Card>
        </Fragment>
    )
}
export default SupirPage;