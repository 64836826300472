import { useReducer } from "react"


// export function useContextLogKendaraan(){
export function useContextDpKontrak(){
    const [dpKontrakStore, dpKontrakDispatch] = useReducer(
        (prevState, action) => {
            switch (action.type){
                case 'FETCH' :
                    return {
                        ...prevState, 
                        data : action.data,
                        offset : action.offset,
                        lastPage : action.lastPage,
                        page : action.page,
                        pageObj : action.pageObj,
                        ready : true
                    }
                case 'DETAIL' :                    
                    return {
                        ...prevState, 
                        detail : action.detail,
                        ready : true,
                    }          
                case 'SEARCH' : 
                    return {
                        ...prevState,
                        search : action.search,
                    }
                default :
                    return {
                        ...prevState,
                        data : null,
                        offset : 0,
                        lastPage : 1,
                        page : 1,
                        pageObj: [],
                        search : '',
                        ready : false,
                        detail: null
                    }
            }
        },
        {
            data : null,
            offset: 0,
            lastpage: 1,
            page : 1,
            pageObj: [],
            search: '',
            ready : false,
            detail : null,
        }
    )

    return {
        dpKontrakStore, dpKontrakDispatch
    }
}