import React, { Fragment } from 'react';
import { Alert, Button, ButtonGroup, Card, CardBody, CardHeader, CardFooter, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row,  FormGroup, Label } from 'reactstrap';
import DataTableComp from '../../../containers/Components/DataTableComp';
import PagePagination from '../../../containers/Components/PagePagination';
import { useTangki } from './tangkiFunction';
import SearchComp from '../../../containers/Components/SearchComp';
import FormTextSingleComp from '../../../containers/Components/FormTextSingleComp';
import LoaderComponent from '../../../containers/Components/LoaderComponent';


const TangkiPage  = (props) => {
    const {  
        alertValue,  handleCloseAlert, modalValue,  isLoading, 
        pageValue, handleCloseModal,
        handleChangeSearch, handleSearch, handleKeyDownSearch, handlePagination,
        formData, handleChange, handleBlur,listData, view, columnHeader, handleAdd,        
        handleSubmit, handleCancel, handleEdit, handleDelete, handleSubmitDelete
    } = useTangki({tipe: 'list'});

    const myButtonGroup = (props) => {
        return(
            <ButtonGroup>
                <Button className='btn-edit' color='success' onClick={() => handleEdit(props.data)}><i className='fa fa-edit'></i></Button>
                <Button className='btn-edit' color='danger' onClick={() => handleDelete(props.data)}><i className='fa fa-trash'></i></Button>
            </ButtonGroup>
        )
    }
    return(
        <Fragment>
            { isLoading && <LoaderComponent/>}
            <Alert isOpen={alertValue.show} toggle={handleCloseAlert} color={alertValue.color}>
                <div className="content" dangerouslySetInnerHTML={{__html: alertValue.text}}></div>
            </Alert>
            <Modal isOpen={modalValue.show} size='lg' toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>{modalValue.title}</ModalHeader>
                <ModalBody>
                    <Alert isOpen={alertValue.show} color={alertValue.color} toggle={handleCloseAlert}>
                        <div className="content" dangerouslySetInnerHTML={{__html: alertValue.text}}></div>    
                    </Alert>
                    { modalValue.text }
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                        <Button color='danger' onClick={handleSubmitDelete} size='sm'>Delete</Button>
                        <Button color='secondary' onClick={handleCloseModal} size='sm'>Cancel</Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>            
            <Card>
                <CardHeader>
                    <Row>
                        <Col lg={6}>Tangki</Col>
                    </Row>
                </CardHeader>
                <CardBody>
                { view === 'list' &&
                    <Fragment>
                    <Row>
                        <Col lg={8}>
                            <SearchComp
                                onChange={handleChangeSearch}
                                onClick={handleSearch}
                                onKeyDown={handleKeyDownSearch}
                                value={pageValue.search}
                            />
                        </Col>
                        <Col lg={4}>
                            <Button color='primary' onClick={handleAdd}><i className='fa fa-plus'></i></Button>
                        </Col>
                    </Row>
                    <DataTableComp
                        columns={columnHeader}
                        data={listData}
                        pageOffset={pageValue.offset}
                        countNum={true}
                        actionGroup={true}
                        MyButtonGroup={myButtonGroup}
                        clickTr={props.clickTr}
                    />
                    <PagePagination
                        data={pageValue.obj}
                        lastPage={pageValue.lastPage}
                        page={pageValue.page}
                        handlePagination={handlePagination}
                    />
                    </Fragment>
                }
                { ['create', 'edit'].includes(view) &&
                    <Fragment>
                        <FormGroup>
                            <Label>{formData.kode.label}</Label>
                            <FormTextSingleComp
                                {...formData.kode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{formData.kapasitas.label}</Label>
                            <FormTextSingleComp
                                {...formData.kapasitas}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </FormGroup>
                    </Fragment>
                }
                </CardBody>
                {
                    ['create', 'edit'].includes(view) &&
                    <CardFooter>
                        <ButtonGroup>
                            <Button size='sm' color='primary' onClick={handleSubmit}>Simpan</Button>
                            <Button size='sm' color='secondary' onClick={handleCancel}>Cancel</Button>
                        </ButtonGroup>
                    </CardFooter>
                }
            </Card>
        </Fragment>
    )
}
export default TangkiPage