import { useState, useCallback, useEffect } from "react"
import config from "../../../config";
import { useGlobalFuntction } from "../../../generalFunction/globalFunction"
import api from "../../../services/api";
import { pageObj } from "../../../utils/pageObj";


export function useKontak(props) {
    const { alertValue, setAlertValue, modalValue, setModalValue, isLoading, setIsLoading,
        handleCloseAlert, handleCloseModal, pageValue, setPageValue,
    } = useGlobalFuntction();
    const tipeKontak = props && props.tipeKontak ? props.tipeKontak : null
    const withDeposite = props.withDeposite || 'no'
    const outletId = props && props.outletId ? props.outletId : '';
    const tipeQuery = (props && props.tipeQuery) || 'all'
    const [listData, setListData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
        id : {
            value : 0,
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: false, 
            tipe: 'text', 
            label : 'ID', 
            name : 'id',
            readOnly: true
        },
        nama : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Nama', 
            name : 'nama',            
        },
        npwp : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'NPWP', 
            name : 'npwp',            
        },
        website : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Website', 
            name : 'website',
        },
        bank : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Bank', 
            name : 'bank',
        },
        nama_rekening : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Nama Rekening', 
            name : 'nama_rekening',
        },
        nomor_rekening : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Nomor Rekening', 
            name : 'nomor_rekening',
        },
        account_piutang : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: false, 
            tipe: 'text', 
            label : 'Account Piutang', 
            name : 'account_piutang',
        },
        account_piutang_label : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'input_button', 
            label : 'Account Piutang', 
            name : 'account_piutang_label',
            onClick : function(){
                handleShowAccount('piutang')
            },
            readOnly: true,
        },
        account_hutang : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: false, 
            tipe: 'text', 
            label : 'Account Hutang', 
            name : 'account_hutang',
        },
        account_hutang_label : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'input_button', 
            label : 'Account Hutang', 
            name : 'account_hutang_label',
            onClick : function(){
                handleShowAccount('hutang')
            },
            readOnly: true,
        },
        telp1 : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Telp 1', 
            name : 'telp1',
        },
        telp2 : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Telp 2', 
            name : 'telp2',
        },
        fax : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Fax', 
            name : 'fax',
        },
        email : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Email', 
            name : 'email',
        },
        alamat : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'textarea', 
            label : 'Alamat', 
            name : 'alamat',
        },
        memo : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'textarea', 
            label : 'Memo', 
            name : 'memo',
        },        
    })
    const [accountSelected, setAccountSelected] = useState('');

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage, tipe_query=tipeQuery) => {
        setIsLoading(true);
        try{
            let url = `${config.endPoint.kontak}?q=${q}&tipe_query=${tipe_query}&with_deposite=${withDeposite}`
            if (tipeKontak){
                url += `&tipe_kontak=${tipeKontak}`
            }
            if (outletId !== ''){
                url += `&outlet=${outletId}`;
            }
            const res = await api.get(url, {params : {limit : limit, offset: offset}}).then(res => res.data);
            setIsLoading(false);            
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))                
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setAlertValue, setPageValue, setIsLoading, tipeQuery, withDeposite, tipeKontak, outletId])    

    const handleShowAccount = (params) => {
        setAccountSelected(params);
        setModalValue(modalValue => ({...modalValue,
            show: true,
            tipe: 'account',
            title: 'Pilih Account'
        }))
    }

    const handleClickAccount = (post) => {
        if (accountSelected === 'hutang'){
            setFormData(formData => ({...formData,
                account_hutang : {...formData.account_hutang, value: post.id},
                account_hutang_label : {...formData.account_hutang_label, value : `${post.noakun} - ${post.nmakun}`}
            }))
        }else{
            setFormData(formData => ({...formData,
                account_piutang : {...formData.account_piutang, value: post.id},
                account_piutang_label : {...formData.account_piutang_label, value : `${post.noakun} - ${post.nmakun}`}
            }))
        }
        handleAdd();
    }
    
    useEffect(() => {
        if (props.tipe === 'list'){
            fetchData();
        }
    },[props.tipe, fetchData])
    


    const handlePagination = (page) => {
		let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
		setPageValue(pageValue => ({...pageValue, page: page, offset: myOffset}));		
		fetchData(pageValue.search, myOffset, config.itemPerPage, pageValue.tipe_kontak)
	}	

    const handleDelete = (post) => {        
        setModalValue(modalValue => ({...modalValue, show: true, text : `Apakah anda yakin akan menghapus kontak ${post.nama} ? `, title: 'Konfirmasi', tipe: 'delete'}));
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id}
        }))        
    }

    const handleSubmitDelete = async() => {
        try{
            await api.delete(`${config.endPoint.kontak}${formData.id.value}/`).then(res => res.data);
            fetchData(pageValue.search, pageValue.offset, config.itemPerPage, pageValue.tipe_kontak);
            handleCloseModal();
        }catch(error){
            setIsLoading(false);
            setPageValue(pageValue => ({...pageValue, show: true, text: 'Unable to delete data', color : 'danger'}))
            setTimeout(() => {
                setPageValue(pageValue => ({...pageValue, show: false}))
            }, 5000)
        }
    }

    const handleEdit = async(post) => {
        setIsEdit(true);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id, required: true},            
            nama : {...formData.nama, value : post.nama},
            npwp : {...formData.npwp, value : post.npwp},
            website : {...formData.website, value : post.website},
            bank : {...formData.bank, value : post.bank},
            nama_rekening : {...formData.nama_rekening, value : post.nama_rekening},
            nomor_rekening : {...formData.nomor_rekening, value : post.nomor_rekening},
            account_piutang : {...formData.account_piutang, value : post.account_piutang},
            account_piutang_label : {...formData.account_piutang_label, value : post.account_piutang_display ? `${post.account_piutang_display.noakun} - ${post.account_piutang_display.nmakun}` : '' },
            account_hutang : {...formData.account_hutang, value : post.account_hutang},
            account_hutang_label : {...formData.account_hutang_label, value : post.account_hutang_display ? `${post.account_hutang_display.noakun} - ${post.account_hutang_display.nmakun}` : ''},
            telp1 : {...formData.telp1, value : post.telp1},
            telp2 : {...formData.telp2, value : post.telp2},
            fax : {...formData.fax, value : post.fax},
            email : {...formData.email, value : post.email},
            alamat : {...formData.alamat, value : post.alamat},
            memo : {...formData.memo, value : post.memo},
        }));
        setModalValue(modalValue => ({...modalValue,
            title : 'Edit Kontak',
            tipe : 'edit',
            show: true
        }))
    }

    const handleAdd = () => {        
        setIsEdit(false);
        setModalValue(modalValue => ({...modalValue,
            title : 'Tambah Kontak',
            tipe : 'create',
            show: true
        }))
    }

 

    const validateForm = (formDetail, value) => {
        if (formDetail.name !== 'confirm_password'){            
            if (formDetail.required && value === ''){
                return {
                    'isError' : true,
                    'errorMessage' : formDetail.errorMessage
                }
            }else{
                return {
                    'isError' : false,
                    'errorMessage' : formDetail.errorMessage
                }
            }
        }else{            
            if (value !== formData.password.value){                
                return {
                    'isError' : true,
                    'errorMessage' : 'Confirm password did not match with password'
                }
            }else{                
                return {
                    'isError' : false,
                    'errorMessage' : 'Confirm password did not match with password'
                }
            }
        }
    }

    const handleCancel = () => {
        setFormData(formData => ({...formData,
            nama : {...formData.nama, value: ''},
            id : {...formData.id, value : 0, required: false}           
        }));
        setIsEdit(false);
    }

    const handleSubmit = async() => {
        setIsLoading(true)
        try{
            let tmpForm = {}
            let countError = 0;
            let array = {...formData};

            Object.entries(array).map(([index, post]) => {
                let validate = validateForm(post, post.value)
                if (validate.isError) {
                    countError++;
                    post.showError = true;
                    post.errorMessage = validate.errorMessage;
                }else{
                    tmpForm[index] = post.value;
                }
                return true;
            });

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
                setIsLoading(false);
                setTimeout(() => {
                    handleCloseAlert();
                }, 5000);
                return;
            }
            if (tipeKontak){
                tmpForm['tipe_kontak'] = tipeKontak
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm))
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.kontak}${formData.id.value}/`, newForm).then(res => res.data);                
            }else{
                res = await api.post(`${config.endPoint.kontak}`, newForm).then(res => res.data);
            }
            setIsLoading(false);
            if (res){
                if (props.handleCancel){
                    props.handleCancel()
                }else{
                    fetchData();
                    handleCancel();
                    handleCloseModal();
                }
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        let nilai = value;
        if (formData[name].tipe === 'number'){
            nilai = nilai.replace(/,/g, '');
        }
        setFormData(formData => ({...formData, [name] : {...formData[name], value: nilai}}))
        if (name === 'tipe_akun'){
            if ([1, '1'].includes(value)){
                setFormData(formData => ({...formData,
                    ac_name : {...formData.ac_name, show: true, required : true},
                    ac_number : {...formData.ac_number, show: true, required : true},
                }))
            }else{
                setFormData(formData => ({...formData,
                    ac_name : {...formData.ac_name, show: false, required : false },
                    ac_number : {...formData.ac_number, show: false, required : false },
                }))
            }
        }
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        let validate = validateForm(formData[name], value);
        setFormData(formData => ({...formData,
            [name] : {...formData[name], 
                showError: validate.isError,
                errorMsg : validate.errorMessage
            }
        }))
    }



    // ============ SEARCH ===============
    const handleChangeSearch = (e) => {
        const {name, value} = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}));
        if (name === 'tipe_kontak'){
            fetchData(pageValue.search, 0, config.itemPerPage, value)
        }
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage, pageValue.tipe_kontak);
        setPageValue(pageValue => ({...pageValue,
            page : 1,
            offset: 0
        }))
    }
        

    return {
        alertValue, setAlertValue, modalValue, setModalValue, isLoading, setIsLoading,
        handleCloseAlert, handleCloseModal, pageValue, setPageValue,
        listData, isEdit, handlePagination, handleSubmit, handleDelete, handleSubmitDelete,
        handleChangeSearch, handleSearch, handleKeyDownSearch, handleEdit, handleAdd,
        handleChange, handleBlur, formData, handleClickAccount, fetchData,
        accountSelected,
    }
}