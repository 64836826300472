import React, { Fragment } from 'react';
import { Alert, Button, ButtonGroup, Card, CardBody, CardHeader, Col,  FormGroup,  Label,  Modal,  ModalBody,  ModalFooter,  ModalHeader,  Row, Table } from 'reactstrap';
import FormTextSingleComp from '../../../containers/Components/FormTextSingleComp';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import PagePagination from '../../../containers/Components/PagePagination';
import SearchComp from '../../../containers/Components/SearchComp';
import { useGlobalFuntction } from '../../../generalFunction/globalFunction';
import AccountPage from '../../Accounting/Account/AccountPage';
import { useKontak } from './kontakFunction';

const KontakPage = (props) => {    
    const withDeposite = props.withDeposite || 'no';
    const disableEdit = props.disableEdit || false;
    const disableDelete = props.disableDelete || false;
    const tipeKontak = props.tipeKontak || null;
    const outletId = props && props.outletId ? props.outletId : '';
    const { 
        alertValue, modalValue,  isLoading,
        handleCloseAlert, handleCloseModal, pageValue, handleAdd,
        listData,  handlePagination, formData, handleChange, handleBlur,
        handleChangeSearch, handleSearch, handleKeyDownSearch, handleSubmit, handleClickAccount,
        accountSelected, handleEdit, handleSubmitDelete, handleDelete
    }  = useKontak({tipe:'list', withDeposite: withDeposite, tipeKontak: tipeKontak, outletId: outletId});
    
    const { getPermission } = useGlobalFuntction(); 
    
    

    return (
        <Fragment>
            { isLoading && <LoaderComponent/> }
            <Alert isOpen={alertValue.show} color={alertValue.color} toggle={handleCloseAlert}>
                {alertValue.text}
            </Alert>
            <Modal isOpen={modalValue.show} toggle={handleCloseModal} size="xl">
                { ['account'].includes(modalValue.tipe) ?
                    <AccountPage
                        tipe_query="without_open_balance"
                        handleClose={handleAdd}
                        clickTr={handleClickAccount}
                        nature={accountSelected}
                    />
                    :
                    <Fragment>
                        <ModalHeader toggle={handleCloseModal}>
                            {modalValue.title}
                        </ModalHeader>
                        <ModalBody>
                            { ['create', 'edit'].includes(modalValue.tipe) ?                                
                                <Row>
                                    <Col lg={6}>
                                        <div style={{width: '100%', backgroundColor: '#337ab7', paddingTop: 5, paddingBottom: 5, color: '#ffffff', marginBottom: 20, textAlign: 'center'}}>
                                            General Info
                                        </div>
                                        {
                                            Object.entries(Object.assign(...Object.keys(formData).slice(1, 4).map(k => ({ [k]: formData[k] })))).map(([index, post]) => {
                                                if (post.show) {
                                                    return(
                                                        <FormGroup row key={index}>
                                                            <Label className='col-lg-4'>{post.label} {post.required ? '*' : ''}</Label>
                                                            <Col lg={8}>
                                                                <FormTextSingleComp
                                                                    {...post}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    )
                                                }
                                                return true;
                                            })
                                        }
                                        <div style={{width: '100%', backgroundColor: '#f2dede', paddingTop: 5, paddingBottom: 5, color: '#a94442', marginBottom: 20, textAlign: 'center'}}>
                                            Bank Info
                                        </div>
                                        {
                                            Object.entries(Object.assign(...Object.keys(formData).slice(4, 7).map(k => ({ [k]: formData[k] })))).map(([index, post]) => {
                                                if (post.show) {
                                                    return(
                                                        <FormGroup row key={index}>
                                                            <Label className='col-lg-4'>{post.label} {post.required ? '*' : ''}</Label>
                                                            <Col lg={8}>
                                                                <FormTextSingleComp
                                                                    {...post}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    )
                                                }
                                                return true;
                                            })
                                        }
                                        <div style={{width: '100%', backgroundColor: '#d9edf7', paddingTop: 5, paddingBottom: 5, color: '#31708f', marginBottom: 20, textAlign: 'center'}}>
                                            Account Info
                                        </div>
                                        {
                                            Object.entries(Object.assign(...Object.keys(formData).slice(7, 11).map(k => ({ [k]: formData[k] })))).map(([index, post]) => {
                                                if (post.show) {
                                                    return(
                                                        <FormGroup row key={index}>
                                                            <Label className='col-lg-4'>{post.label} {post.required ? '*' : ''}</Label>
                                                            <Col lg={8}>
                                                                <FormTextSingleComp
                                                                    {...post}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    )
                                                }
                                                return true;
                                            })
                                        }
                                    </Col>
                                    <Col lg={6}>
                                        <div style={{width: '100%', backgroundColor: '#dff0d8', paddingTop: 5, paddingBottom: 5, color: '#3c763d', marginBottom: 20, textAlign: 'center'}}>
                                            Contact Info
                                        </div>
                                        {
                                            Object.entries(Object.assign(...Object.keys(formData).slice(11, 20).map(k => ({ [k]: formData[k] })))).map(([index, post]) => {
                                                if (post.show) {
                                                    return(
                                                        <FormGroup row key={index}>
                                                            <Label className='col-lg-4'>{post.label} {post.required ? '*' : ''}</Label>
                                                            <Col lg={8}>
                                                                <FormTextSingleComp
                                                                    {...post}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    )
                                                }
                                                return true;
                                            })
                                        }
                                    </Col>
                                </Row>
                                :
                                <>{ modalValue.text }</>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <ButtonGroup>
                                <Button size="sm" color={['create', 'edit'].includes(modalValue.tipe) ? "primary" : 'danger'} onClick={['create', 'edit'].includes(modalValue.tipe) ?  handleSubmit : handleSubmitDelete}>{ ['create', 'edit'].includes(modalValue.tipe) ? 'Simpan' : 'Delete'}</Button>
                                <Button size="sm" color="secondary" onClick={handleCloseModal}>Cancel</Button>
                            </ButtonGroup>
                        </ModalFooter>
                    </Fragment>
                }
            </Modal>
            <Card>
                <CardHeader>
                    <Row>
                        <Col lg={6}>
                            Kontak
                        </Col>
                        { props.handleClose &&
                            <Col lg={6}>
                                <div className="float-right">
                                    <i className='fa fa-times' onClick={props.handleClose} style={{cursor: 'pointer'}}></i>
                                </div>
                            </Col>                        
                        }
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg={6}>
                            <SearchComp
                                value={pageValue.search}
                                onChange={handleChangeSearch}
                                onKeyDown={handleKeyDownSearch}
                                onClick={handleSearch}
                            />
                        </Col>
                        { tipeKontak === null &&
                            <Col lg={4}>
                                <FormTextSingleComp
                                    tipe="select"
                                    value={pageValue.tipe_kontak}
                                    show={true}
                                    onChange={handleChangeSearch}
                                    name='tipe_kontak'
                                    obj={[{value: '', label : 'Semua Kontak'}, {value : 'konsumen', label : 'Konsumen'}, {value: 'suplier', label : 'Suplier'}]}
                                />
                            </Col>
                        }
                        <Col lg={2}>
                            { getPermission('add_kontak') &&
                                <Button color="success" onClick={handleAdd}><i className='fa fa-plus'></i></Button>
                            }
                        </Col>
                    </Row>
                    <Table className='small table-bordered table-center table-sm-padding table-striped table-header-withbackground'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nama</th>
                                <th>Alamat</th>
                                <th>Telp 1</th>
                                <th>Telp 2</th>
                                <th>Email</th>
                                <th>Akun Piutang</th>
                                <th>Akun Hutang</th>
                                { withDeposite === 'yes' &&
                                    <th>Saldo Deposite</th>
                                }
                                { (!disableEdit || !disableDelete) &&
                                    <>
                                        { (getPermission('change_kontak') ||  getPermission('delete_kontak')) &&
                                            <th>Action</th>
                                        }
                                    </>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            { listData.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : ''}} className="text-right">{pageValue.offset+1+index}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : ''}}>{post.nama}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : ''}}>{post.alamat}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : ''}}>{post.telp1}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : ''}}>{post.telp2}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : ''}}>{post.email}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : ''}}>{post.account_piutang_display && `${post.account_piutang_display.noakun} - ${post.account_piutang_display.nmakun}`}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : ''}}>{post.account_hutang_display && `${post.account_hutang_display.noakun} - ${post.account_hutang_display.nmakun}`}</td>
                                        { withDeposite === 'yes' &&
                                            <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : ''}} className='text-right'>{post.deposite && post.deposite.toLocaleString()}</td>
                                        }
                                        { (!disableEdit || !disableDelete) &&
                                            <>
                                                { (getPermission('change_kontak') ||  getPermission('delete_kontak')) &&
                                                    <td className="text-center">
                                                        <ButtonGroup>
                                                            { (getPermission('change_kontak') && !disableEdit) &&
                                                                <Button className='btn-edit' color="primary" onClick={() => handleEdit(post)}><i className='fa fa-edit'></i></Button>
                                                            }
                                                            { (getPermission('delete_kontak') && !disableDelete) &&
                                                                <Button className='btn-edit' color="danger" onClick={() => handleDelete(post)}><i className='fa fa-trash'></i></Button>
                                                            }
                                                        </ButtonGroup>
                                                    </td>
                                                }
                                            </>
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <PagePagination
                        data={pageValue.obj}
                        page={pageValue.page}
                        handlePagination={handlePagination}
                        lastPage={pageValue.lastPage}
                    />
                    {/* { pageValue.obj.length > 1 &&
                        <Pagination>
                            { pageValue.obj.map((post, index) => {
                                return(
                                    <PaginationItem key={index} className={pageValue.page === post ? 'active' : ''}>
                                        <PaginationLink tag="button" onClick={() => handlePagination(post)}>
                                            {post}
                                        </PaginationLink>
                                    </PaginationItem>
                                )
                            })}
                        </Pagination>
                    } */}
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default KontakPage;