import { useCallback, useContext, useEffect, useState } from "react";
import config from "../../../../config";
import { UserContext } from "../../../../context/UserContext";
import { useGlobalFuntction } from "../../../../generalFunction/globalFunction";
import api from "../../../../services/api";
import { pageObj } from "../../../../utils/pageObj";

export function useKategoriPartFunction(props){
    const { 
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, 
        handleVerificationSubmit, handleVerificationBlur, handleChangeFormData,
        fetchAccount, fetchProdukSatuan, getPermission, getStateColor,
    } = useGlobalFuntction();
    const myState = useContext(UserContext);
    const [alertState, alertDispatch] = myState.alertState;
    const [listData, setListData] = useState([]);
    const [view, setView] = useState('list');
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            value : 0,
            label : 'ID',
            tipe: 'text',
            show: false,
            required : false,
            showError : false,
            readOnly : true
        },
        nama : {
            name : 'nama',
            value : '',
            label : 'Nama',
            tipe: 'text',
            show: true,
            required : true,
            showError : false
        }
    })

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        try{
            let params = { 
                q : q,
                offset : offset,
                limit : limit
            }
            const res = await api.get(`${config.endPoint.kategoriSparePart}`, {params: params}).then(res => res.data);
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            alertDispatch({
                type: 'SHOW',
                color : 'danger',
                text : 'Unable to fetch data'
            })
            setTimeout(() => {
                alertDispatch({
                    type: 'HIDE',
                })
            }, config.timeOutValue)
        }
    },[alertDispatch, setIsLoading, setPageValue])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleEdit = (post) => {
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id},
            nama : {...formData.nama, value : post.nama}
        }));
        setIsEdit(true);
        setView('create');
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            text : `Apakah anda yakin akan menghapus kategori sparepart ${post.nama} ? `,
            id: post.id,
            title: 'Konfirmasi'
        }))
    }

    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.kategoriSparePart}${modalValue.id}/`).then(res => res.data);
            if (res){
                handleCloseModal();
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            alertDispatch({
                type: 'SHOW',
                color : 'danger',
                text : 'Unable to delete data'
            })
            setTimeout(() => {
                alertDispatch({
                    type: 'HIDE',
                })
            }, config.timeOutValue)
        }
    }

    // ========== search ===========
    const handleChangeSearch = (e) => {
        const {name, value} = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}));
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage);
        setPageValue(pageValue => ({...pageValue,
            page : 1,
            offset: 0
        }))
    }

    // ========== create ===========
    const handleClear = () => {
        setFormData(formData => ({...formData,
            nama : {...formData.nama, value : ''},
            id : {...formData.id, value : 0},
        }));
    }
    
    const handleAdd = () => {
        handleClear();
        setView('create');
        setIsEdit(false);                
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(handleChangeFormData(formData, name, value))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        setFormData(handleVerificationBlur(formData, name, value));
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let myForm = handleVerificationSubmit(formData);
            if (!myForm.success) {
                setIsLoading(false);
                alertDispatch({
                    type: 'SHOW',
                    text: 'Lengkapi data',
                    color: 'danger'
                })
                setTimeout(() => {
                    alertDispatch({
                        type: 'HIDE',
                    })
                }, config.timeOutValue)
                return;
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(myForm.formData));
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.kategoriSparePart}${formData.id.value}/`, newForm).then(res => res.data);                
            }else{
                res = await api.post(`${config.endPoint.kategoriSparePart}`, newForm).then(res => res.data);                
            }
            setIsLoading(false);
            if (res) {
                alertDispatch({
                    type: 'SHOW',
                    text: isEdit ? 'Berhasil merubah kategori sparepart' : 'Berhasil menambahkan kategori sparepart',
                    color: 'success'
                })
                setTimeout(() => {
                    alertDispatch({
                        type: 'HIDE',
                    })
                }, config.timeOutValue)
                handleCancel();
                fetchData();
            }
            
        }catch(error){
            setIsLoading(false);
            alertDispatch({
                type: 'SHOW',
                color : 'danger',
                text : 'Unable to fetch data'
            })
            setTimeout(() => {
                alertDispatch({
                    type: 'HIDE',
                })
            }, config.timeOutValue)
        }
    }

    const handleCancel = () => {
        setIsEdit(false);
        handleClear();
        setView('list');
    }


    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, 
        handleVerificationSubmit, handleVerificationBlur, handleChangeFormData,
        fetchAccount, fetchProdukSatuan, getPermission, getStateColor, 
        alertState, alertDispatch, listData, handleChange, handleBlur, handleAdd, view,
        handleEdit, handleDelete, handleSearch, handleChangeSearch, handleKeyDownSearch,
        formData, handleSubmit, handleCancel, handleSubmitDelete
    }
}