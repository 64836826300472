import { useCallback, useEffect, useState } from "react";
import config from "../../../config";
import { useGlobalFuntction } from "../../../generalFunction/globalFunction";
import api from "../../../services/api";
import { pageObj } from "../../../utils/pageObj";
import Moment from 'moment';
import { useHistory } from "react-router";



export function usePegawai(props){
    const tipe = props && props.tipe ? props.tipe : "pegawai";
    const history = useHistory();
    const { 
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, 
    } = useGlobalFuntction();
    const [listData, setListData] = useState([]);
    const [view, setView] = useState('list');
    

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage, status_aktif='') => {
        setIsLoading(true);
        try{
            let params = {
                limit : limit, 
                offset : offset,
                tipe : tipe,
                status_aktif: status_aktif
            }

            
            const res = await api.get(`${config.endPoint.pegawai}?q=${q}`, { params : params}).then(res => res.data);
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setAlertValue, setIsLoading, setPageValue, tipe])
        
    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handlePagination = (post) => {
        const myOffset = (parseInt(post) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        fetchData(pageValue.search, myOffset, config.itemPerPage, pageValue.statusAktif);
        setPageValue(pageValue => ({...pageValue, page : post, offset: myOffset}))
    }

    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage, pageValue.statusAktif);
        setPageValue(pageValue => ({...pageValue, page: 1, offset: 0}))
    }

    const handleChangeSearch = (e) => {
        const { name, value} = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}));
        if (name === 'statusAktif'){
            fetchData(pageValue.search, pageValue.offset, config.itemPerPage, value);
            setPageValue(pageValue => ({...pageValue, page: 1, offset: 0}))
        }
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    // =================================== form data ================================
    const [formData, setFormData] = useState({
        id : {
            value : 0,
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: false, 
            tipe: 'text', 
            label : 'Nama Jabatan', 
            name : 'id',
        },
        nip : {
            value : '',
            required : tipe === 'kandidat' ? false : true,
            showError : false, 
            errorMsg : '', 
            show: tipe === 'kandidat' ? false : true,
            tipe: 'text', 
            label : 'NIP', 
            name : 'nip',
            col: 6,
        },        
        nama : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Nama Pegawai', 
            name : 'nama',
            col: 6,
        },    
        is_supir : {
            value : false,
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'switch', 
            label : 'Supir', 
            name : 'is_supir',
            col: 6,
        },    
        jk : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Jenis Kelamin', 
            name : 'jk',
            col: 6,
            obj : [
                {value : '', label : '-- Pilih Jenis Kelaminin --'},
                {value : 1, label : 'Laki-laki'},
                {value : 2, label : 'Perempuan'},
            ]
        },    
        alamat : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'textarea', 
            label : 'Alamat', 
            name : 'alamat',
            col: 6,
        },
        email : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Email', 
            name : 'email',
            col: 6,
        },
        telp1 : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'HP', 
            name : 'telp1',
            col: 6,
        },
        tanggal_lahir : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'date', 
            label : 'Tanggal Lahir', 
            name : 'tanggal_lahir',
            col: 6,
        },
        tempat_lahir : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Tempat Lahir', 
            name : 'tempat_lahir',
            col: 6,
        },
        status_pegawai : {
            value : tipe === 'kandidat' ? 0 : 1,
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: tipe === 'kandidat' ? false : true,
            tipe: 'select', 
            label : 'Status Pegawai', 
            name : 'status_pegawai',
            col: 6,
            obj : [
                { value : '', label : '-- Pilih Status Pegawai --'},
                { value : 1, label :  'Training'},
                { value : 2, label :  'Tetap'},
                { value : 3, label :  'Outsourcing'},
                { value : 4, label :  'Kontrak'}
            ]
        },
        department : {
            value : '',
            required : tipe === 'kandidat' ? false : true,
            showError : false, 
            errorMsg : '', 
            show: tipe === 'kandidat' ? false : true,
            tipe: 'select', 
            label : 'Department', 
            name : 'department',
            col: 6,
            obj : [],
        },        
        jabatan : {
            value : '',
            required : tipe === 'kandidat' ? false : true,
            showError : false, 
            errorMsg : '', 
            show: tipe === 'kandidat' ? false : true,
            tipe: 'select', 
            label : 'Jabatan', 
            name : 'jabatan',
            col: 6,
            obj : [],
        },        
        start_date : {
            value : tipe === 'kandidat' ? null : '',
            required : tipe === 'kandidat' ? false : true,
            showError : false, 
            errorMsg : '', 
            show: tipe === 'kandidat' ? false : true,
            tipe: 'date', 
            label : 'Tanggal Mulai Bekerja', 
            name : 'start_date',
            col: 6,
        },
        bank : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: tipe === 'kandidat' ? false : true,
            tipe: 'text', 
            label : 'Bank', 
            name : 'bank',
            col: 6,
        },
        nama_rekening : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: tipe === 'kandidat' ? false : true,
            tipe: 'text', 
            label : 'Nama Rekening', 
            name : 'nama_rekening',
            col: 6,
        },
        nomor_rekening : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: tipe === 'kandidat' ? false : true,
            tipe: 'text', 
            label : 'Nomor Rekening', 
            name : 'nomor_rekening',
            col: 6,
        },
        foto_url : {
            name : 'foto_url',
            label : 'Foto Pegawai',
            value : '',
            tipe: 'foto',
            required : false,
            showError : false,
            show: false,            
        },
    });
    const [listFormPendidikan, setListFormPendidikan] = useState([]);
    const [formPendidikan, setFormPendidikan] = useState({
        id : {
            value : 0,
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: false, 
            tipe: 'number', 
            label : 'ID', 
            name : 'id',
            col: 6,
            readOnly: true,
        },
        jenis_pendidikan : {
            value : 'formal',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Jenis Pendidikan', 
            name : 'jenis_pendidikan',
            col: 6,
            obj : [
                {'value' : 'formal', "label" : 'Formal'},
                {'value' : 'non_formal', "label" : 'Non Formal'},
            ]
        },
        pendidikan : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Pendidikan', 
            name : 'pendidikan',
            col: 6,
            obj : [
                {value : '',  label : '-- Pilih Jenis Pendidikan --'},
                {value : 1,  label : 'SMP'},
                {value : 2,  label : 'SMA'},
                {value : 3,  label : 'D1'},
                {value : 4,  label : 'D3'},
                {value : 5,  label : 'S1'},
                {value : 6,  label : 'S2'},
            ],
        },
        tahun_lulus : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'number', 
            label : 'Tahun Kelulusan', 
            name : 'tahun_lulus',
            col: 6,
            className: 'text-right',
        },
        jurusan : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Jurusan', 
            name : 'jurusan',            
            col: 6,
        },
        nama_gelar : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Nama Gelar', 
            name : 'nama_gelar',
            helpText : '(contoh : Sarjana Ekonomi, Sarjana Teknik)',
            col: 6,
        },
        lulusan : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Tempat Pendidikan', 
            name : 'lulusan',
            col: 6,
        },
        nilai_ipk : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'number', 
            label : 'IPK / Nilai Rata rata', 
            name : 'nilai_ipk',
            col: 6,
        },
    })
    const [formPengalaman, setFormPengalaman] = useState({
        id : {            
            value : 0,
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: false, 
            tipe: 'text', 
            label : 'id', 
            name : 'id',            
            col: 6,
            readOnly: true,
        },
        perusahaan : {            
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Perusahaan', 
            name : 'perusahaan',            
            col: 6,            
        },
        dari_tahun : {            
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'number', 
            label : 'Dari Tahun', 
            name : 'dari_tahun',            
            col: 6,            
        },
        sd_tahun : {            
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'number', 
            label : 'Sampai Tahun', 
            name : 'sd_tahun',            
            col: 6,            
        },
        posisi : {            
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'textarea', 
            label : 'Posisi', 
            name : 'posisi',            
            col: 6,            
        },
    })    
    const [listFormPengalaman, setListFormPengalaman] = useState([]);
    const [formKetrampilan, setFormKetrampilan] = useState({
        kemampuan : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Kemampuan', 
            name : 'kemampuan',            
            col: 12,
        },
        tingkat_kemampuan : {
            value : 'Sedang',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Tingkat kemampuan',
            name : 'tingkat_kemampuan',
            col: 12,
            obj : [
                {value : 'Sedang', label : 'Sedang'},
                {value : 'Baik', label : 'Baik'},
                {value : 'Sangat Baik', label : 'Sangat Baik'},
            ]
        },
    })
    const [listKetrampilan, setListKetrampilan] = useState([]);
    const [activeTab, setActiveTab] = useState('pegawai')
    const [isEdit, setIsEdit] = useState(false);

    const fetchDepartment = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.department}`).then(res => res.data);
            let tmp = [{value : '', label : '-- Pilih Department --'}]
            if (res){
                res.results.map(post => tmp.push({value: post.id, label : post.nama}))
            }
            setFormData(formData => ({...formData, 
                department: {...formData.department, 
                    obj : tmp,
                    value : tmp.length > 0 ? tmp[0].value : ''
                }
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setIsLoading, setAlertValue])

    const fetchJabatan = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.jabatan}`).then(res => res.data);
            let tmp = [{value : '', label : '-- Pilih Jabatan --'}]
            if (res){
                res.results.map(post => tmp.push({value: post.id, label : post.nama}))
            }
            setFormData(formData => ({...formData, 
                jabatan: {...formData.jabatan, 
                    obj : tmp,
                    value : tmp.length > 0 ? tmp[0].value : ''
                }
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }, [setIsLoading, setAlertValue])

    useEffect(() => {
        fetchDepartment();
        fetchJabatan();
    },[fetchDepartment, fetchJabatan])

    const handleInsertFoto = (e) => {
        var nfile = e.target.files[0];        
        var reader = new FileReader();
        reader.readAsDataURL(nfile);
        reader.onloadend = function() {
            setFormData(formData => ({...formData, foto_url : {...formData.foto_url, value : reader.result}}))
        }
	}

    const handleEdit = (post) => {
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id},
            nip : {...formData.nip, value : post.nip, show : tipe === 'kandidat' ? false : true, required : tipe === 'kandidat' ? false : true},
            nama : {...formData.nama, value : post.kontak_display.nama},
            alamat : {...formData.alamat, value : post.kontak_display.alamat},
            jk : {...formData.jk, value : post.kontak_display.jk || ''},
            email : {...formData.email, value : post.kontak_display.email},
            telp1 : {...formData.telp1, value : post.kontak_display.hp},
            is_supir : {...formData.is_supir, value : post.is_supir},
            tanggal_lahir : {...formData.tanggal_lahir, value : post.kontak_display.tanggal_lahir_display !== '' ? Moment(post.kontak_display.tanggal_lahir_display, 'DD-MM-YYYY').toDate() : null},
            tempat_lahir : {...formData.tempat_lahir, value : post.tempat_lahir},
            status_pegawai : {...formData.status_pegawai, value : post.status_pegawai, show : tipe === 'kandidat' ? false : true},
            department : {...formData.department, value : post.department, show : tipe === 'kandidat' ? false : true, required : tipe === 'kandidat' ? false : true},
            jabatan : {...formData.jabatan, value : post.jabatan, show : tipe === 'kandidat' ? false : true, required : tipe === 'kandidat' ? false : true},
            // pendidikan : {...formData.pendidikan, value : post.pendidikan, show : tipe === 'kandidat' ? false : true, required : tipe === 'kandidat' ? false : true},
            jurusan : {...formData.jurusan, value : post.jurusan},
            foto_url : {...formData.foto_url, value : post.foto},
            lulusan : {...formData.lulusan, value : post.lulusan},
            bank : {...formData.bank, value : post.kontak_display.bank, show : tipe === 'kandidat' ? false : true},
            start_date : {...formData.start_date, value : post.start_date_display !== '' ? Moment(post.start_date_display, 'DD-MM-YYYY').toDate() : null, show : tipe === 'kandidat' ? false : true, required : tipe === 'kandidat' ? false : true},
            nama_rekening : {...formData.nama_rekening, value : post.kontak_display.nama_rekening, show : tipe === 'kandidat' ? false : true},
            nomor_rekening : {...formData.nomor_rekening, value : post.kontak_display.nomor_rekening, show : tipe === 'kandidat' ? false : true},
        }));
        
        let tmpPendidikan = []
        post.list_pendidikan.map(post => {
            let objPendidikan = {...post}
            objPendidikan['textPendidikan'] = post.pendidikan_display
            objPendidikan['textJenisPendidikan'] = post.is_formal_display_text
            tmpPendidikan.push(objPendidikan);            
            return true;
        })
        setListFormPendidikan(tmpPendidikan)

        setListFormPengalaman(post.list_pengalaman_kerja)

        setListKetrampilan(post.list_kemampuan)
        setIsEdit(true);
        setView('create')
    }

    const handleAdd = () => {
        localStorage.removeItem('pegawaiId');
        setFormData(formData => ({...formData,
            id : {...formData.id, value : ''},
            nip : {...formData.nip, value : '', show : tipe === 'kandidat' ? false : true, required : tipe === 'kandidat' ? false : true},
            nama : {...formData.nama, value : ''},
            alamat : {...formData.alamat, value : ''},
            jk : {...formData.jk, value : ''},
            email : {...formData.email, value : ''},
            telp1 : {...formData.telp1, value : ''},
            tanggal_lahir : {...formData.tanggal_lahir, value : null},
            tempat_lahir : {...formData.tempat_lahir, value : ''},
            status_pegawai : {...formData.status_pegawai, value : tipe === 'kandidat' ? 0 : 1, show : tipe === 'kandidat' ? false : true, required : tipe === 'kandidat' ? false : true},
            department : {...formData.department, value : tipe === 'kandidat' ? '' : formData.department.obj.length > 0 ? formData.department.obj[0].value : '', show : tipe === 'kandidat' ? false : true, required : tipe === 'kandidat' ? false : true},
            jabatan : {...formData.jabatan, value : tipe === 'kandidat' ? '' :  formData.jabatan.obj.length > 0 ? formData.jabatan.obj[0].value : ''},
            // pendidikan : {...formData.pendidikan, value : ''},
            jurusan : {...formData.jurusan, value : ''},
            foto_url : {...formData.foto_url, value : ''},
            lulusan : {...formData.lulusan, value : ''},
            bank : {...formData.bank, value : '', show : tipe === 'kandidat' ? false : true},
            start_date : {...formData.start_date, value : null, show : tipe === 'kandidat' ? false : true, required : tipe === 'kandidat' ? false : true},
            nama_rekening : {...formData.nama_rekening, value : '', show : tipe === 'kandidat' ? false : true},
            nomor_rekening : {...formData.nomor_rekening, value : '', show : tipe === 'kandidat' ? false : true},
        }));
        // setModalValue(modalValue => ({...modalValue, show: true, tipe: 'create', title: 'Edit Master Pegawai'}))
        // setModalValue(modalValue => ({...modalValue, tipe: 'create', show: true, title: 'Tambah Master Pegawai'}));
        setIsEdit(false);
        setView('create')
    }

    const handleChange = (e, formName) => {
        const { name, value } = e.target;
        let nilai = value;
        if (formName === 'formData'){
            if (formData[name].tipe === 'number'){
                nilai = nilai.replace(/,/g, '');
            }
            setFormData(formData => ({...formData, [name] : {...formData[name], value : nilai}}))
        }else if(formName === 'formPendidikan'){
            if (formPendidikan[name].tipe === 'number'){
                nilai = nilai.replace(/,/g, '');
            }
            setFormPendidikan(formPendidikan => ({...formPendidikan, [name] : {...formPendidikan[name], value : nilai}}))            
            if (name === 'jenis_pendidikan'){
                if (value === 'non_formal'){
                    setFormPendidikan(formPendidikan => ({...formPendidikan,
                        nama_gelar : {...formPendidikan.nama_gelar, label : 'Nama Pendidikan / Kursus', helpText : ''},
                        tahun_lulus : {...formPendidikan.tahun_lulus, label : 'Tahun'},
                        pendidikan : {...formPendidikan.pendidikan, value : '', show : false, required: false},
                        jurusan : {...formPendidikan.jurusan, value : '', show : false, required: false},
                        nilai_ipk : {...formPendidikan.nilai_ipk, value : '', show : false, required: false},
                    }))
                }else{
                    setFormPendidikan(formPendidikan => ({...formPendidikan,
                        nama_gelar : {...formPendidikan.nama_gelar, label : 'Nama Gelar', helpText : '(contoh : Sarjana Ekonomi, Sarjana Teknik)'},
                        tahun_lulus : {...formPendidikan.tahun_lulus, label : 'Tahun Kelulusan'},
                        pendidikan : {...formPendidikan.pendidikan, value : '', show : true, required: true},
                        jurusan : {...formPendidikan.jurusan, value : '', show : true, required: true},
                        nilai_ipk : {...formPendidikan.nilai_ipk, value : '', show : true, required: true},
                    }))
                }
            }
        }else if (formName === 'formPengalaman'){
            if (formPengalaman[name].tipe === 'number'){
                nilai = nilai.replace(/,/g, '');
            }
            setFormPengalaman(formPengalaman => ({...formPengalaman, [name] : {...formPengalaman[name], value : nilai}}))
        }else if (formName === 'formKetrampilan'){
            if (formKetrampilan[name].tipe === 'number'){
                nilai = nilai.replace(/,/g, '');
            }
            setFormKetrampilan(formKetrampilan => ({...formKetrampilan, [name] : {...formKetrampilan[name], value : nilai}}))
        }
    }

    const handleChangeDate = (e, name) => {
        setFormData(formData => ({...formData, [name] : {...formData[name], value : e}}))
    }
    
    const handleBlur = (e, formName) => {
        const { name, value } = e.target;
        if (formName === 'formData'){
            if (formData[name].required && value === ''){
                setFormData(formData => ({...formData, [name] : {...formData[name], showError : true}}))
            }else{
                setFormData(formData => ({...formData, [name] : {...formData[name], showError : false}}))
            }
        }else if(formName === 'formPendidikan'){
            if (formPendidikan[name].required && value === ''){
                setFormPendidikan(formPendidikan => ({...formPendidikan, [name] : {...formPendidikan[name], showError : true}}))
            }else{
                setFormPendidikan(formPendidikan => ({...formPendidikan, [name] : {...formPendidikan[name], showError : false}}))
            }
        }else if(formName === 'formPengalaman'){
            if (formPengalaman[name].required && value === ''){
                setFormPengalaman(formPengalaman => ({...formPengalaman, [name] : {...formPengalaman[name], showError : true}}))
            }else{
                setFormPengalaman(formPengalaman => ({...formPengalaman, [name] : {...formPengalaman[name], showError : false}}))
            }
        }else if(formName === 'formKetrampilan'){
            if (formKetrampilan[name].required && value === ''){
                setFormKetrampilan(formKetrampilan => ({...formKetrampilan, [name] : {...formKetrampilan[name], showError : true}}))
            }else{
                setFormKetrampilan(formKetrampilan => ({...formKetrampilan, [name] : {...formKetrampilan[name], showError : false}}))
            }
        }
    }

    const afterSubmitToEdit = async(id) => {
        setIsLoading(true)
        try{
            const res = await api.get(`${config.endPoint.pegawai}${id}/`).then(res => res.data);
            if (res){
                setFormData(formData => ({...formData,
                    id : {...formData.id, value : res.results.id},
                    nip : {...formData.nip, value : res.results.nip},
                    nama : {...formData.nama, value : res.results.kontak_display.nama},
                    alamat : {...formData.alamat, value : res.results.kontak_display.alamat},
                    email : {...formData.email, value : res.results.kontak_display.email},
                    telp1 : {...formData.telp1, value : res.results.kontak_display.hp},
                    tanggal_lahir : {...formData.tanggal_lahir, value : res.results.kontak_display.tanggal_lahir_display !== '' ? Moment(res.results.kontak_display.tanggal_lahir_display, 'DD-MM-YYYY').toDate() : null},
                    tempat_lahir : {...formData.tempat_lahir, value : res.results.tempat_lahir},
                    status_pegawai : {...formData.status_pegawai, value : res.results.status_pegawai},
                    department : {...formData.department, value : res.results.department},
                    jabatan : {...formData.jabatan, value : res.results.jabatan},
                    pendidikan : {...formData.pendidikan, value : res.results.pendidikan},
                    jurusan : {...formData.jurusan, value : res.results.jurusan},
                    lulusan : {...formData.lulusan, value : res.results.lulusan},
                    bank : {...formData.bank, value : res.results.kontak_display.bank},
                    start_date : {...formData.start_date, value : res.results.start_date_display !== '' ? Moment(res.results.start_date_display, 'DD-MM-YYYY').toDate() : null},
                    nama_rekening : {...formData.nama_rekening, value : res.results.kontak_display.nama_rekening},
                    nomor_rekening : {...formData.nomor_rekening, value : res.results.kontak_display.nomor_rekening},
                    foto_url : {...formData.foto_url, value : res.results.foto},
                }));
                
                let tmpPendidikan = []
                res.results.list_pendidikan.map(post => {
                    let objPendidikan = {...post}
                    objPendidikan['textPendidikan'] = post.pendidikan_display
                    objPendidikan['textJenisPendidikan'] = post.is_formal_display_text
                    tmpPendidikan.push(objPendidikan);            
                    return true;
                })
                setListFormPendidikan(tmpPendidikan)
        
                setListFormPengalaman(res.results.list_pengalaman_kerja)
        
                setListKetrampilan(res.results.list_kemampuan)
                setIsEdit(true);
                setView('create')
            }
        }catch(error){
            setIsLoading(false);
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)       
        }
    }

    const handleSubmit  = async() => {
        setIsLoading(true);
        try{
            let countError = 0;
            let array = {...formData};
            let tmpData = {};

            Object.entries(array).map(([index, post]) => {
                if (post.required && (post.value === '' || post.value === null)){
                    post.showError = true;
                    countError++;
                }else{
                    tmpData[index] = post.value;
                }
                return true;
            })

            if (countError > 0){
                setFormData(array);
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi data', color: 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                return;
            }
            tmpData['tanggal_lahir'] = Moment(tmpData['tanggal_lahir']).format('YYYY-MM-DD')
            if (tipe === 'pegawai'){
                tmpData['start_date'] = Moment(tmpData['start_date']).format('YYYY-MM-DD')
            } 

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpData));
            
            let res;
            if (isEdit){
                newForm.append('formPendidikan', JSON.stringify(listFormPendidikan))
                newForm.append('formPengalaman', JSON.stringify(listFormPengalaman))
                newForm.append('formKetrampilan', JSON.stringify(listKetrampilan))
                res = await api.put(`${config.endPoint.pegawai}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.pegawai}`, newForm).then(res => res.data);
            }
            if (res){
                afterSubmitToEdit(res.id);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)        
        }
    }
    
    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            await api.delete(`${config.endPoint.pegawai}${formData.id.value}/`).then(res => res.data);
            handleCloseModal();
            fetchData();
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'unable to delete data', color: 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue, show: true, tipe: 'delete', title: 'Konfirmasi'}));
        setFormData(formData => ({...formData, 
            id : {...formData.id, value : post.id},
            nama : {...formData.nama, value : post.kontak_display.nama}
        }))
    }

    const handleChangeTab = (params) => {
        setActiveTab(params);
    }

    const handleCancel = () => {
        setView('list');
        setIsEdit(false);
        fetchData();
    }

    const handleAddListForm = (formName) => {
        let array = [];
        if (formName === 'formPendidikan'){
            array = [...listFormPendidikan];
            let tmp = {}
            let countError = 0;        
            Object.entries(formPendidikan).map(([index, post]) => {
                if (index === 'pendidikan'){
                    let tmpTextPendidikan = post.obj.filter(x => parseInt(x.value) === parseInt(post.value));
                    if (tmpTextPendidikan.length > 0){
                        tmp['textPendidikan'] = tmpTextPendidikan[0].label
                    }
                }
                if (index === 'jenis_pendidikan'){
                    let tmpTextJenisPendidikan = post.obj.filter(x => x.value === post.value);
                    if (tmpTextJenisPendidikan.length > 0){
                        tmp['textJenisPendidikan'] = tmpTextJenisPendidikan[0].label
                    }
                }
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;
                }else{
                    tmp[index] = post.value;
                }
                return true;
            })
            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}));
                }, config.timeOutValue)
                return;
            }
            array.push(tmp);
            setListFormPendidikan(array);
            
            setFormPendidikan({
                id : {...formPendidikan.id, value : 0},
                jenis_pendidikan : {...formPendidikan.jenis_pendidikan, value : 'formal'},
                pendidikan : {...formPendidikan.pendidikan, value : ''},
                tahun_lulus : {...formPendidikan.tahun_lulus, value : ''},
                jurusan : {...formPendidikan.jurusan, value : ''},
                lulusan : {...formPendidikan.lulusan, value : ''},
                nilai_ipk : {...formPendidikan.nilai_ipk, value : ''},
                nama_gelar: {...formPendidikan.nama_gelar, value : ''}
            })
        }else if(formName === 'formPengalaman'){
            array = [...listFormPengalaman];
            let tmp = {}
            let countError = 0;        
            Object.entries(formPengalaman).map(([index, post]) => {                
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;
                }else{
                    tmp[index] = post.value;
                }
                return true;
            })
            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}));
                }, config.timeOutValue)
                return;
            }
            array.push(tmp);
            setListFormPengalaman(array);
            
            setFormPengalaman({
                id : {...formPengalaman.id, value : 0},                
                perusahaan : {...formPengalaman.perusahaan, value : ''},
                dari_tahun : {...formPengalaman.dari_tahun, value : ''},
                sd_tahun : {...formPengalaman.sd_tahun, value : ''},
                posisi : {...formPengalaman.posisi, value : ''},
            })
        }else if(formName === 'formKetrampilan'){            
            array = [...listKetrampilan];
            let tmp = {}
            let countError = 0;        
            Object.entries(formKetrampilan).map(([index, post]) => {
                if (index === 'tingkat_kemampuan'){
                    let tmpTingkatKemampuan = post.obj.filter(x => x.value === post.value);
                    if (tmpTingkatKemampuan.length > 0){
                        tmp['textTingkatKemampuan'] = tmpTingkatKemampuan[0].label
                    }
                }

                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;
                }else{
                    tmp[index] = post.value;
                }
                return true;
            })
            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}));
                }, config.timeOutValue)
                return;
            }
            array.push(tmp);
            setListKetrampilan(array);
            
            setFormKetrampilan({                    
                kemampuan : {...formKetrampilan.kemampuan, value : ''},
                tingkat_kemampuan : {...formKetrampilan.tingkat_kemampuan, value : 'Sedang'},
            })            
        }
    }    
    const handleDeleteListForm  = (index, formName) => {
        let array = []
        if (formName === 'formPendidikan'){
            array = [...listFormPendidikan];
            array.splice(index, 1);
            setListFormPendidikan(array);
        }else if(formName === 'formPengalaman'){
            array = [...listFormPengalaman];
            array.splice(index, 1);
            setListFormPengalaman(array);
        }else if (formName === 'formKetrampilan'){
            array = [...listKetrampilan];
            array.splice(index, 1);
            setListKetrampilan(array);
        }
    }

    const handleRedirectCv = (post) => {        
        window.open(`#/pegawai/cv/${post.id}`, "_blank")
    }
    
    const handleCetakCv = () => {        
        window.open(`#/pegawai/cv/${formData.id.value}`, "_blank")
    }

    
    const handleChangeKandidatToPegawai = (post) => {
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id},
            nip : {...formData.nip, value : '', show: true, required: true},
            nama : {...formData.nama, value : post.kontak_display.nama},
            alamat : {...formData.alamat, value : post.kontak_display.alamat},
            email : {...formData.email, value : post.kontak_display.email},
            telp1 : {...formData.telp1, value : post.kontak_display.hp},
            tanggal_lahir : {...formData.tanggal_lahir, value : post.kontak_display.tanggal_lahir_display !== '' ? Moment(post.kontak_display.tanggal_lahir_display, 'DD-MM-YYYY').toDate() : null},
            tempat_lahir : {...formData.tempat_lahir, value : post.tempat_lahir},
            status_pegawai : {...formData.status_pegawai, value : '', show: true, required: true},
            department : {...formData.department, value : '', show: true, required: true},
            jabatan : {...formData.jabatan, value : '', show: true, required: true},
            pendidikan : {...formData.pendidikan, value : post.pendidikan},
            jurusan : {...formData.jurusan, value : post.jurusan},
            lulusan : {...formData.lulusan, value : post.lulusan},
            bank : {...formData.bank, value : post.kontak_display.bank, show: true},
            start_date : {...formData.start_date, value : post.start_date_display !== '' ? Moment(post.start_date_display, 'DD-MM-YYYY').toDate() : null, show: true, required: true},
            nama_rekening : {...formData.nama_rekening, value : post.kontak_display.nama_rekening, show: true},
            nomor_rekening : {...formData.nomor_rekening, value : post.kontak_display.nomor_rekening, show: true},
        }));
        
        let tmpPendidikan = []
        post.list_pendidikan.map(post => {
            let objPendidikan = {...post}
            objPendidikan['textPendidikan'] = post.pendidikan_display
            objPendidikan['textJenisPendidikan'] = post.is_formal_display_text
            tmpPendidikan.push(objPendidikan);            
            return true;
        })
        setListFormPendidikan(tmpPendidikan)

        setListFormPengalaman(post.list_pengalaman_kerja)

        setListKetrampilan(post.list_kemampuan)
        setIsEdit(true);
        setView('createFromKandidat')
    }

    const handleDetail = (post) => {
        sessionStorage.setItem('pegawaiId', post.id);
        history.push('/hrd/pegawai/detail')
    }

    const handleChangeStatusAktif = (post) => {
        sessionStorage.setItem('pegawaiId', post.id);
        history.push('/hrd/status-pegawai')
    }

    const handleChangeSwitch = (e, formName, name) => {     
        let checked = e.target.checked;   
        if (formName === 'formData'){
            setFormData(formData => ({...formData,
                [name] : {...formData[name], value : checked}
            }))
        }
    }
    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, listData, handlePagination,
        handleSearch, handleChangeSearch, handleKeyDownSearch, handleChange, handleBlur,
        handleAdd, formData, handleSubmit, handleSubmitDelete, handleEdit, handleDelete, handleChangeDate,
        view, isEdit, handleChangeTab, activeTab, handleCancel, formPendidikan, listFormPendidikan, handleAddListForm,
        handleDeleteListForm, formPengalaman, listFormPengalaman, formKetrampilan, listKetrampilan,
        handleRedirectCv, handleCetakCv, handleChangeKandidatToPegawai, handleDetail,
        handleInsertFoto, handleChangeStatusAktif, handleChangeSwitch
    }
}