import React from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import config from '../../config';


const KontrakDoPrint = (props) => {
    let info = JSON.parse(localStorage.getItem(config.localStorageKey.DO_KENDARAAN)) || null;
    let perusahaan = JSON.parse(localStorage.getItem(config.localStorageKey.PERUSAHAAN)) || null;
    return (
        <div className='laporan-lanscape' style={{ background: '#ffffff' }}>
            <div className="header">
                <Row>
                    <Col xs={12}>
                        <div className="text-center title" style={{ fontWeight: 'bold' }}>
                            {perusahaan && perusahaan.nama}
                        </div>
                        <div className="text-center title">
                            {perusahaan && perusahaan.alamat}
                        </div>
                    </Col>
                </Row>
                <Row className=''>
                    <Col xs={12} style={{ padding: 40 }}>
                        <div
                            style={{
                                border: '1px solid #000000',
                                // paddingLeft: '20px !important',
                                paddingLeft: 20,
                                paddingRight: 20,
                                borderRadius: 10,
                                paddingTop: 40,
                                paddingBottom: 40,
                            }}
                        >
                            <Row>
                                <Col lg={8} xs={8}>
                                    <FormGroup row className="without-margin-bottom">
                                        <Label className='col-lg-2'>Nomor</Label>
                                        <Label className='col-lg-8'>: 001/ILD-PCI/MOL/II/2022</Label>
                                    </FormGroup>
                                    <FormGroup row className="without-margin-bottom">
                                        <Label className='col-lg-2'>Nomor DO</Label>
                                        <Label className='col-lg-8'>: 001/ILD-PCI/MOL/II/2022</Label>
                                    </FormGroup>
                                </Col>
                                <Col lg={4} xs={4}>
                                    <div>B.Lampung, ..........2022</div>
                                    <div style={{ marginTop: 20, marginBottom: 5 }}>Kepada Yth,</div>
                                    <div style={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}>{info && info.kontrak_display.rekanan_display}</div>
                                    <div style={{ marginTop: 5 }}>di <span style={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}>{info && info.kontrak_display.alamat_rekanan}</span></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} xs={12}>
                                    <div style={{ marginTop: 15, marginBottom: 15 }}>Dengan Hormat,</div>
                                    <div>Bersama ini mohon kendaraan kami</div>
                                    <div style={{ borderBottom: '1px dotted #000' }}>Dengan Nomor Polisi : {info && info.kendaraan_display.nopol}</div>
                                    <div style={{ marginTop: 10, marginBottom: 10, borderBottom: '1px dotted #000' }}>Dapat kiranya diberikan muatan : {info && info.kontrak_display.jenis_barang}</div>
                                    <div style={{ borderBottom: '1px dotted #000' }}>Untuk ke : {info && info.kontrak_display.pembeli_display} </div>
                                    <div>Sebagaimana biasanya. </div>
                                    <div>Demikianlah atas semua bantuannya kami ucapkan banyak terima kasih.</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} xs={8}>
                                </Col>
                                <Col lg={4} xs={4}>
                                    <div className="text-center">
                                        <div style={{ fontWeight: 'bold', marginTop: 80 }}>Hormat Kami,</div>
                                        <div style={{ marginTop: 60, fontWeight: 'bold' }}>IBRAHIM.ZA</div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default KontrakDoPrint;