import React, { Fragment, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router";
import config from "../../../config";
import LoaderComponent from "../../../containers/Components/LoaderComponent";
import { useGlobalFuntction } from "../../../generalFunction/globalFunction";
import api from "../../../services/api";
import '../../../style/cvStyle.css';

import MailIcon from '../../../assets/img/icon/mail-icon.png';
import PhoneIcon from '../../../assets/img/icon/phone-icon.png';
import HomeIcon from '../../../assets/img/icon/home-icon.png';
import MonitorIcon from '../../../assets/img/icon/monitor-icon.png';
import SarjanaIcon from '../../../assets/img/icon/sarjana-icon.png';
import SkillIcon from '../../../assets/img/icon/skiils-icon.png';

const PegawaiCVPrint = (props) => {
    const {pegawaiId} = useParams();    
    const [pegawaiInfo, setPegawaiInfo] = useState(null);
    const { isLoading, setIsLoading} = useGlobalFuntction();
    
    const fetchPegawaiInfo = useCallback(async(id=0) => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.pegawai}${pegawaiId}/`).then(res => res.data);
            if (res){
                setPegawaiInfo(res.results);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);            
        }
    }, [pegawaiId, setIsLoading])

    useEffect(() => {
        fetchPegawaiInfo();
    },[fetchPegawaiInfo])

    return(
        <Fragment>
            { isLoading && <LoaderComponent/>}
            <div className='containeri'>
                <div className="container-left">
                    <div className="primary-title">CURRICULUM VITAE</div>
                    <div className="img-container">
                        <div className="striped">
                            <img src={pegawaiInfo && pegawaiInfo.foto} alt="foto"/>
                        </div>                    
                    </div>
                    <div className="employee-name">
                        {pegawaiInfo && pegawaiInfo.kontak_display.nama.toUpperCase()}
                    </div>
                    <div className="contact-container">
                        <div className="title">CONTACT</div>
                        <ul className="ul-contact">
                            <li>
                                <div className="icon-container">
                                    <img alt="icon" src={MailIcon} className='icon' />
                                </div> 
                                <div className='icon-content'>{pegawaiInfo && pegawaiInfo.kontak_display.email}</div>
                            </li>
                            <li>
                                <div className="icon-container">
                                    <img alt="icon" src={PhoneIcon} className='icon' />
                                </div> 
                                <div className='icon-content'>{pegawaiInfo && pegawaiInfo.kontak_display.hp}</div>
                            </li>
                            <li>
                                <div className="icon-container">
                                    <img alt="icon" src={HomeIcon} className='icon' />
                                </div> 
                                <div className='icon-content'>{pegawaiInfo && pegawaiInfo.kontak_display.alamat}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="container-right">                
                    <div className="experience-container">
                        <div className="title-container">
                            <div className="icon-container">
                                <img alt="icon" src={MonitorIcon} className="icon" />
                            </div>
                            <div className="label-title">PENGALAMAN KERJA</div>                        
                        </div>
                        <div className="experience-content">
                            <ul className='ul-experience'>
                                { pegawaiInfo && pegawaiInfo.list_pengalaman_kerja.map((post, index) => {
                                    return(
                                        <li key={index}>
                                            <div className='experience-perusahaan'>{post.perusahaan.toUpperCase()}</div>
                                            <div className='experience-tahun'>{`${post.dari_tahun} - ${post.sd_tahun}`}</div>
                                            <div className='experience-posisi'>{post.posisi}</div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="study-container">
                        <div className="title-container">
                            <div className="icon-container">
                                <img alt="icon" src={SarjanaIcon} className="icon" />
                            </div>
                            <div className="label-title">PENDIDIKAN</div>
                        </div>
                        <div className="study-content">
                            <div className="formal-title" style={{marginBottom: 10}}>FORMAL</div>
                            <ul className='ul-study'>
                                { pegawaiInfo && pegawaiInfo.list_pendidikan.map((post, index) => {
                                    if (post.is_formal){
                                        return(
                                            <li key={index}>
                                                <div className='universitas'>{post.lulusan}</div>
                                                <div className='fakultas-container'>
                                                    <div className='fakultas'>{post.nama_gelar}</div>
                                                    <div className="tahun-lulus">{post.tahun_lulus}</div>
                                                </div>
                                            </li>
                                        )
                                    }
                                    return true;
                                })}                                
                            </ul>
                            <div className="formal-title" style={{marginTop: 20, marginBottom: 10}}>NON FORMAL</div>
                            <ul className='ul-study'>
                                { pegawaiInfo && pegawaiInfo.list_pendidikan.map((post, index) => {
                                    if (!post.is_formal){
                                        return(
                                            <li key={index}>
                                                <div className='universitas'>{post.lulusan}</div>
                                                <div className='fakultas-container'>
                                                    <div className='fakultas'>{post.nama_gelar}</div>
                                                    <div className="tahun-lulus">{post.tahun_lulus}</div>
                                                </div>
                                            </li>
                                        )
                                    }
                                    return true;
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="kemampuan-container">
                        <div className="title-container">
                            <div className="icon-container">
                                <img alt="icon" src={SkillIcon} className="icon" />
                            </div>
                            <div className="label-title">SKIL</div>
                        </div>
                        <div className="kemampuan-content">
                            { pegawaiInfo && pegawaiInfo.list_kemampuan.map((post, index) => {
                                return(
                                    <div className="progress-content" key={index}>
                                        <div>{post.kemampuan}</div>
                                        <div className="w3-light-grey w3-round">
                                            <div className="w3-container w3-round w3-blue progress-bar" style={{width: post.tingkat_kemampuan === 'Sangat Baik' ? "100%" : post.tingkat_kemampuan === 'Baik' ? "75%" :  "50%"}}></div>
                                        </div> 
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default PegawaiCVPrint