import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Table } from 'reactstrap';
import  classNames from 'classnames';

const KendaraanTableComp = (props) => {
    const { listData, pageOffset, className, handleEdit, handleDelete } = props;
    return(
        <Fragment>
            <Table className={classNames('table-bordered table-small table-sm-padding table-header-withbackground table-center', className)} responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nomor Polisi</th>
                        <th>Tipe Kendaraan</th>
                        <th>Merk Kendaraan</th>
                        <th>Tahun Perakitan</th>
                        <th>Nomor Rangka</th>
                        <th>Nomor Mesin</th>
                        <th>Kapasitas</th>
                        <th>Tanggal KIR</th>
                        <th>Tanggal PKB</th>
                        <th>Tangki</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    { listData.map((post, index) => {
                        return(
                            <tr key={index}>
                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}} className='text-right'>{index+1+pageOffset}</td>
                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{(post.tanggal_kir_display.early_warning || post.tanggal_pkb_display.early_warning) && <><i className='fa fa-info-circle' style={{color: 'red'}}></i>&nbsp;</>} {post.nopol}</td>
                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.tipe}</td>
                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.merk}</td>
                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}} className='text-center'>{post.tahun_perakitan_display}</td>
                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.chasis}</td>
                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.engine}</td>
                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.kapasitas}</td>
                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>
                                    <div className={post.tanggal_kir_display.early_warning ? 'label-danger' : ''}>{post.tanggal_kir_display.tanggal}</div>
                                </td>
                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>
                                    <div className={post.tanggal_pkb_display.early_warning ? 'label-danger' : ''}>{post.tanggal_pkb_display.tanggal}</div>
                                </td>
                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.tangki_display}</td>
                                <td>
                                    <ButtonGroup>
                                        <Button className='btn-edit' color='success' onClick={() => handleEdit(post)}><i className='fa fa-edit'></i></Button>
                                        <Button className='btn-edit' color='danger' onClick={() => handleDelete(post)}><i className='fa fa-trash'></i></Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Fragment>
    )
}

KendaraanTableComp.propTypes = {
    listData : PropTypes.array.isRequired,   
    pageOffset : PropTypes.number.isRequired,
    className : PropTypes.string,
    handleEdit : PropTypes.func.isRequired,
    handleDelete : PropTypes.func.isRequired,
}
export default KendaraanTableComp;
