import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Col, Row, Table } from 'reactstrap';
import config from '../../../config';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import { useGlobalFuntction } from '../../../generalFunction/globalFunction';
import api from '../../../services/api';

import '../../../style/kwitansiStyle.scss';

const KwitansiPrint = (props) => {    
    const { isLoading, setIsLoading, } = useGlobalFuntction()
    const { kwitansiId } = useParams();
    const [info, setInfo] = useState(null);

    const fetchData = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.uangMukaKontrak}${kwitansiId}/`).then(res => res.data);
            if (res){                
                setInfo(res.results);
            }
            setIsLoading(false);
        }catch(error){            
            setIsLoading(false);
        }
        // eslint-disable-next-line
    },[setIsLoading])

    useEffect(() => {
        fetchData();
    },[fetchData])

    return(
        <Fragment>
            { isLoading && <LoaderComponent/>}
            <div className='laporan-potrait'>
                <div className="kwitansi">
                    <div className="kwitansi-title">
                        KWITANSI
                    </div>
                    <div className="kwitansi-detail">
                        <Row>
                            <Col xs={4}>Sudah Terima Dari</Col>
                            <Col xs={8}>: { info && info.kontrak_display && info.kontrak_display.rekanan_display}</Col>
                        </Row>
                        <Row>
                            <Col xs={4}>Untuk</Col>
                            <Col xs={8}>: { info && info.penerima}</Col>
                        </Row>
                        <Row>
                            <Col xs={4}>Uang Sejumlah</Col>
                            <Col xs={8}>: { info && info.terbilang}</Col>
                        </Row>
                        <Row>
                            <Col xs={4}>Untuk Pembayaran</Col>
                            <Col xs={8}>: { info && info.keterangan}</Col>
                        </Row>
                    </div>
                    <div className="kwitansi-table">
                        <Table className='table-small small table-bordered table-center'>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Uraian</th>
                                    <th>Unit</th>
                                    <th>Hari</th>
                                    <th>Price (Rp)</th>
                                    <th>Amount (Rp)</th>
                                </tr>
                            </thead>
                            <tbody>
                                { info && info.detail_json && info.detail_json.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className="text-right">{index+1}</td>
                                            <td>{post.uraian}</td>
                                            <td className="text-right">{post.unit}</td>
                                            <td className="text-right">{post.hari}</td>
                                            <td className="text-right">{post.price && parseInt(post.price).toLocaleString()}</td>
                                            <td className="text-right">{post.amount && parseInt(post.amount).toLocaleString()}</td>
                                        </tr>
                                    )
                                })}                                
                                <tr>
                                    <td colSpan={5} className='text-right'>Total yang dibayar</td>
                                    <td className='text-right'>{info && info.nominal && info.nominal.toLocaleString()}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="kwitansi-transfer">
                        Pembayaran mohon di transfer ke Rekening Bank { info && info.bank_display && info.bank_display.nama}
                    </div>
                    <div className="kwitansi-ac-name">
                        No. { info && info.bank_display && info.bank_display.ac_number} a/n. { info && info.bank_display && info.bank_display.ac_name}
                    </div>
                    <div className="kwitansi-footer">
                        <div className="kwitansi-total">
                            <div className="kwitansi-total-rp">Rp</div>
                            <div className="kwitansi-total-nominal">{info && info.nominal && info.nominal.toLocaleString()}</div>
                        </div>
                        <div className="kwitansi-ttd">
                            <div className="kwitansi-ttd-tanggal">
                                Bandar Lampung, {info && info.hari} {info && info.tanggal_komplit_display}
                            </div>
                            <div className="kwitansi-ttd-yangterima">Yang terima,</div>
                            <div className="kwitansi-ttd-nama">{ info && info.penerima}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default KwitansiPrint;