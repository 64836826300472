import React, { Fragment } from 'react';
import { Alert, Button, ButtonGroup, CardBody, Col, FormGroup, Label, Row, Table } from 'reactstrap';
import CardTemplate from '../../containers/Components/CardTemplate';
import FormTextSingleComp from '../../containers/Components/FormTextSingleComp';
import LoaderComponent from '../../containers/Components/LoaderComponent';
import PagePagination from '../../containers/Components/PagePagination';
import SearchComp from '../../containers/Components/SearchComp';
import { useStock } from './stockFunction';


const StockLokasiPage = (props) => {
    const hide_lokasi = props && props.hide_lokasi ? props.hide_lokasi : false;
    const lokasi_id = props && props.lokasi_id ? props.lokasi_id : null;
    const jenis_lokasi = props && props.jenis_lokasi ? props.jenis_lokasi : null;
    const { 
        alertValue, handleCloseAlert,  isLoading, listData,
        pageValue,  listLokasi, handleChangeSearch, handlePagination, handleDetail,
        handleKeyDownSearch, handleSearch, handlePrintSo
    } = useStock({lokasi_id : lokasi_id, jenis_lokasi : jenis_lokasi});
    return(
        <Fragment>
            { isLoading && <LoaderComponent/>}
            <Alert isOpen={alertValue.show} toggle={handleCloseAlert} color={alertValue.color}>
                {alertValue.text}
            </Alert>
            <CardTemplate headerTitle="Stock By Lokasi">
                <CardBody>                    
                    <Row>
                        { pageValue.perusahaanObj.length > 1 &&
                            <Col lg={12}>
                                <FormGroup>
                                    <Label>Perusahaan</Label>
                                    <FormTextSingleComp
                                        value={pageValue.perusahaan}
                                        show={true}
                                        tipe="select"
                                        name="perusahaan"
                                        onChange={handleChangeSearch}
                                        obj={pageValue.perusahaanObj}
                                    />
                                </FormGroup>
                            </Col>
                        }
                        { !hide_lokasi &&
                            <Col lg={4}>
                                <FormGroup>
                                    <Label>
                                        Filter Lokasi
                                    </Label>
                                    <FormTextSingleComp
                                        name='lokasi_id'
                                        value={pageValue.lokasi_id}
                                        tipe="select"
                                        obj={listLokasi}
                                        show={true}
                                        onChange={handleChangeSearch}
                                    />
                                </FormGroup>
                            </Col>
                        }
                        <Col lg={4}>
                            <FormGroup>
                                <Label>Cari Berdasarkan Keyword</Label>                                
                                <SearchComp
                                    value={pageValue.search}
                                    onChange={handleChangeSearch}
                                    onKeyDown={handleKeyDownSearch}
                                    onClick={handleSearch}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={4}>
                            <FormGroup>
                                <Label>.
                                </Label>
                                <Button color="success" block onClick={handlePrintSo}>Cetak Stock Opname</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div style={{marginBottom: 10}}/>
                    <Table className='table-bordered table-small table-sm-padding table-header-withbackground table-center' responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nama Produk</th>
                                <th>Kode</th>
                                <th>Kategori</th>
                                {/* <th>Open Balance</th> */}
                                <th>Masuk</th>
                                <th>Keluar</th>
                                <th>Saldo</th>
                                <th>HPP</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { listData.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}} className="text-right">{pageValue.offset+1+index}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}}>{post.nama}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}}>{post.kode}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}}>{post.kategori_display && post.kategori_display.nama}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}} className='text-right'>{post.qty_avg ? post.qty_avg.masuk : 0}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}} className='text-right'>{post.qty_avg ? post.qty_avg.keluar : 0}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}} className='text-right'>{post.qty_avg ? post.qty_avg.saldo : 0}</td>                                        
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}} className='text-right'>{post.harga_saldo_avg.toLocaleString()}</td>
                                        <td className="text-center">
                                            <ButtonGroup>
                                                <Button color="primary" size="sm" className='btn-edit'><i className='fa fa-history' onClick={() => handleDetail(post)}></i></Button>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <PagePagination
                        data={pageValue.obj}
                        page={pageValue.page}
                        lastPage={pageValue.lastPage}
                        handlePagination={handlePagination}
                    />
                </CardBody>
            </CardTemplate>
        </Fragment>
    )
}
export default StockLokasiPage;