import { useReducer } from "react"


export function useContextLogBiayaKendaraan(){
    const [logBiayaKendaraanStore, logBiayaKendaraanDispatch] = useReducer(
        (prevState, action) => {
            switch (action.type){
                case 'FETCH' :
                    return {
                        ...prevState, 
                        data : action.data,
                        offset : action.offset,
                        lastPage : action.lastPage,
                        page : action.page,
                        pageObj : action.pageObj,
                        search : action.search,
                        ready : true
                    }                
                case 'SEARCH' : 
                    return {
                        ...prevState,
                        search : action.search,
                    }
                default :
                    return {
                        ...prevState,
                        data : null,
                        offset : 0,
                        lastPage : 1,
                        page : 1,
                        pageObj: [],
                        search : '',
                        ready : false
                    }
            }
        },
        {
            data : null,
            offset: 0,
            lastpage: 1,
            page : 1,
            pageObj: [],
            search: '',
            ready : false
        }
    )

    return {
        logBiayaKendaraanStore, logBiayaKendaraanDispatch
    }
}