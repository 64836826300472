import React, { Fragment } from "react"
import PropTypes from 'prop-types';
import { FormGroup, Label, Row, Col } from "reactstrap";
import FormTextSingleComp from "../../../containers/Components/FormTextSingleComp";

const PegawaiForm = (props) => {
    const { formData, handleChange, handleBlur, formName, handleChangeDate, children,
        handleChangeSwitch
    } = props;
    return(
        <Fragment>
            <Row>
                { Object.entries(formData).map(([index, post]) => {
                    if (post.show ){
                        return(
                            <Fragment key={index}>
                                <Col lg={post.col}>
                                    { post.tipe === 'switch' ?
                                        <FormGroup row>
                                            <Label className='col-lg-4'>{post.label}</Label>
                                            <Col lg={4}>
                                                <FormTextSingleComp
                                                    {...post}
                                                    onChange={(e) => handleChangeSwitch(e, formName, post.name)}                                                    
                                                />    
                                            </Col>
                                        </FormGroup>
                                        :    
                                        <FormGroup>
                                            <Label>{post.label}</Label>
                                            {post.helpText &&
                                                <span style={{fontSize:12, fontStyle: 'italic'}}>{post.helpText}</span>
                                            }
                                            <FormTextSingleComp
                                                {...post}
                                                onChange={(e) => handleChange(e, formName)}
                                                onBlur={(e) => handleBlur(e, formName)}
                                                onChangeDate={handleChangeDate}
                                            />
                                        </FormGroup>
                                    }
                                </Col>
    
                            </Fragment>
                        )
                    }
                    return true;
                }) }
                {children}
            </Row>
        </Fragment>
    )    
}
PegawaiForm.propTypes = {
    formData : PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChangeDate: PropTypes.func,
    formName: PropTypes.string.isRequired,
    children: PropTypes.node
}
export default PegawaiForm