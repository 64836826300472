import React from 'react';
import PropTypes from 'prop-types';
import { Input, Table } from 'reactstrap';
import classNames from 'classnames';

const DataTableComp = (props) => {
    const { columns, className, theadClass, countNum, sortAsc, checkListData,
        data, pageOffset, handleSort, sortBy, actionGroup, MyButtonGroup,
        buttonGroupClassName
    } = props;

    const columnLength = () => {
        if (countNum && actionGroup) {
            return columns.length + 2
        } else if (countNum && !actionGroup) {
            return columns.length + 1
        } else {
            return columns.length
        }
    }

    return (
        <div className="table-responsive">
            <Table className={classNames('table-bordered table-small table-sm-padding table-header-withbackground table-center text-sm', className)} responsive>
                <thead className={theadClass ? theadClass : ''}>
                    <tr>
                        {checkListData &&
                            <th>
                                <Input
                                    type="check"
                                />
                            </th>
                        }
                        {countNum &&
                            <th>#</th>
                        }
                        {columns.map((post, index) => {
                            return (
                                <th
                                    key={index}
                                    className={classNames(post.className ? post.className : '', {
                                        sorting_desc: sortAsc && sortBy === post.accessor ? true : false,
                                        sorting_asc: !sortAsc && sortBy === post.accessor ? true : false,
                                        sortable: post.sort === true,
                                    })}
                                    role="columnheader"
                                    onClick={() => handleSort ? handleSort(post.accessor) : null}
                                >
                                    {post.Header}
                                </th>
                            )
                        })}
                        {(actionGroup && MyButtonGroup) &&
                            <th>Action</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {data.length === 0 &&
                        <tr>
                            <td colSpan={columnLength()} className='text-center'>Belum ada data</td>
                        </tr>
                    }
                    {data && data.length > 0 && data.map((row, index) => {
                        return (
                            <tr key={index} role="row">
                                {checkListData &&
                                    <td>
                                        <Input
                                            type="check"
                                        />
                                    </td>
                                }
                                {countNum &&
                                    <td className='text-right'>{index + pageOffset + 1}</td>
                                }
                                {columns.map((cell, cellIndex) => {
                                    return (
                                        <td onClick={() => props.clickTr ? props.clickTr(row) : null} style={{ cursor: props.clickTr ? 'pointer' : 'inherit' }} key={cellIndex} role="cell" className={classNames(cell.withpre ? 'withpre' : '', typeof (row[cell.accessor]) === 'number' ? 'text-right' : '', cell.className)}>
                                            { cell.tipe && cell.tipe === 'image' ?
                                                <div className={cell.classNameImage ? cell.classNameImage : ''}>
                                                    {row[cell.accessor] ?
                                                        <img src={row[cell.accessor]} alt='foto' className={cell.classNameImage} />
                                                        :
                                                        ''
                                                    }
                                                </div>
                                                :
                                                <>
                                                    { cell.badge ?
                                                        <span className={row.status_color ? row.status_color : ''}>
                                                            {cell.Cell ? cell.Cell(row) : typeof (row[cell.accessor]) === 'number' ? row[cell.accessor].toLocaleString('DE') : row[cell.accessor]}
                                                        </span>
                                                        :
                                                        <>
                                                            {cell.Cell ? cell.Cell(row) : typeof (row[cell.accessor]) === 'number' ? row[cell.accessor].toLocaleString('DE') : row[cell.accessor]}
                                                        </>
                                                    }
                                                </>
                                            }
                                        </td>
                                    )
                                })}
                                { (actionGroup && MyButtonGroup) &&
                                    <td className={classNames("text-center", buttonGroupClassName)}>
                                        <MyButtonGroup
                                            data={row}
                                            index={index}
                                        />
                                    </td>
                                }
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
}
DataTableComp.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    className: PropTypes.string,
    theadClass: PropTypes.string,
    countNum: PropTypes.bool,
    sortAsc: PropTypes.bool,
    actionGroup: PropTypes.bool,
    checkListData: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    pageOffset: PropTypes.number.isRequired,
    handleSort: PropTypes.func,
    MyButtonGroup: PropTypes.func,
    sortBy: PropTypes.string,
    buttonGroupClassName: PropTypes.string,
}
export default DataTableComp;
