import React, { Fragment } from 'react';
import { Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import FormTextSingleComp from '../../../../containers/Components/FormTextSingleComp';
import LoaderComponent from '../../../../containers/Components/LoaderComponent';
import KategoriListComp from './KategoriListComp';
import { useKategoriPartFunction } from './kategoriPartFunction';

const KategoriPartPage = (props) => {
    const { 
        isLoading, pageValue, listData, view, handleEdit, handleDelete,
        handleSearch, handleChangeSearch, handleKeyDownSearch, handleAdd,
        formData, handleChange, handleBlur, handleSubmit, handleCancel,
        modalValue, handleCloseModal, handleSubmitDelete
    } = useKategoriPartFunction();

    return(
        <Fragment>
            { isLoading && <LoaderComponent/>}
            <Modal toggle={handleCloseModal} isOpen={modalValue.show} size="md">
                <ModalHeader toggle={handleCloseModal}>{modalValue.title}</ModalHeader>
                <ModalBody>
                    {modalValue.text}
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                        <Button size="sm" color="danger" onClick={handleSubmitDelete}><i className='fa fa-trash'></i> Hapus</Button>
                        <Button size="sm" color="secondary" onClick={handleCloseModal}><i className='fa fa-undo'></i> Cancel</Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
            <Card>
                <CardHeader>
                    { view === 'list' && 'Daftar Kategori Sparepart'}
                    { view === 'create' && 'Tambah Kategori Sparepart'}
                </CardHeader>
                <CardBody>
                    { view === 'list' &&
                        <KategoriListComp
                            listData={listData}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            pageValue={pageValue}
                            handleSearch={handleSearch}
                            handleChangeSearch={handleChangeSearch}
                            handleKeyDownSearch={handleKeyDownSearch}
                            handleAdd={handleAdd}
                            clickTr={props.clickTr}
                        />
                    }
                    { view === 'create' &&
                        <Row>
                            <Col lg={6} className='offset-lg-3'>
                                <FormGroup row>
                                    <Label className='col-lg-4'>Nama Kategori</Label>
                                    <Col lg={8}>
                                        <FormTextSingleComp
                                            {...formData.nama}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    }
                </CardBody>
                { view === 'create' &&
                    <CardFooter>
                        <div className="float-right">
                            <ButtonGroup>
                                <Button size="sm" color="primary" onClick={handleSubmit}>Simpan</Button>
                                <Button size="sm" color="secondary" onClick={handleCancel}>Cancel</Button>
                            </ButtonGroup>
                        </div>
                    </CardFooter>
                }
            </Card>
        </Fragment>
    )
}
export default KategoriPartPage;