import React, { Fragment } from 'react';
import { Alert, Button, ButtonGroup, CardBody, Modal, Pagination, PaginationLink, Table, Card, Row, Col, FormGroup, Label, ModalFooter,  } from 'reactstrap';
import CardHeader from 'reactstrap/lib/CardHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import FormTextSingleComp from '../../../containers/Components/FormTextSingleComp';
// import FormTextSingleComp from '../../../containers/Components/FormTextSingleComp';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import SearchComp from '../../../containers/Components/SearchComp';
import { useAccount } from './accountFunction';

const AccountPage = (props) => {
    const tipe_query = props && props.tipe_query ? props.tipe_query : null;
    const nature = props.nature || null;
    const { 
        modalValue, isLoading, alertValue, listData, handleCloseAlert, pageValue, handleCloseModal,
        handlePagination, handleChangeSearch, handleKeyDownSearch, openBalance, handleAdd, formData,
        handleChange, handleBlur, handleSubmit, handleEdit, getPermission, handleSearch, handleDelete,
        handleSubmitDelete
    } = useAccount({tipe: 'list', tipe_query: tipe_query, nature : nature});

    const disabledAddButton = props.disabledAddButton ? props.disabledAddButton : false; 
    const disabledEditButton = props.disabledEditButton ? props.disabledEditButton : false; 
    const disabledDeleteButton = props.disabledDeleteButton ? props.disabledDeleteButton : false; 

    return(
        <Fragment>
            { isLoading && <LoaderComponent/>}
            <Alert isOpen={alertValue.show} toggle={handleCloseAlert} color={alertValue.color}>
                {alertValue.text}
            </Alert>
            <Modal isOpen={modalValue.show} toggle={handleCloseModal} size={['add', 'edit'].includes(modalValue.tipe) ? 'xl' : 'md'}>
                <ModalHeader toggle={handleCloseModal}>{modalValue.title}</ModalHeader>
                <ModalBody>
                    { ['add', 'edit'].includes(modalValue.tipe) &&
                        <Row>
                            <Col lg={6}>
                                { Object.entries(Object.assign(...Object.keys(formData).slice(0, 5).map(k => ({ [k]: formData[k] })))).map(([index, post]) => {
                                    if (post.show){
                                        return(
                                            <FormGroup key={index} row>
                                                <Label className='col-lg-4'>{post.label}</Label>
                                                <Col lg={8}>
                                                    <FormTextSingleComp
                                                        {...post}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        )
                                    }
                                    return true;
                                })}
                            </Col>
                            <Col lg={6}>
                                { Object.entries(Object.assign(...Object.keys(formData).slice(5, 15).map(k => ({ [k]: formData[k] })))).map(([index, post]) => {
                                    return(
                                        <FormGroup key={index} row>
                                            <Label className='col-lg-4'>{post.label}</Label>
                                            <Col lg={8}>
                                                <FormTextSingleComp
                                                    {...post}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Col>
                                        </FormGroup>
                                    )
                                })}
                            </Col>
                        </Row>
                    }
                    { modalValue.tipe === 'delete' &&
                        <div>
                            {modalValue.text}
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                        { ['add', 'edit'].includes(modalValue.tipe) &&
                            <Button size="sm" color="primary" onClick={handleSubmit}>Simpan</Button>
                        }
                        { modalValue.tipe === 'delete' &&
                            <Button size="sm" color="danger" onClick={handleSubmitDelete}>Hapus</Button>
                        }
                        <Button size="sm" color="secondary" onClick={handleCloseModal}>cancel</Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
            
            <Card>
                <CardHeader>
                    <Row>
                        <Col lg={6}>
                            {props.title ? props.title : 'Account'}
                        </Col>
                        { props.handleClose &&
                            <Col lg={6}>
                                <div className="float-right">
                                    <i className='fa fa-times' onClick={props.handleClose} style={{cursor: 'pointer'}}></i>
                                </div>
                            </Col>                        
                        }
                    </Row>
                </CardHeader>
                <CardBody>
                    {/* <Row>
                        <Col lg={12}>
                            <FormGroup>
                                <FormTextSingleComp
                                    name="perusahaan"
                                    value={pageValue.perusahaan}
                                    onChange={handleChangeSearch}
                                    show={true}
                                    obj={pageValue.perusahaanObj}
                                />
                            </FormGroup>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col lg={8}>
                            <SearchComp
                                onChange={handleChangeSearch}
                                value={pageValue.search}
                                onKeyDown={handleKeyDownSearch}
                                onClick={handleSearch}
                            />
                        </Col>                        
                        { ( getPermission('add_account') && !disabledAddButton) &&
                            <Col lg={4}>
                                <Button color="success" onClick={handleAdd}><i className='fa fa-plus'></i></Button>
                            </Col>
                        }
                    </Row>
                    <Table className='table-bordered table-small table-sm-padding table-header-withbackground table-center' responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>COA</th>
                                <th>Nama Akun</th>
                                <th>Kategori Induk</th>
                                <th>Nature</th>
                                <th>Akun D/K</th>
                                <th>Akun N/LR</th>
                                <th>Subkategori</th>
                                { openBalance && 
                                    <th>Open Balance</th>
                                }
                                { !disabledEditButton && !disabledDeleteButton &&
                                    <th>Action</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            { listData.map((post, index) => {
                                return(
                                    <tr key={index} className={['yes', 'Yes'].includes(post.subkat) ? 'tr-bold' : 'tr-account-child'}>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}} className="text-right">{pageValue.offset+1+index}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}} className='td-account-child'>{post.noakun}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}} className='td-account-child'>{post.nmakun}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}}>{post.under_display}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}}>{post.nature_display}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}} className='text-center'>{post.akundb}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}} className='text-center'>{post.akunnr}</td>
                                        <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}} className='text-center'>{post.subkat}</td>
                                        { openBalance &&
                                            <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}} className='text-right'>{post.open_balance.toLocaleString()}</td>
                                        }
                                        { !disabledEditButton && !disabledDeleteButton &&
                                            <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor : props.clickTr ? 'pointer' : 'inherit'}} className="text-center">
                                                <ButtonGroup>
                                                    { (getPermission('change_account') && !disabledEditButton) &&
                                                        <Button className='btn-edit' color="primary" onClick={() => handleEdit(post)}><i className='fa fa-edit'></i></Button>
                                                    }
                                                    { (getPermission('delete_account') && !disabledDeleteButton) &&
                                                        <Button className='btn-edit' color="danger" onClick={() => handleDelete(post)}><i className='fa fa-trash'></i></Button>
                                                    }
                                                </ButtonGroup>
                                            </td>
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    { pageValue.obj.length > 1 &&                
                        <Pagination>
                            { pageValue.obj.map((post, index) => {
                                return(
                                    <PaginationItem key={index} className={pageValue.page === post ? 'active' : ''}>
                                        <PaginationLink tag="button" onClick={() => handlePagination(post)}>{post}</PaginationLink>
                                    </PaginationItem>
                                )
                            })}
                        </Pagination>
                    }
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default AccountPage;