import React, { Fragment } from 'react';
import PropTypes from 'prop-types'
import { Button, Col, Row } from 'reactstrap';
import SearchComp from '../../../containers/Components/SearchComp';
import DataTableComp from '../../../containers/Components/DataTableComp';
import PagePagination from '../../../containers/Components/PagePagination';



const KontrakTableComp = (props) => {
    const { 
        handleChangeSearch, 
        handleSearch, 
        handleKeyDownSearch, 
        pageValue,
        handleAdd,
        columnHeader,
        listData,
        hideButton,
        handleCreateJadwal,
        handleShowCetakDO,
        handleClosing,
        handleEdit,
        clickTr,
        handlePagination,
        handleDetail
    } = props;


    const myButtonGroup = (props) => {
        return(            
            <div className='text-center' style={{textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                { props.data.state === 1 &&
                    <div style={{width: 80, marginBottom: 5}}>
                        <Button block className='btn-edit' color='success' onClick={() => handleCreateJadwal(props.data)}><i className='fa fa-check'></i> Jadwal</Button>
                    </div>
                }
                <div style={{width: 80, marginBottom: 5}}>
                    <Button block className='btn-edit' color='primary' onClick={() => handleShowCetakDO(props.data)}><i className='fa fa-print'></i> Cetak DO</Button>
                </div>
                {/* { props.data.state === 1 && */}
                    <div style={{width: 80, marginBottom: 5}}>
                        <Button block className='btn-edit' color='danger' onClick={() => handleClosing(props.data)}><i className='fa fa-power-off'></i> Closing</Button>
                    </div>
                {/* } */}
                { props.data.state === 1 &&
                    <div style={{width: 80, marginBottom: 5}}>
                        <Button block className='btn-edit' color='warning' onClick={() => handleEdit(props.data)}><i className='fa fa-edit'></i> Edit</Button>
                    </div>
                }
                <div style={{width: 80, marginBottom: 5}}>
                    <Button block className='btn-edit' color='dark' onClick={() => handleDetail(props.data)}><i className='fa fa-edit'></i> Detail</Button>
                </div>
            </div>
        )
    }

    return(
        <Fragment>            
            <Row>
                <Col lg={8}>
                    <SearchComp
                        onChange={handleChangeSearch}
                        onClick={handleSearch}
                        onKeyDown={handleKeyDownSearch}
                        value={pageValue.search}
                    />
                </Col>
                {!hideButton &&
                    <Col lg={4}>
                        <Button color='primary' onClick={handleAdd}><i className='fa fa-plus'></i></Button>
                    </Col>
                }
            </Row>
            <DataTableComp
                columns={columnHeader}
                data={listData}
                pageOffset={pageValue.offset}
                countNum={true}
                actionGroup={!hideButton ? true : false}
                MyButtonGroup={myButtonGroup}
                clickTr={clickTr}
            />
            <PagePagination
                data={pageValue.obj}
                lastPage={pageValue.lastPage}
                page={pageValue.page}
                handlePagination={handlePagination}
            />
        </Fragment>
    )
}
KontrakTableComp.propTypes = {
    handleChangeSearch : PropTypes.func.isRequired,
    handleSearch : PropTypes.func.isRequired,
    handleKeyDownSearch : PropTypes.func.isRequired,
    pageValue : PropTypes.object.isRequired,
    handleAdd : PropTypes.func.isRequired,
    columnHeader :  PropTypes.array.isRequired,
    listData: PropTypes.array.isRequired,
    hideButton : PropTypes.bool.isRequired,
    handleCreateJadwal : PropTypes.func.isRequired,
    handleShowCetakDO : PropTypes.func.isRequired,
    handleClosing : PropTypes.func.isRequired,
    handleEdit : PropTypes.func.isRequired,
    clickTr : PropTypes.func,
    handlePagination : PropTypes.func.isRequired,
    handleDetail : PropTypes.func.isRequired
}

export default KontrakTableComp;