import React from 'react';
import { Row, Col, Card, CardHeader } from 'reactstrap';

const CardTemplate = (props) => {
    return(
        <div className={props.cardClassName ? `animated fadeIn ${props.cardClassName}` : "animated fadeIn"}>
			<Row>
				<Col xs={props.xs} lg={props.lg} className={props.colClassName}>	
                    <Card>
                        <CardHeader>
                            <i className="fa fa-align-justify"></i> {props.headerTitle}
                            { props.handleClose &&
                                <div className="float-right">
                                    <i className='fa fa-times' onClick={props.handleClose}></i>
                                </div>
                            }                            
                        </CardHeader>
                        {props.children}
                    </Card>                    
                </Col>
            </Row>
        </div>
    )
}
export default CardTemplate