import React, { Fragment } from 'react';
import { Input, InputGroup, InputGroupAddon, Button, } from 'reactstrap';
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppSwitch } from '@coreui/react';

const FormTextSingleComp = (props) => {
    return (
        <Fragment>
            { props.tipe === 'date' &&
                <DatePicker
                    style={{display:'block !important'}}
                    name={props.name}
                    className="form-control"
                    selected={props.value}
                    dateFormat={props.dateFormat ? props.dateFormat : "dd-MM-yyyy"}
                    onChange={(e) => props.onChangeDate(e, props.name)}
                    showTimeSelect={props.showTimeSelect ? props.showTimeSelect : false}
                    showTimeSelectOnly={props.showTimeSelectOnly ? props.showTimeSelectOnly : false}
                    readOnly={props.readOnly}
                    timeFormat="HH:mm"
                    onBlur={props.onBlur}
                />
            }
            {/* { (props.tipe === 'text' || props.tipe === 'textarea') && */}
            {['text', 'textarea', 'password'].includes(props.tipe) &&
                <Input
                    id={props.name}
                    placeholder={props.placeHolder}
                    value={props.value}
                    readOnly={props.readOnly}
                    onChange={props.onChange}
                    name={props.name}
                    required={props.required}
                    onBlur={props.onBlur}
                    type={props.show ? props.tipe : 'hidden'}
                    onKeyDown={props.onKeyDown ? props.onKeyDown : null}
                    {...props.child}
                />
            }

            { (props.tipe === 'select' && props.show) &&
                <Input
                    type={props.tipe}
                    id={props.name}
                    placeholder={props.placeHolder}
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                    onBlur={props.onBlur}
                    readOnly={props.readOnly ? props.readOnly : false}
                >
                    {props.obj && props.obj.map((opt, mykey) => {
                        return (
                            <option value={opt.value} key={mykey} disabled={props.readOnly ? props.readOnly : false}>{opt.label}</option>
                        )
                    })}
                </Input>
            }
            { (props.tipe === 'select_group' && props.show) &&
                <Input
                    type="select"
                    id={props.name}
                    placeholder={props.placeHolder}
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                    onBlur={props.onBlur}
                >
                    <option value="">-- Pilih --</option>
                    {props.obj && props.obj.map((optgroup, groupkey) => {
                        return (
                            <optgroup key={groupkey} label={optgroup.label}>
                                {optgroup.child && optgroup.child.map((child, index) => {
                                    return (
                                        <option key={index} value={child.value}>{`${child.label}`}</option>
                                    )
                                })}
                            </optgroup>
                        )
                    })}
                </Input>
            }
            { ['input_button_number', 'input_button'].includes(props.tipe) &&
                <InputGroup>
                    { props.tipe === 'input_button' &&
                        <Input
                            type="text"
                            id={props.name}
                            name={props.name}
                            placeholder={props.placeHolder}
                            readOnly={props.readOnly}
                            value={props.value}
                            onChange={props.onChange}
                            onKeyDown={props.onKeyDown ? props.onKeyDown : null}
                        />
                    }
                    { props.tipe === 'input_button_number' &&
                        <NumberFormat
                            thousandSeparator={props.disableSaparator ? false : true}
                            prefix={props.prefix ? 'Rp.' : ''}
                            inputMode="numeric"
                            placeholder={props.placeHolder}
                            className={props.className ? `${props.className} form-control` : "form-control"}
                            name={props.name}
                            value={props.value}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            readOnly={props.readOnly}
                            id={props.id}
                            onKeyDown={props.onKeyDown}
                        />
                    }
                    <InputGroupAddon addonType="append">
                        <Button type="button" color="primary" onClick={props.onClick} disabled={props.disabled ? props.disabled : false}><i className='fa fa-search'></i></Button>
                    </InputGroupAddon>
                </InputGroup>
            }
            { props.tipe === 'number' &&
                <NumberFormat
                    thousandSeparator={props.disableSaparator ? false : true}
                    prefix={props.prefix ? 'Rp.' : ''}
                    inputMode="numeric"
                    placeholder={props.placeHolder}
                    className={props.className ? `${props.className} form-control` : "form-control"}
                    name={props.name}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    readOnly={props.readOnly}
                    id={props.id}
                    onKeyDown={props.onKeyDown}
                />
            }
            { props.tipe === 'switch' &&                
                <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'}  onChange={(e) => props.onChange(e, props.name)} name={props.name} checked={props.value}/>
            }
            {props.showError &&
                (<div className="error-message" style={{ fontStyle: 'italic', fontSize: 12, color: 'red' }}>{props.errorMsg ? props.errorMsg : 'Wajib diisi'}</div>)
            }
        </Fragment>
    )
}

export default FormTextSingleComp;