import React, { Fragment } from 'react';
import { Button, ButtonGroup, Col, FormGroup, Row, Table } from 'reactstrap';
import FormTextSingleComp from '../../../containers/Components/FormTextSingleComp';
import Moment from 'moment';

const JadwalComp  = (props) => {
    const { 
        info, listJadwal, handleAddKendaraanJadwal, 
        handleChangeDateJadwal, handleShowKendaraan,
        handleShowSupir, handleDeleteJadwal, handleChangeJadwal,
        tipeView, handlePrintDo
    } = props;

    return(
        <Fragment>
            <Row>
                <Col lg={6}>
                    <FormGroup row className='without-margin-bottom'>
                        <Col lg={4} xs={4} sm={4}>
                            Jenis Transaksi
                        </Col>
                        <Col lg={6} xs={6} sm={6}>
                            : { info && info.jenis_transaksi_display }
                        </Col>
                    </FormGroup>
                    <FormGroup row className='without-margin-bottom'>
                        <Col lg={4} xs={4} sm={4}>
                            Nomor Kontrak
                        </Col>
                        <Col lg={6} xs={6} sm={6}>
                            : { info && info.nomor }
                        </Col>
                    </FormGroup>
                    <FormGroup row className='without-margin-bottom'>
                        <Col lg={4} xs={4} sm={4}>
                            Rekanan
                        </Col>
                        <Col lg={6} xs={6} sm={6}>
                            : { info && info.rekanan_display }
                        </Col>
                    </FormGroup>
                    <FormGroup row className='without-margin-bottom'>
                        <Col lg={4} xs={4} sm={4}>
                            Pembeli
                        </Col>
                        <Col lg={6} xs={6} sm={6}>
                            : { info && info.pembeli_display }
                        </Col>
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup row className='without-margin-bottom'>
                        <Col lg={4} xs={4} sm={4}>
                            Alamat Pengambilan
                        </Col>
                        <Col lg={6} xs={6} sm={6}>
                            : { info && info.alamat_pengambilan }
                        </Col>
                    </FormGroup>
                    <FormGroup row className='without-margin-bottom'>
                        <Col lg={4} xs={4} sm={4}>
                            Alamat Pengantaran
                        </Col>
                        <Col lg={6} xs={6} sm={6}>
                            : { info && info.alamat_pengantaran }
                        </Col>
                    </FormGroup>
                    <FormGroup row className='without-margin-bottom'>
                        <Col lg={4} xs={4} sm={4}>
                            Nilai Kontrak
                        </Col>
                        <Col lg={6} xs={6} sm={6}>
                            : Rp.{ info && info.nilai_kontrak.toLocaleString() }
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <div style={{marginTop: 10}}/>
            { ['jadwal', 'closing'].includes(tipeView) &&
                <Row>
                    <Col lg={12}>
                        <Button size="sm" block color="primary" onClick={handleAddKendaraanJadwal}>Tambah Jadwal</Button>
                    </Col>
                </Row>
            }
            <div style={{marginTop: 10}}/>
            <Row>
                <Col lg={12}>
                    <div className="table-responsive">
                        <Table className='table-bordered table-small table-sm-padding table-header-withbackground table-center' responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Tanggal</th>
                                    <th>Kendaraan</th>
                                    <th>Supir</th>
                                    {/* <th>Uang Makan</th>
                                    <th>BBM</th> */}
                                    { info && info.jenis_transaksi === 1 &&
                                        <Fragment>
                                            <th>Jumlah RIT</th>
                                            <th>Jumlah Muatan / RIT</th>
                                        </Fragment>
                                    }
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { listJadwal.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className='text-right'>{index+1}</td>
                                            <td>
                                                { ['jadwal', 'closing'].includes(tipeView) ?
                                                    <FormTextSingleComp
                                                        {...post.tanggal}
                                                        onChangeDate={(e) => handleChangeDateJadwal(e, 'tanggal', index)}
                                                    />
                                                    :
                                                    <>
                                                        {Moment(post.tanggal).format('DD-MM-YYYY')}
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                { ['jadwal', 'closing'].includes(tipeView) ?
                                                    <FormTextSingleComp
                                                        {...post.kendaraan_display}
                                                        onClick={() => handleShowKendaraan(index)}
                                                        onChange={handleChangeDateJadwal}
                                                    />
                                                    :
                                                    <>
                                                        {post.kendaraan_display.value}
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                { ['jadwal', 'closing'].includes(tipeView) ?
                                                    <FormTextSingleComp
                                                        {...post.supir_display}
                                                        onClick={() => handleShowSupir(index)}
                                                        onChange={handleChangeDateJadwal}
                                                    />
                                                    : 
                                                    <>
                                                        {post.supir_display.value.toLocaleString()}
                                                    </>
                                                }
                                            </td>
                                            {/* <td className='text-right'>
                                                { ['jadwal', 'closing'].includes(tipeView) ?
                                                    <FormTextSingleComp
                                                        {...post.uang_makan}
                                                        onChange={(e) => handleChangeJadwal(e, index)}
                                                    />
                                                    :
                                                    <>
                                                        {post.uang_makan.value.toLocaleString()}
                                                    </>
                                                }
                                            </td>
                                            <td className='text-right'>
                                                { ['jadwal', 'closing'].includes(tipeView) ?
                                                    <FormTextSingleComp
                                                        {...post.bbm}
                                                        onChange={(e) => handleChangeJadwal(e, index)}
                                                    />
                                                    :
                                                    <>
                                                        {post.bbm.value.toLocaleString()}
                                                    </>
                                                }
                                            </td> */}
                                            { info && info.jenis_transaksi === 1 &&
                                                <Fragment>
                                                    <td className='text-right'>
                                                        { ['jadwal', 'closing'].includes(tipeView) ?
                                                            <FormTextSingleComp
                                                                {...post.jumlah_rit}
                                                                onChange={(e) => handleChangeJadwal(e, index)}
                                                            />
                                                            :
                                                            <>
                                                                {post.jumlah_rit.value.toLocaleString()}
                                                            </>
                                                        }
                                                    </td>
                                                    <td className='text-right'>
                                                        { ['jadwal', 'closing'].includes(tipeView) ?
                                                            <FormTextSingleComp
                                                                {...post.jumlah_muatan}
                                                                onChange={(e) => handleChangeJadwal(e, index)}
                                                            />
                                                            :
                                                            <>
                                                                {post.jumlah_muatan.value.toLocaleString()}
                                                            </>
                                                        }
                                                    </td>
                                                </Fragment>
                                            }
                                            <td className="text-center">
                                                <ButtonGroup>
                                                { (['jadwal', 'closing'].includes(tipeView) && post.can_delete.value) &&
                                                    <Button className='btn-edit' color='danger' onClick={() => handleDeleteJadwal(index)}><i className='fa fa-times'></i></Button>
                                                }
                                                { tipeView === 'cetak_do' &&
                                                    <Button className='btn-edit' color='success' onClick={() => handlePrintDo(post)}><i className='fa fa-print'></i></Button>                                                
                                                }
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    )
                                }) }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}
export default JadwalComp