import React, { Fragment } from 'react';
import { 
    Alert, 
    Button, 
    ButtonGroup, 
    Card, 
    CardBody, 
    CardHeader, 
    Col, 
    FormGroup, 
    Label, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader, 
    Row, 
    Table,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardFooter,
    Input,
} from 'reactstrap';
import FormTextSingleComp from '../../../containers/Components/FormTextSingleComp';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import PagePagination from '../../../containers/Components/PagePagination';
import SearchComp from '../../../containers/Components/SearchComp';
import PegawaiForm from './PegawaiForm';
import { usePegawai } from './pegawaiFunction';

const PegawaiPage = (props) => {
    const hiddenEditButton = props && props.hiddenEditButton ? props.hiddenEditButton : false;
    const tipe = props && props.tipe ? props.tipe : "pegawai";
    const { isLoading, alertValue, handleCloseAlert, listData, 
        pageValue, handlePagination, 
        handleAdd, handleSearch, handleChangeSearch, handleKeyDownSearch,
        modalValue, handleCloseModal, formData, handleChange, handleBlur,
        handleSubmit, handleSubmitDelete, handleEdit, handleDelete, handleChangeDate,
        view, isEdit, handleChangeTab, activeTab, handleCancel, formPendidikan, listFormPendidikan, handleAddListForm,
        handleDeleteListForm, formPengalaman, listFormPengalaman, formKetrampilan, listKetrampilan, handleRedirectCv,
        handleCetakCv, handleChangeKandidatToPegawai, handleDetail, handleInsertFoto, handleChangeStatusAktif,
        handleChangeSwitch
    } = usePegawai({tipe: tipe});
    
    return(
        <Fragment>
            { isLoading && <LoaderComponent/>}
            <Modal isOpen={modalValue.show} toggle={handleCloseModal} size={modalValue.tipe === 'create' ? 'xl' : "lg"}>
                <ModalHeader toggle={handleCloseModal}>{modalValue.title}</ModalHeader>
                <ModalBody>
                    <Alert isOpen={alertValue.show} color={alertValue.color} toggle={handleCloseAlert}>
                        {alertValue.text}
                    </Alert>
                    { modalValue.tipe === 'create' &&
                        <Row>
                            <Col lg={6}>
                                { 
                                    Object.entries(Object.assign(...Object.keys(formData).slice(0, 9).map(k => ({ [k]: formData[k] })))).map(([index, post]) => {
                                    if (post.show){
                                        return(
                                            <FormGroup key={index} row>
                                                <Label className='col-lg-4'>{post.label} { post.required ? '*' : ''}</Label>
                                                <Col lg={8}>
                                                    <FormTextSingleComp
                                                        {...post}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onChangeDate={handleChangeDate}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        )
                                    }
                                    return true;
                                }) }
                            </Col>
                            <Col lg={6}>
                                { 
                                    Object.entries(Object.assign(...Object.keys(formData).slice(9, 20).map(k => ({ [k]: formData[k] })))).map(([index, post]) => {
                                    if (post.show){
                                        return(
                                            <FormGroup key={index} row>
                                                <Label className='col-lg-4'>{post.label} { post.required ? '*' : ''}</Label>
                                                <Col lg={8}>
                                                    <FormTextSingleComp
                                                        {...post}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onChangeDate={handleChangeDate}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        )
                                    }
                                    return true;
                                }) }
                            </Col>
                        </Row>                        
                    }
                    { modalValue.tipe === 'delete' &&
                        <Fragment>{`Apakah anda yakin akan menghapus pegawai ${formData.nama.value} ?`}</Fragment>
                    }
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                        <Button size="sm" color={modalValue.tipe === 'create' ? 'primary' : 'danger'} onClick={() => modalValue.tipe === 'create' ? handleSubmit() : handleSubmitDelete()}>{modalValue.tipe === 'create' ? 'Simpan' : 'Hapus'}</Button>
                        <Button size="sm" color="secondary" onClick={handleCloseModal}>Cancel</Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
            <Card>
                <CardHeader>
                    <Row>
                        <Col lg={6}>
                            { view === 'createFromKandidat' ? 'Ubah Kandidat ke Pegawai' :  'Master Pegawai' }
                        </Col>
                        { view === 'list' && !hiddenEditButton &&
                            <Col lg={6}>
                                <div className="float-right">
                                    <Button color="primary" size="sm" onClick={handleAdd}><i className='fa fa-plus'></i>{ tipe === 'pegawai' ?  "Tambah Pegawai" : "Tambah Kandidat"}</Button>
                                </div>
                            </Col>
                        }
                    </Row>
                </CardHeader>
                <CardBody>
                    <Alert isOpen={alertValue.show} color={alertValue.color} toggle={handleCloseAlert}>
                        {alertValue.text}
                    </Alert>
                    { ['createFromKandidat', 'create'].includes(view) &&
                        <Fragment>
                            { isEdit ?
                                <Fragment>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink onClick={() => handleChangeTab('pegawai')} active={activeTab === 'pegawai'}>
                                                Pegawai
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink onClick={() => handleChangeTab('pendidikan')} active={activeTab === 'pendidikan'}>
                                                Pendidikan
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink onClick={() => handleChangeTab('pengalaman')} active={activeTab === 'pengalaman'}>
                                                Pengalaman Kerja
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink onClick={() => handleChangeTab('ketrampilan')} active={activeTab === 'ketrampilan'}>
                                                Ketrampilan / Kemampuan
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="pegawai">
                                            <PegawaiForm
                                                formData={formData}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}   
                                                formName='formData'
                                                handleChangeDate={handleChangeDate}
                                                handleChangeSwitch={handleChangeSwitch}
                                            >
                                                <Col lg={6}>
                                                    <FormGroup>
                                                        <Label>{formData.foto_url.label} { formData.foto_url.required ? '*' : ''}</Label>
                                                        <div>
                                                            <Input
                                                                type='file'
                                                                id={`upload-button`}
                                                                onChange={handleInsertFoto}
                                                                // style={{display:'none'}}
                                                            />
                                                        </div>
                                                        { formData.foto_url.value &&
                                                            <div className="foto-container">
                                                                <img src={formData.foto_url.value} alt="foto"/>
                                                            </div>                 
                                                        }
                                                    </FormGroup> 
                                                </Col>
                                            </PegawaiForm>
                                        </TabPane>
                                        <TabPane tabId="pendidikan">                                            
                                            <PegawaiForm
                                                formData={formPendidikan}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}                            
                                                formName='formPendidikan'
                                            />
                                            <div className="float-right" style={{marginBottom: 10}}>
                                                <Button color='success' size="sm" onClick={() => handleAddListForm('formPendidikan')}><i className='fa fa-plus'></i> Tambah Pendidikan</Button>
                                            </div>
                                            <Table className="small">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Jenis Pendidikan</th>
                                                        <th>Pendidikan</th>
                                                        <th>Nama Gelar / Nama Pendidikan </th>
                                                        <th>Tahun Kelulusan</th>
                                                        <th>Jurusan</th>
                                                        <th>Lulusan</th>
                                                        <th>Nilai / IPK</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { listFormPendidikan.map((post, index) => {
                                                        return(
                                                            <tr key={index}>
                                                                <td>{index+1}</td>
                                                                <td>{post.textJenisPendidikan}</td>
                                                                <td>{post.textPendidikan}</td>
                                                                <td>{post.nama_gelar}</td>
                                                                <td>{post.tahun_lulus}</td>
                                                                <td>{post.jurusan}</td>
                                                                <td>{post.lulusan}</td>
                                                                <td>{post.nilai_ipk}</td>
                                                                <td>
                                                                    <Button className='btn-edit' color='danger' onClick={() => handleDeleteListForm(index, 'formPendidikan')}><i className='fa fa-trash'></i></Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </TabPane>
                                        <TabPane tabId="pengalaman">                                            
                                            <PegawaiForm
                                                formData={formPengalaman}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}                            
                                                formName='formPengalaman'
                                            />
                                            <div className="float-right" style={{marginBottom: 10}}>
                                                <Button color='success' size="sm" onClick={() => handleAddListForm('formPengalaman')}><i className='fa fa-plus'></i> Tambah Pendidikan</Button>
                                            </div>
                                            <Table className="small">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Perusahaan</th>
                                                        <th>Dari Tahun</th>
                                                        <th>Sampai Tahun</th>
                                                        <th>Posisi / Jabatan</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { listFormPengalaman.map((post, index) => {
                                                        return(
                                                            <tr key={index}>
                                                                <td>{index+1}</td>
                                                                <td>{post.perusahaan}</td>
                                                                <td>{post.dari_tahun}</td>
                                                                <td>{post.sd_tahun}</td>
                                                                <td>{post.posisi}</td>                                                                
                                                                <td>
                                                                    <Button className='btn-edit' color='danger' onClick={() => handleDeleteListForm(index, 'formPengalaman')}><i className='fa fa-trash'></i></Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </TabPane>
                                        <TabPane tabId="ketrampilan">      
                                            <Row>
                                                <Col lg={6} className="offset-lg-3">
                                                    <PegawaiForm
                                                        formData={formKetrampilan}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}                            
                                                        formName='formKetrampilan'
                                                    />
                                                </Col>
                                            </Row>                                      
                                            <div className="float-right" style={{marginBottom: 10}}>
                                                <Button color='success' size="sm" onClick={() => handleAddListForm('formKetrampilan')}><i className='fa fa-plus'></i> Tambah Pendidikan</Button>
                                            </div>
                                            <Table className="small">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Kemampuan / Ketrampilan</th>
                                                        <th>Tingkat Ketrampilan / Kemampuan</th>                                                        
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { listKetrampilan.map((post, index) => {
                                                        return(
                                                            <tr key={index}>
                                                                <td>{index+1}</td>
                                                                <td>{post.kemampuan}</td>
                                                                <td>{post.textTingkatKemampuan}</td>
                                                                <td>
                                                                    <Button className='btn-edit' color='danger' onClick={() => handleDeleteListForm(index, 'formKetrampilan')}><i className='fa fa-trash'></i></Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </TabPane>                                    
                                    </TabContent>
                                </Fragment>
                                :
                                <PegawaiForm
                                    formData={formData}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    formName="formData"
                                    handleChangeDate={handleChangeDate}
                                    handleChangeSwitch={handleChangeSwitch}
                                >
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label>{formData.foto_url.label} { formData.foto_url.required ? '*' : ''}</Label>
                                            <div>
                                                <Input
                                                    type='file'
                                                    id={`upload-button`}
                                                    onChange={handleInsertFoto}
                                                    // style={{display:'none'}}
                                                />
                                            </div>
                                            { formData.foto_url.value &&
                                                <div className="foto-container">
                                                    <img src={formData.foto_url.value} alt="foto"/>
                                                </div>                 
                                            }
                                        </FormGroup> 
                                    </Col>
                                </PegawaiForm>
                            }   
                        </Fragment>
                    }
                    { view === 'list' &&
                        <Fragment>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>Status Aktif</Label>
                                        <FormTextSingleComp
                                            value={pageValue.statusAktif}                                            
                                            onChange={handleChangeSearch}
                                            onKeyDown={handleKeyDownSearch}
                                            tipe="select"
                                            name="statusAktif"
                                            obj={pageValue.statusAktifObj}
                                            show={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>Cari</Label>
                                        <SearchComp
                                            value={pageValue.search}
                                            onClick={handleSearch}
                                            onChange={handleChangeSearch}
                                            onKeyDown={handleKeyDownSearch}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Table className='small table-bordered table-center table-sm-padding table-striped table-header-withbackground'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nama</th>
                                        { tipe === 'pegawai' &&
                                            <th>NIP</th>
                                        }
                                        <th>Email</th>
                                        <th>Tgl Lahir</th>
                                        { tipe === 'pegawai' &&
                                            <th>Department</th>
                                        }
                                        { tipe === 'pegawai' &&
                                            <th>Jabatan</th>
                                        }
                                        { tipe === 'pegawai' &&
                                            <th>Status</th>
                                        }
                                        { tipe === 'pegawai' &&
                                            <th>Mulai Bekerja</th>
                                        }
                                        { tipe === 'pegawai' &&
                                            <th>Status Aktif</th>
                                        }
                                        <th>HP</th>
                                        { !hiddenEditButton &&
                                            <th>Action</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    { listData.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}} className="text-right">{pageValue.offset+1+index}</td>
                                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.kontak_display.nama}</td>
                                                { tipe === 'pegawai' &&
                                                    <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.nip}</td>
                                                }
                                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.kontak_display.email}</td>
                                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.kontak_display.tanggal_lahir_display}</td>
                                                { tipe === 'pegawai' &&
                                                    <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.department_display}</td>
                                                }
                                                { tipe === 'pegawai' &&
                                                    <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.jabatan_display}</td>
                                                }
                                                { tipe === 'pegawai' &&
                                                    <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.status_pegawai_display}</td>
                                                }
                                                { tipe === 'pegawai' &&
                                                    <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.start_date_display}</td>
                                                }
                                                { tipe === 'pegawai' &&
                                                    <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.status_aktif_display}</td>
                                                }
                                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.kontak_display.hp}</td>
                                                { !hiddenEditButton &&
                                                    <td className="text-center col-2">
                                                        <div className='btn-group-pegawai'>
                                                            <div>
                                                                <Button block className='btn-edit' color='danger' onClick={() => handleDelete(post)}><i className='fa fa-trash'></i> Hapus</Button>
                                                            </div>
                                                            <div>
                                                                <Button block className='btn-edit' color='primary' onClick={() => handleEdit(post)}><i className='fa fa-edit'></i> Edit</Button>
                                                            </div>
                                                            <div>
                                                                <Button block className='btn-edit' color='warning' onClick={() => handleRedirectCv(post)}><i className='fa fa-print'></i> Cetak CV</Button>
                                                            </div>
                                                            <div>
                                                                <Button outline block className='btn-edit' color='primary' onClick={() => handleDetail(post)}><i className='fa fa-print'></i> Lihat Detail</Button>
                                                            </div>
                                                            { tipe === "pegawai" && 
                                                                <div>
                                                                    <Button outline block className='btn-edit' color='danger' onClick={() => handleChangeKandidatToPegawai(post)}><i className='fa fa-info'></i> Surat Peringatan</Button>
                                                                </div>
                                                            }
                                                            { tipe === "kandidat" &&
                                                                <div>
                                                                    <Button block className='btn-edit' color='success' onClick={() => handleChangeKandidatToPegawai(post)}><i className='fa fa-check'></i> Ubah Menjadi Pegawai</Button>
                                                                </div>
                                                            }
                                                            { tipe === "pegawai" &&
                                                                <div>
                                                                    <Button block className='btn-edit' color='danger' onClick={() => handleChangeStatusAktif(post)}><i className='fa fa-times'></i> Putus Hubungan Kerja</Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePagination}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </Fragment>
                    }
                </CardBody>
                { ['createFromKandidat', 'create'].includes(view) &&
                    <CardFooter>
                        <div className="float-right">
                            <ButtonGroup>
                                <Button color="primary" size="sm" onClick={handleSubmit}>Simpan</Button>
                                { isEdit &&
                                    <Button color="warning" size="sm" onClick={handleCetakCv}>Cetak CV</Button>
                                }
                                <Button color="secondary" size="sm" onClick={handleCancel}>Cancel</Button>
                            </ButtonGroup>
                        </div>
                    </CardFooter>
                }
            </Card>
        </Fragment>
    )
}
export default PegawaiPage;