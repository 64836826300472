import React, { Fragment } from 'react';
import { FormGroup,  Row, Col, Card, CardHeader, CardBody, Table,  } from 'reactstrap';
import { useKontrakView } from './kontrakViewFunction';

const KontrakViewPage = (props) => {
    const {  info } = useKontrakView({kontrakId: props.kontrakId, infoKontrak : props && props.infoKontrak ? props.infoKontrak : null});
    return(
        <Fragment>
            <Card>
                <CardHeader>
                    <Row>
                        <Col lg={6}>
                            Detail Kontrak
                        </Col>
                        <Col lg={6}>
                            <div className="float-right">
                                <i className='fa fa-times' style={{cursor: 'pointer'}} onClick={props.handleClose}></i>
                            </div>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg={6}>                    
                            <FormGroup row className='without-margin-bottom'>
                                <Col lg={4} xs={4} sm={4}>
                                    Jenis Transaksi
                                </Col>
                                <Col lg={6} xs={6} sm={6}>
                                    : { info && info.jenis_transaksi_display }
                                </Col>
                            </FormGroup>
                            <FormGroup row className='without-margin-bottom'>
                                <Col lg={4} xs={4} sm={4}>
                                    Nomor Kontrak
                                </Col>
                                <Col lg={6} xs={6} sm={6}>
                                    : { info && info.nomor }
                                </Col>
                            </FormGroup>
                            <FormGroup row className='without-margin-bottom'>
                                <Col lg={4} xs={4} sm={4}>
                                    Rekanan
                                </Col>
                                <Col lg={6} xs={6} sm={6}>
                                    : { info && info.rekanan_display }
                                </Col>
                            </FormGroup>
                            <FormGroup row className='without-margin-bottom'>
                                <Col lg={4} xs={4} sm={4}>
                                    Pembeli
                                </Col>
                                <Col lg={6} xs={6} sm={6}>
                                    : { info && info.pembeli_display }
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup row className='without-margin-bottom'>
                                <Col lg={4} xs={4} sm={4}>
                                    Alamat Pengambilan
                                </Col>
                                <Col lg={6} xs={6} sm={6}>
                                    : { info && info.alamat_pengambilan }
                                </Col>
                            </FormGroup>
                            <FormGroup row className='without-margin-bottom'>
                                <Col lg={4} xs={4} sm={4}>
                                    Alamat Pengantaran
                                </Col>
                                <Col lg={6} xs={6} sm={6}>
                                    : { info && info.alamat_pengantaran }
                                </Col>
                            </FormGroup>
                            <FormGroup row className='without-margin-bottom'>
                                <Col lg={4} xs={4} sm={4}>
                                    Nilai Kontrak
                                </Col>
                                <Col lg={6} xs={6} sm={6}>
                                    : Rp.{ info && info.nilai_kontrak.toLocaleString() }
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div style={{marginTop: 10}}/>
                    <Row>
                        <Col lg={12}>
                            <div className="table-responsive">
                                <Table className='table-bordered table-small table-sm-padding table-header-withbackground table-center' responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Tanggal</th>
                                            <th>Kendaraan</th>
                                            <th>Supir</th>                                            
                                            { info && info.jenis_transaksi === 1 &&
                                                <Fragment>
                                                    <th>Jumlah RIT</th>
                                                    <th>Jumlah Muatan / RIT</th>
                                                </Fragment>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { info && info.kontrak_jadwal_display && info.kontrak_jadwal_display.map((post, index) => {         
                                            return(
                                                <tr key={index}>
                                                    <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}} className='text-right'>{index+1}</td>
                                                    <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>                                                        
                                                        {post.tanggal}
                                                    </td>
                                                    <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>                                                        
                                                        {post.kendaraan_display}
                                                    </td>
                                                    <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>                                                        
                                                        {post.supir_display}
                                                    </td>                                                    
                                                    { info && info.jenis_transaksi === 1 &&
                                                        <Fragment>
                                                            <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}} className='text-right'>
                                                                {post.jumlah_rit.toLocaleString()}
                                                            </td>
                                                            <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}} className='text-right'>
                                                                {post.jumlah_muatan.toLocaleString()}
                                                            </td>
                                                        </Fragment>
                                                    }
                                                </tr>
                                            )
                                        }) }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default KontrakViewPage;