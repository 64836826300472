import React, { useState, useReducer } from 'react';
import AccountPage from '../views/Accounting/Account/AccountPage';
import PegawaiPage from '../views/HRD/Pegawai/PegawaiPage';
import KontakPage from '../views/Kontak/Kontak/KontakPage';
import StockLokasiPage from '../views/Lokasi/StockLokasiPage';
import SatuanPage from '../views/MasterData/Inventory/Satuan/SatuanPage';
import KendaraanPage from '../views/MasterData/Kendaraan/KendaraanPage';
import KategoriPartPage from '../views/MasterData/SparePart/Kategori/KategoriPartPage';
import SparePartPage from '../views/MasterData/SparePart/SparePart/SparePartPage';
import SupirPage from '../views/MasterData/Supir/SupirPage';
import TangkiPage from '../views/MasterData/Tangki/TangkiPage';
import KontrakPage from '../views/Transaksi/KontrakPage';
import KontrakViewPage from '../views/Transaksi/KontrakViewPage';
// import BankPage from '../views/Accounting/Bank/BankPage';
// import PegawaiPage from '../views/HRD/Pegawai/PegawaiPage';
// import KontakPage from '../views/Kontak/Kontak/KontakPage';
// import BidangPage from '../views/MasterData/Department/BidangPage';
// import DepartmentPage from '../views/MasterData/Department/DepartmentPage';
// import ProdukPage from '../views/MasterData/Inventory/Produk/ProdukPage';
// import KegiatanDetailPage from '../views/MasterData/Kegiatan/KegiatanDetailPage';



const TEST = 'TEST';
const TANGKI = 'TANGKI';
const CLOSE = 'CLOSE';
const KONTAK = 'KONTAK';
const ACCOUNT = 'ACCOUNT';
const SATUAN = 'SATUAN';
const KENDARAAN = 'KENDARAAN';
const SUPIR = 'SUPIR';
const KONTRAK = 'KONTRAK';
const DETAILKONTRAK = 'DETAILKONTRAK';
const PEGAWAI = 'PEGAWAI';
const KATEGORI_SPAREPART = 'KATEGORI_SPAREPART';
const SPAREPART = 'SPAREPART';
const STOCK = 'STOCK';

export function useGlobalModalFunction() {
    // ===================== custom modal ===================
    const [showConfirmCallToAction, setshowConfirmCallToAction] = useState(true);

    const modalReducer = (state, action) => {
        switch (action.type) {
            case TEST:
                return {
                    heading: `Daftar Pegawai`,
                    content: <></>,
                    confirmButtonText: '',
                    confirmButtonAction: null,
                    show: true,
                    size: 'xl',
                    withCard: false
                }
            case STOCK:
                return {
                    heading: `Sparepart`,
                    content: <StockLokasiPage
                        clickTr={action.onClickTr}
                        isModal={true}
                        handleClose={action.handleClose}
                        hide_lokasi={action.hide_lokasi}
                        lokasi_id={action.lokasi_id}
                    />,
                    confirmButtonText: '',
                    confirmButtonAction: null,
                    show: true,
                    size: 'xl',
                    withCard: false
                }
            case SPAREPART:
                return {
                    heading: `Sparepart`,
                    content: <SparePartPage
                        clickTr={action.onClickTr}
                        isModal={true}
                        handleClose={action.handleClose}
                    />,
                    confirmButtonText: '',
                    confirmButtonAction: null,
                    show: true,
                    size: 'xl',
                    withCard: false
                }
            case ACCOUNT:
                return {
                    heading: `Account`,
                    content: <AccountPage
                        clickTr={action.onClickTr}
                        isModal={true}
                        handleClose={action.handleClose}
                        nature={action.nature ? action.nature : null}                      
                    />,
                    confirmButtonText: '',
                    confirmButtonAction: null,
                    show: true,
                    size: 'xl',
                    withCard: false
                }
            case KATEGORI_SPAREPART:
                return {
                    heading: `Kategori Sparepart`,
                    content: <KategoriPartPage
                        clickTr={action.onClickTr}
                        isModal={true}
                        handleClose={action.handleClose}                        
                    />,
                    confirmButtonText: '',
                    confirmButtonAction: null,
                    show: true,
                    size: 'xl',
                    withCard: false
                }
            case KONTAK:
                return {
                    heading: `Kontak`,
                    content: <KontakPage
                        clickTr={action.onClickTr}
                        isModal={true}
                        handleClose={action.handleClose}
                        tipeKontak={action.tipeKontak}
                    />,
                    confirmButtonText: '',
                    confirmButtonAction: null,
                    show: true,
                    size: 'xl',
                    withCard: false
                }
            case PEGAWAI:
                return {
                    heading: `Kontak`,
                    content: <PegawaiPage
                        clickTr={action.onClickTr}
                        isModal={true}
                        handleClose={action.handleClose}
                        tipeKontak={action.tipeKontak}
                        hiddenEditButton={action.hiddenEditButton}
                    />,
                    confirmButtonText: '',
                    confirmButtonAction: null,
                    show: true,
                    size: 'xl',
                    withCard: false
                }
            case KONTRAK:
                return {
                    heading: `Kontrak`,
                    content: <KontrakPage
                        clickTr={action.onClickTr}
                        isModal={true}
                        handleClose={action.handleClose}
                        hideButton={action.hideButton ? action.hideButton : false}
                        status={action.status ? action.status : ''}
                    />,
                    confirmButtonText: '',
                    confirmButtonAction: null,
                    show: true,
                    size: 'xl',
                    withCard: false
                }
            case DETAILKONTRAK:
                return {
                    heading: `Detail Kontrak`,
                    content: <KontrakViewPage
                        clickTr={action.onClickTr}
                        kontrakId={action.kontrakId}
                        isModal={true}
                        handleClose={action.handleClose}
                        infoKontrak={action.infoKontrak}
                    />,
                    confirmButtonText: '',
                    confirmButtonAction: null,
                    show: true,
                    size: 'xl',
                    withCard: false
                }
            case SUPIR:
                return {
                    heading: `Supir / Driver`,
                    content: <SupirPage
                        clickTr={action.onClickTr}
                        isModal={true}
                        handleClose={action.handleClose}
                        tipeKontak="supir"
                    />,
                    confirmButtonText: '',
                    confirmButtonAction: null,
                    show: true,
                    size: 'xl',
                    withCard: false
                }
            case SATUAN:
                return {
                    heading: `Satuan`,
                    content: <SatuanPage
                        clickTr={action.onClickTr}
                        isModal={true}
                        handleClose={action.handleClose}
                        tipeSatuan={action.tipeSatuan}
                    />,
                    confirmButtonText: '',
                    confirmButtonAction: null,
                    show: true,
                    size: 'xl',
                    withCard: false
                }
            case KENDARAAN:
                return {
                    heading: `Kendaraan`,
                    content: <KendaraanPage
                        clickTr={action.onClickTr}
                        isModal={true}
                        handleClose={action.handleClose}
                    />,
                    confirmButtonText: '',
                    confirmButtonAction: null,
                    show: true,
                    size: 'xl',
                    withCard: false
                }
            case CLOSE:
                return {
                    heading: '',
                    content: '',
                    confirmButtonText: '',
                    confirmButtonAction: () => console.log('close'),
                    show: false
                }
            case TANGKI:
                return {
                    heading: `Daftar Pegawai`,
                    content: <TangkiPage
                        clickTr={action.onClickTr}
                        isModal={true}
                        handleClose={action.handleClose}
                    />,
                    confirmButtonText: '',
                    confirmButtonAction: null,
                    show: true,
                    size: 'xl',
                    withCard: false
                }
            default:
                return {
                    heading: `See  Availability`,
                    content: `Below is availability. Select a time and date that works for you.`,
                    confirmButtonText: 'Request Session',
                    confirmButtonAction: () => console.log('Session(s) requested.'),
                    show: true,
                    size: 'lg',
                    withCard: true
                }
        }
    }
    const [modalState, modalDispatch] = useReducer(modalReducer, {
        heading: '',
        content: '',
        confirmButtonText: '',
        confirmButtonAction: null,
        show: false
    });

    return {
        modalState, modalDispatch, showConfirmCallToAction, setshowConfirmCallToAction
    }
}