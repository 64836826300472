import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import FormTextSingleComp from '../../../containers/Components/FormTextSingleComp';

const SupirForm = (props) => {
    const { 
        formData, handleChange, handleBlur, handleChangeDate,
        handleInsertFoto
    } = props;
    return(
        <Fragment>
            <Row>
                <Col lg={6}>
                    { Object.entries(Object.assign(...Object.keys(formData).slice(0, 6).map(k => ({ [k]: formData[k] })))).map(([index, post]) => {
                        if (post.show){
                            return (
                                <FormGroup row key={index}>
                                    <Label lg={4}>{post.label}</Label>
                                    <Col lg={8}>
                                        <FormTextSingleComp
                                            {...post}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onChangeDate={handleChangeDate}
                                        />
                                    </Col>
                                </FormGroup>
                            )
                        }
                        return true;
                    })}
                </Col>
                <Col lg={6}>
                    { Object.entries(Object.assign(...Object.keys(formData).slice(6, 10).map(k => ({ [k]: formData[k] })))).map(([index, post]) => {
                        if (post.show) {
                            return (
                                <FormGroup row key={index}>
                                    <Label lg={4}>{post.label}</Label>
                                    <Col lg={8}>
                                        { post.tipe === 'foto' ?
                                            <>
                                                <Input
                                                    type='file'
                                                    id={`upload-button`}
                                                    onChange={(e) => handleInsertFoto(e, post.name)}
                                                    name={post.name}
                                                />
                                                { post.value &&
                                                    <div className={post.name === 'foto_supir_url' ? 'pas-foto' : "foto-container"}>
                                                        <img src={post.value} alt="foto"/>
                                                    </div>                 
                                                }
                                            </>
                                            :
                                            <FormTextSingleComp
                                                {...post}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onChangeDate={handleChangeDate}
                                            />
                                        }
                                    </Col>
                                </FormGroup>
                            )
                        }
                        return true;
                    })}
                </Col>
            </Row>
        </Fragment>
    )
}
SupirForm.propTypes = {
    formData : PropTypes.object.isRequired,
    handleChange : PropTypes.func.isRequired,    
    handleBlur : PropTypes.func.isRequired,
    handleChangeDate : PropTypes.func.isRequired,
    handleInsertFoto : PropTypes.func.isRequired,
}
export default SupirForm;