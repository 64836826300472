import React, { Fragment } from 'react';
import {  Badge, Col,  Row, Table } from 'reactstrap';
import Moment from 'moment';
import { useGlobalFuntction } from '../../../../generalFunction/globalFunction';

const MuatanTerangkut = (props) => {
    let tmp = JSON.parse(localStorage.getItem('data')) || null;    
    let info = tmp && tmp.info ? tmp.info : null
    let listData = tmp && tmp.listJadwal ? tmp.listJadwal : null    
    let saldoDisplay = tmp && tmp.saldoDisplay ? tmp.saldoDisplay : null    
    const { getStateColor } = useGlobalFuntction();

    
    return(
        <div className='laporan-landscape' style={{background: '#ffffff'}}>
            {/* <div className='laporan'> */}

                <div className="header">
                    <Row>
                        <Col xs={12}>
                            <div className="text-center title">
                                Laporan Muatan Terangkut
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="text-center">
                                {info && info.rekanan_display}
                            </div>                            
                        </Col>
                        <Col xs={12}>
                            <div className="text-center">
                                Nomor : {info && info.nomor}
                            </div>
                            <div style={{marginBottom: 10}}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Row>
                                <Col lg={4}>Jenis Transaksi</Col>
                                <Col lg={8}>: {info && info.jenis_transaksi_display}</Col>
                            </Row>
                            <Row>
                                <Col lg={4}>Nomor Kontrak</Col>
                                <Col lg={8}>: {info && info.nomor}</Col>
                            </Row>
                            <Row>
                                <Col lg={4}>Rekanan</Col>
                                <Col lg={8}>: {info && info.rekanan_display}</Col>
                            </Row>
                            <Row>
                                <Col lg={4}>Pembeli</Col>
                                <Col lg={8}>: {info && info.pembeli_display}</Col>
                            </Row>
                        </Col>
                        <Col xs={6}>
                            <Row>
                                <Col lg={4}>Alamat Pengambilan</Col>
                                <Col lg={8}>: {info && info.alamat_pengambilan}</Col>
                            </Row>
                            <Row>
                                <Col lg={4}>Alamat Pengantaran</Col>
                                <Col lg={8}>: {info && info.alamat_pengantaran}</Col>
                            </Row>
                            <Row>
                                <Col lg={4}>Nilai Kontrak</Col>
                                <Col lg={8}>: {info && info.nilai_kontrak.toLocaleString()}</Col>
                            </Row>
                            <Row>
                                <Col lg={4}>Status</Col>
                                <Col lg={8}>: <Badge pill color={info && info.state ? getStateColor(info.state) : 'muted'}>{info && info.state_display}</Badge></Col>
                            </Row>
                        </Col>
                    </Row>                
                </div>
                <div className="body">
                    <Row>
                        <Col xs={12}>
                            <div style={{marginBottom: 10}}/>
                            <Table className='table-bordered table-small table-sm-padding table-header-withbackground table-center' responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>                                        
                                        <th>Tanggal (Plan)</th>
                                        <th>Tanggal (Selesai)</th>
                                        <th>Kendaraan</th>
                                        <th>Supir</th>
                                        {info && info.jenis_transaksi === 1 &&
                                            <Fragment>
                                                <th colSpan={2}>Jumlah Muatan (Plan)</th>
                                                <th colSpan={2}>Jumlah Muatan (Actual)</th>
                                                <th colSpan={2}>Jumlah Muatan Akhir (Actual)</th>
                                            </Fragment>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {listData && listData.map((post, index) => {                                        
                                        return (
                                            <tr key={index}>
                                                <td className='text-right'>{index + 1}</td>
                                                <td className='text-center'>{Moment(post.tanggal).format('DD-MM-YYYY')}</td>
                                                <td className='text-center'>{post.tanggal_selesai && Moment(post.tanggal_selesai).format('DD-MM-YYYY')}</td>
                                                <td>{post.kendaraan_display.nopol}</td>
                                                <td>{post.supir_display}</td>
                                                { info && info.jenis_transaksi === 1 &&
                                                    <Fragment>
                                                        <td className='text-right'>{post.jumlah_muatan_display.toLocaleString()}</td>
                                                        <td>{saldoDisplay.satuan_display}</td>
                                                        <td className='text-right'>{post.jumlah_muatan_actual_display.toLocaleString()}</td>
                                                        <td>{saldoDisplay.satuan_display}</td>                                                      
                                                        <td className='text-right'>{post.jumlah_muatan_akhir_display.toLocaleString()}</td>
                                                        <td>{saldoDisplay.satuan_display}</td>                                                      
                                                    </Fragment>
                                                }
                                            </tr>
                                        )
                                    })}
                                    { info && info.jenis_transaksi === 1 &&
                                        <Fragment>
                                            <tr>
                                                <td className="text-right" colSpan={7}>Jumlah Muatan Kontrak</td>
                                                <td></td>
                                                <td></td>
                                                <td className="text-right" >{info && info.jumlah_display.toLocaleString()}</td>
                                                <td>{saldoDisplay.satuan_display}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-right" colSpan={7}>Jumlah Selesai</td>
                                                <td className="text-right" >{saldoDisplay.total_done.toLocaleString()}</td>
                                                <td>{saldoDisplay.satuan_display}</td>
                                                <td className="text-right" >{saldoDisplay.total_akhir.toLocaleString()}</td>
                                                <td>{saldoDisplay.satuan_display}</td>
                                            </tr>   
                                            <tr>
                                                <td className="text-right" colSpan={7}>Sisa Muatan</td>
                                                { info && info.jumlah ?
                                                    <td className="text-right" >{(parseFloat(info.jumlah_display) - parseFloat(saldoDisplay.total_done)).toLocaleString()}</td>
                                                    :
                                                    <td className="text-right" >0</td>
                                                }
                                                <td>{saldoDisplay.satuan_display}</td>                                                    
                                                { info && info.jumlah ?
                                                    <td className="text-right" >{(parseFloat(info.jumlah_display) - parseFloat(saldoDisplay.total_akhir)).toLocaleString()}</td>
                                                    :
                                                    <td className="text-right" >0</td>
                                                }
                                                <td>{saldoDisplay.satuan_display}</td>                                                    
                                            </tr>   
                                        </Fragment>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            {/* </div> */}
        </div>
    )
}
export default MuatanTerangkut;