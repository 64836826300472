import React, { Fragment } from 'react';
import { Card, Alert, Button, ButtonGroup, CardBody, Col, FormGroup, Label, Pagination, 
        PaginationItem, PaginationLink, Row, Table, Modal, ModalHeader, ModalBody, 
        ModalFooter, CardHeader } from 'reactstrap';
// import CardTemplate from '../../../../containers/Components/CardTemplate';
import FormTextSingleComp from '../../../../containers/Components/FormTextSingleComp';
import LoaderComponent from '../../../../containers/Components/LoaderComponent';
import SearchComp from '../../../../containers/Components/SearchComp';
import { useSatuan } from './satuanFunction';

const SatuanPage = (props) => {
    const tipeSatuan = props && props.tipeSatuan ? props.tipeSatuan : null;
    const { 
        pageValue, listData, isLoading, alertValue, handleCloseAlert, formData, handleChange, handleBlur,
        handlePagination, handleEdit, isEdit, handleCancel, handleSubmit, handleDelete, handleSubmitDelete, modalValue,
        handleAdd,
        handleChangeSearch, handleKeyDownSearch, handleSearch
    } = useSatuan({tipe: 'list', produkId : props.produkId || null, satuanWaktu: props.satuanWaktu || null, defaultSatuan : props.defaultSatuan || 'yes', tipeSatuan : tipeSatuan})

    const fullSize = props.fullSize || false;

    return(
        <Fragment>
            { isLoading && <LoaderComponent/>}
            <Alert isOpen={alertValue.show} toggle={handleCloseAlert} color={alertValue.color}>
                {alertValue.text}
            </Alert>
            <Modal isOpen={modalValue.show} toggle={handleCancel}>
                <ModalHeader toggle={handleCancel}>{modalValue.title}</ModalHeader>
                <ModalBody>
                    { modalValue.tipe === 'create' &&
                        <Fragment>
                            { Object.entries(formData).map(([index, post]) => {
                                if (post.show){
                                    return(
                                        <FormGroup key={index}>
                                            <Label>{post.label}</Label>
                                            <FormTextSingleComp
                                                {...post}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </FormGroup>
                                    )
                                }
                                return true;
                            }) }

                        </Fragment>
                    }
                    { modalValue.tipe === 'delete' &&
                        <Fragment>
                            { modalValue.text }
                        </Fragment>
                    }
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                        
                        <Button size="sm" color={modalValue.tipe === 'create' ? 'success' : 'danger'} onClick={() => modalValue.tipe === 'delete' ? handleSubmitDelete() : handleSubmit()}>{modalValue.tipe === 'delete' ? 'Delete' : isEdit ? 'Update' :  'Save'}</Button>
                        <Button size="sm" color="secondary" onClick={handleCancel}>Cancel</Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
            <Card>
                <CardHeader>
                    <Row>
                        <Col lg={6}>
                            Satuan
                        </Col>
                        { props.handleClose &&
                            <Col lg={6}>
                                <div className="float-right">
                                    <i className='fa fa-times' onClick={props.handleClose} style={{cursor: 'pointer'}}></i>
                                </div>
                            </Col>                        
                        }
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg={fullSize ? 12 : 6} className={fullSize ? 'offset-lg-0' : 'offset-lg-3'}>
                            <Row>
                                <Col lg={8}>
                                    <SearchComp
                                        value={pageValue.search}
                                        onChange={handleChangeSearch}
                                        onKeyDown={handleKeyDownSearch}
                                        onClick={handleSearch}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <div className="float-right">
                                        <ButtonGroup>
                                            <Button color="success" onClick={handleAdd}><i className='fa fa-plus'></i> Add</Button>
                                        </ButtonGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Table className='small'>
                                <thead>
                                    <tr>
                                        <th className='text-right'>#</th>
                                        <th className='text-center'>Satuan</th>
                                        <th className='text-center'>Deskripsi</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { listData.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}} className='text-right'>{index+1+pageValue.offset}</td>
                                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.nama}</td>
                                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.deskripsi}</td>
                                                <td className='text-center'>
                                                    <ButtonGroup>
                                                        <Button className='btn-edit' color="primary" onClick={() => handleEdit(post)}><i className='fa fa-edit'></i></Button>
                                                        <Button className='btn-edit' color="danger" onClick={() => handleDelete(post)}><i className='fa fa-trash'></i></Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            { pageValue.obj.length > 1 &&
                                <Pagination>
                                    { pageValue.obj.map((post, index) => {
                                        return(
                                            <PaginationItem key={index} className={ pageValue.page === post ? 'active' : ''}>
                                                <PaginationLink tag="button" onClick={() => handlePagination(post)}>
                                                    {post}
                                                </PaginationLink>
                                            </PaginationItem>
                                        )
                                    })}
                                </Pagination>
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default SatuanPage;