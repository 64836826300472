import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import FormTextSingleComp from '../../../../containers/Components/FormTextSingleComp';

const SparePartFormComp = (props) => {
    const { formData, handleChange, handleBlur, handleShowKategori, handleShowSatuan,
        handleShowAccount
    } = props;
    return(
        <Fragment>
            <Row>
                <Col lg={6}>
                    { Object.entries(Object.assign(...Object.keys(formData).slice(0, 6).map(k => ({ [k]: formData[k] })))).map(([index, post]) => {
                        if (post.show){
                            return(
                                <FormGroup row key={index}>
                                    <Label className='col-lg-4'>{post.label}</Label>
                                    <Col lg={8}>
                                        <FormTextSingleComp
                                            {...post}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onClick={index === 'kategori_display' ? handleShowKategori : handleShowSatuan}
                                        />
                                    </Col>
                                </FormGroup>
                            )
                        }
                        return true;
                    })}
                </Col>
                <Col lg={6}>
                    { Object.entries(Object.assign(...Object.keys(formData).slice(6, 15).map(k => ({ [k]: formData[k] })))).map(([index, post]) => {
                        if (post.show){
                            return(
                                <FormGroup row key={index}>
                                    <Label className='col-lg-4'>{post.label}</Label>
                                    <Col lg={8}>
                                        <FormTextSingleComp
                                            {...post}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onClick={index === 'account_persediaan_display' ? handleShowAccount : index === 'satuan_display' ? handleShowSatuan : null}
                                        />
                                    </Col>
                                </FormGroup>
                            )
                        }
                        return true;
                    })}
                </Col>
            </Row>
        </Fragment>
    )
}
SparePartFormComp.propTypes = {
    formData : PropTypes.object.isRequired,
    handleChange : PropTypes.func.isRequired,
    handleBlur : PropTypes.func.isRequired,
    handleShowKategori : PropTypes.func.isRequired,
    handleShowSatuan : PropTypes.func.isRequired,
    handleShowAccount : PropTypes.func.isRequired,
}
export default SparePartFormComp;