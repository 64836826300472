import React, { useState, useEffect, Fragment } from 'react';
import { Button, Modal, ModalFooter, } from 'reactstrap';


export const CustomModal = React.forwardRef((props: any, ref) => {

    const { show, confirmButtonText, confirmButtonAction, showConfirmCallToAction, size, withCard } = props;

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShowModal(show);
    }, [show])

    const handleClose = () => {
        setShowModal(false);
        props.close();
    }

    

    return (
        <Fragment>            
            <Modal isOpen={showModal} toggle={handleClose} size={size}>
                <>
                    {props.children}
                    { withCard &&
                        <ModalFooter>
                            <Button variant="secondary" onClick={handleClose}>Close</Button>
                            {
                                showConfirmCallToAction && showConfirmCallToAction === true &&
                                <Button variant="primary" className="ModalButton" onClick={confirmButtonAction}>
                                    {confirmButtonText}
                                </Button>
                            }

                        </ModalFooter>                    
                    }
                </>
            </Modal >
        </Fragment>
    );
})