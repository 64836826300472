import React from 'react';
import {  Col, FormGroup, Label, Row, Table } from 'reactstrap';


const MutasiCetak = (props) => {
    let info = JSON.parse(localStorage.getItem('mutasiInfo')) || null;
    return(
        <div className='laporan-landscape' style={{background: '#ffffff'}}>
            {/* <div className='laporan'> */}

                <div className="header">
                    <Row>
                        <Col xs={12}>
                            <div className="text-center title">
                                Laporan Mutasi
                            </div>
                            <div style={{marginBottom: 10}}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <div className="without-padding-bottom">
                                <FormGroup row>
                                    <Label className='col-xs-6'>Nomor Mutasi</Label>
                                    <Col xs={6}>
                                        :&nbsp;{info && info.data && info.data.nomor_mutasi}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label className='col-xs-6'>Tanggal Mutasi</Label>
                                    <Col xs={6}>
                                        :&nbsp;{info && info.data && info.data.tanggal_request_display}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label className='col-xs-6'>Tanggal Diterima</Label>
                                    <Col xs={6}>
                                        :&nbsp;{info && info.data && info.data.tanggal_diserahkan_display}
                                    </Col>
                                </FormGroup>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="without-padding-bottom">
                                <FormGroup row>
                                    <Label className='col-xs-6'>Lokasi Asal</Label>
                                    <Col xs={6}>
                                        :&nbsp;{info && info.data && info.data.dari_lokasi_display}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label className='col-xs-6'>Lokasi Tujuan</Label>
                                    <Col xs={6}>
                                        :&nbsp;{info && info.data && info.data.ke_lokasi_display}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label className='col-xs-6'>Status</Label>
                                    <Col xs={6}>
                                        :&nbsp;{info && info.data && info.data.status_display}
                                    </Col>
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>                
                </div>
                <div className="body">
                    <Row>
                        <Col xs={12}>
                            <div style={{marginBottom: 10}}/>
                            <Table className='table-bordered table-small table-sm-padding table-header-withbackground table-center' responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Kode</th>
                                        <th>Nama Barang</th>
                                        <th>Qty Mutasi</th>
                                        <th>Qty Diterima</th>                                
                                    </tr>
                                </thead>
                                <tbody>
                                    { info && info.listData && info.listData.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td className="text-right">{index+1}</td>
                                                <td>{post.produk_display && post.produk_display.kode}</td>
                                                <td>{post.produk_display && post.produk_display.nama}</td>
                                                <td className='text-right'>{post.qty_request}</td>
                                                <td className='text-right'>{post.qty_diterima}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            {/* </div> */}
        </div>
    )
}
export default MutasiCetak;