import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Col, Row, Table } from 'reactstrap';
import SearchComp from '../../../../containers/Components/SearchComp';

const KategoriListComp = (props) => {
    const { listData, pageValue, handleEdit, handleDelete,
        handleChangeSearch, handleSearch, handleKeyDownSearch, handleAdd
    } = props;
    return(
        <Fragment>
            <Row>
                <Col lg={8}>
                    <SearchComp
                        value={pageValue.search}
                        onChange={handleChangeSearch}
                        onKeyDown={handleKeyDownSearch}
                        onClick={handleSearch}
                    />
                </Col>
                <Col lg={4}>
                    <Button onClick={handleAdd} color="primary"><i className='fa fa-plus'></i> Tambah Kategori Sparepart</Button>
                </Col>
            </Row>            
            <Table className={'table-bordered table-small table-sm-padding table-header-withbackground table-center text-sm'} responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nama Kategori</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {listData.map((post, index) => {
                        return(
                            <tr key={index}>
                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}} className="text-right">{pageValue.offset+1+index}</td>
                                <td onClick={() => props.clickTr ? props.clickTr(post) : null} style={{cursor: props.clickTr ? 'pointer' : 'inherit'}}>{post.nama}</td>
                                <td className="text-center">
                                    <ButtonGroup>
                                        <Button className='btn-edit' color='primary' onClick={() => handleEdit(post)}><i className='fa fa-edit'></i></Button>
                                        <Button className='btn-edit' color='danger' onClick={() => handleDelete(post)}><i className='fa fa-trash'></i></Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Fragment>
    )
}

KategoriListComp.propTypes = {
    listData : PropTypes.array.isRequired,
    pageValue : PropTypes.object.isRequired,
    handleEdit : PropTypes.func.isRequired,
    handleDelete : PropTypes.func.isRequired,
    handleChangeSearch : PropTypes.func.isRequired,
    handleSearch : PropTypes.func.isRequired,
    handleKeyDownSearch : PropTypes.func.isRequired,
    handleAdd : PropTypes.func.isRequired,
}

export default KategoriListComp;