import { useCallback, useEffect, useState } from 'react';
import config from '../../../config';
import { useGlobalFuntction } from '../../../generalFunction/globalFunction';
import api from '../../../services/api';
import { pageObj } from '../../../utils/pageObj';
import Moment from 'moment';
import { useGlobalModalFunction } from '../../../generalFunction/globalModalFunction';


export function useKendaraan(props){
    const {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal
    } = useGlobalFuntction();
    const { modalState, modalDispatch, showConfirmCallToAction, setshowConfirmCallToAction } = useGlobalModalFunction()
    
    const [listData, setListData] = useState([]);
    const [view, setView] = useState('list');
    const [kendaraanId, setKendaraanId] = useState(0);
    const initFormData = {
        kategori : {
            value : '',
            required : true,
            showError : false,
            errorMsg : '',
            show: true,
            tipe: 'select',
            label : 'Kategori',
            name : 'kategori',
            obj : [],
        },
        nopol : {
            value : '',
            required : true,
            showError : false,
            errorMsg : '',
            show: true,
            tipe: 'text',
            label : 'Nomor Polisi',
            name : 'nopol',
        },
        tipe : {
            value : '',
            required : false,
            showError : false,
            errorMsg : '',
            show: true,
            tipe: 'text',
            label : 'Tipe Kendaraan',
            name : 'tipe',
        },
        merk : {
            value : '',
            required : false,
            showError : false,
            errorMsg : '',
            show: true,
            tipe: 'text',
            label : 'Merk',
            name : 'merk',
        },
        tahun_perakitan : {
            value : '',
            required : true,
            showError : false,
            errorMsg : '',
            show: true,
            tipe: 'number',
            label : 'Tahun Perakitan',
            name : 'tahun_perakitan',
        },
        chasis : {
            value : '',
            required : false,
            showError : false,
            errorMsg : '',
            show: true,
            tipe: 'text',
            label : 'Nomor Rangka',
            name : 'chasis',
        },
        engine : {
            value : '',
            required : false,
            showError : false,
            errorMsg : '',
            show: true,
            tipe: 'text',
            label : 'Nomor Mesin',
            name : 'engine',
        },
        kapasitas : {
            value : '',
            required : true,
            showError : false,
            errorMsg : '',
            show: true,
            tipe: 'number',
            label : 'Kapasitas (dalam kg)',
            name : 'kapasitas',
        },
        tanggal_kir : {
            value : '',
            required : false,
            showError : false,
            errorMsg : '',
            show: true,
            tipe: 'date',
            label : 'Tanggal KIR',
            name : 'tanggal_kir',
        },
        tanggal_pkb : {
            value : '',
            required : false,
            showError : false,
            errorMsg : '',
            show: true,
            tipe: 'date',
            label : 'Tanggal PKB',
            name : 'tanggal_pkb',
        },
        tangki_display : {
            value : '',
            required : false,
            showError : false,
            errorMsg : '',
            show: true,
            tipe: 'input_button',
            label : 'Tangki',
            name : 'tangki_display',
            readOnly : true,        
        },
        tangki : {
            value : '',
            required : false,
            showError : false,
            errorMsg : '',
            show: true,
            tipe: 'date',
            label : 'Tangki',
            name : 'tangki',
            readOnly : true,        
        },
    }
    const [formData, setFormData] = useState(initFormData)
    const columnHeader = [
        {
            Header: 'Nomor Polisi',
            accessor: 'nopol',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Tipe Kendaraan',
            accessor: 'tipe',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Merk Kendaraan',
            accessor: 'merk',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Tahun Perakitan',
            accessor: 'tahun_perakitan_display',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Nomor Rangka',
            accessor: 'chasis',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Nomor Mesin',
            accessor: 'engine',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Kapasitas',
            accessor: 'kapasitas',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Tanggal KIR',
            accessor: 'tanggal_kir_display',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Tanggal PKB',
            accessor: 'tanggal_pkb_display',
            sort: true,
            className: 'text-center',
        },
        {
            Header: 'Tangki',
            accessor: 'tangki_display',
            sort: true,
            className: 'text-center',
        },
    ]

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        try{
            let url = `${config.endPoint.kendaraan}`
            let params = {
                limit : limit,
                offset: offset,
                q : q
            }
            const res = await api.get(url, {params : params}).then(res => res.data);
            setIsLoading(false); 
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setAlertValue, setPageValue, setIsLoading]) 

    const fetchKategoriKendaraan = async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.kategoriKendaraan}`).then(res => res.data);
            let tmp = [{value : '', label : '-- Pilih Kategori Kendaraan --'}]
            if (res){
                res.results.map(post => tmp.push({value: post.id, label : post.nama}))                
            }
            setFormData(formData => ({...formData,
                kategori : {...formData.kategori, obj : tmp}
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue,
            [name] : value
        }));
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, page : 1, offset: 0}));
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handleAdd = () => {
        setView('create');
        setKendaraanId(0);
        fetchKategoriKendaraan();
        setFormData(initFormData)
    }

    const handlePagination = (post) => {
        const myOffset = (parseInt(post) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : post, offset: myOffset}))
        fetchData(pageValue.search,  myOffset, config.itemPerPage)
    }

    const handleChangeSearchDate = (e, name) => {
        if (name === 'startDate'){
            fetchData(pageValue.search,  0, config.itemPerPage);
             // setStartDate(e);
        }else{
            fetchData(pageValue.search,  0, config.itemPerPage);
             // setEndDate(e);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        let nilai = value;
        if (formData[name].tipe === 'number'){
            nilai = nilai.replace(/,/g, '');
        }
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : nilai}
        }));
    }

    const handleChangeDate = (e, name) => {
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : e}
        }))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && (value === '' || value === null)){
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError: true}
            }))
        }else{
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError: false}
            }))
        }
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let countError = 0;
            let form = {...formData};
            let tmpForm = {}
            Object.entries(form).map(([index, post]) => {
                if (post.required && (post.value === '' || post.value === null)){
                    post.showError = true;
                    countError++;
                }else{
                    tmpForm[index] = post.value;
                }
                return true;
            });
            if (countError > 0){
                setFormData(form);
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color: 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                return;
            }

            

            tmpForm['tanggal_kir'] = Moment(tmpForm['tanggal_kir']).format('YYYY-MM-DD');
            tmpForm['tanggal_pkb'] = Moment(tmpForm['tanggal_pkb']).format('YYYY-MM-DD');

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            let res;
            if (view === 'create'){
                res = await api.post(`${config.endPoint.kendaraan}`, newForm).then(res => res.data);
            }else{
                res = await api.put(`${config.endPoint.kendaraan}${kendaraanId}/`, newForm).then(res => res.data);
            }
            if (res){
                setView('list');
                fetchData();
            }
            setIsLoading(false);
        }catch(error){
            const { response } = error;
            let msg = '';
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            Object.entries(response.data.error).map(([index, post]) => {
                msg += `<p>${index} : ${post.toLocaleString()}</p>`
                return true;
            });

            if (msg === ''){
                msg = "Unable to fetch data"
            }
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }    
    
    const handleCancel = () => {
        setView('list');        
    }

    const handleEdit = (post) => {
        fetchKategoriKendaraan();
        setFormData(initFormData)
        setView('edit');
        setKendaraanId(post.id);
        setFormData(formData => ({...formData,
            nopol : {...formData.nopol, value : post.nopol},
            tipe : {...formData.tipe, value : post.tipe},
            merk : {...formData.merk, value : post.merk},
            tahun_perakitan : {...formData.tahun_perakitan, value : post.tahun_perakitan},
            chasis : {...formData.chasis, value : post.chasis},
            engine : {...formData.engine, value : post.engine},
            kapasitas : {...formData.kapasitas, value : post.kapasitas},
            tanggal_kir : {...formData.tanggal_kir, value : Moment(post.tanggal_kir_display.tanggal, 'DD-MM-YYYY').toDate() || null},
            tanggal_pkb : {...formData.tanggal_pkb, value : Moment(post.tanggal_pkb_display.tanggal, 'DD-MM-YYYY').toDate() || null},
            tangki_display : {...formData.tangki_display, value : post.tangki_display || ''},
            tangki : {...formData.tangki, value : post.tangki_id || ''},
            kategori : {...formData.kategori, value : post.kategori || ''},
        }))
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue, 
            show: true, 
            text : `Apakah anda yakin akan menhapus data kendaraan ${post.nopol} ?`,
            title : 'Konfirmasi',
            id : post.id
        }));
    }

    const handleSubmitDelete = async(id=0) => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.kendaraan}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData();
                handleCloseModal();
            }
            setIsLoading(false);
        }catch(error){
            const { response } = error;
            let msg = '';
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            Object.entries(response.data.error).map(([index, post]) => {
                msg += `<p>${index} : ${post.toLocaleString()}</p>`
                return true;
            });

            if (msg === ''){
                msg = "Unable to fetch data"
            }
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleShowKendaraan = () => {
        modalDispatch({
            type: 'TANGKI',
            onClickTr: handleClickTangki,
            handleClose: closeModalHandler,                
        })
    }

    const handleClickTangki = (post) => {
        setFormData(formData => ({...formData,
           tangki_display : {...formData.tangki_display, value : `${post.kode} - ${post.kapasitas.toLocaleString()}`},
           tangki : {...formData.tangki, value : post.id},
        }))
        closeModalHandler();
    }

    const closeModalHandler = () => {        
        modalDispatch({
            type: 'CLOSE'
        })
    }

    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal,
        handleChangeSearch, handleSearch, handleKeyDownSearch, handlePagination,
        formData, handleChange, handleBlur,
        handleSubmit,handleAdd, listData, view, handleChangeSearchDate, columnHeader,
        handleCancel, handleDelete,
        handleChangeDate, handleEdit, handleSubmitDelete, modalState, modalDispatch, 
        showConfirmCallToAction, setshowConfirmCallToAction, handleShowKendaraan, closeModalHandler
    }
}