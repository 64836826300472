import { useCallback, useEffect, useState } from "react";

import config from "../../../../config";
import { useGlobalFuntction } from "../../../../generalFunction/globalFunction";
import api from "../../../../services/api";
import { pageObj } from "../../../../utils/pageObj";

export function useSatuan(props) {
    const { alertValue, setAlertValue, modalValue, setModalValue, isLoading, setIsLoading,
        handleCloseAlert, handleCloseModal, pageValue, setPageValue,
    } = useGlobalFuntction()
    const produkId = props.produkId || ''
    const satuanWaktu = props.satuanWaktu || ''
    const defaultSatuan = props.defaultSatuan || 'yes';
    const tipeSatuan = props.tipeSatuan || null;
    
    const [listData, setListData] = useState([]);
    const [formData, setFormData] = useState({
        id : {
            value : 0,
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: false, 
            tipe: 'text', 
            label : 'ID', 
            name : 'id',
        },
        nama : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Nama Satuan', 
            name : 'nama',
        },
        deskripsi : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Deskripsi', 
            name : 'deskripsi',
        },
    });

    // const history = useHistory();
    const [isEdit, setIsEdit] = useState(false);

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        try{
            let res;
            let url = `${config.endPoint.satuan}?q=${q}`
            if (produkId){
                url = url + `&produk_id=${produkId}`
            }
            if (satuanWaktu){
                url = url + `&satuan_waktu=${satuanWaktu}`
            }
            
            if (defaultSatuan){
                url = url + `&default_satuan=${defaultSatuan}`
            }

            let params = {
                limit : limit, 
                offset : offset,
            }
            if (tipeSatuan){
                params['tipe_satuan'] = tipeSatuan
            }
            res = await api.get(url, {params : params}).then(res => res.data);
            setIsLoading(false);
            
            setIsLoading(false);
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj: pageObj(res.count, limit, offset)
                }))
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setAlertValue, setPageValue, setIsLoading, produkId, satuanWaktu, defaultSatuan, tipeSatuan])

    useEffect(() => {
        if (props.tipe === 'list'){
            fetchData();
        }
    },[props.tipe, fetchData])

    const validateForm = (formDetail, value) => {
        if (formDetail.name !== 'confirm_password'){            
            if (formDetail.required && value === ''){
                return {
                    'isError' : true,
                    'errorMessage' : formDetail.errorMessage
                }
            }else{
                return {
                    'isError' : false,
                    'errorMessage' : formDetail.errorMessage
                }
            }
        }else{            
            if (value !== formData.password.value){                
                return {
                    'isError' : true,
                    'errorMessage' : 'Confirm password did not match with password'
                }
            }else{                
                return {
                    'isError' : false,
                    'errorMessage' : 'Confirm password did not match with password'
                }
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        let nilai = value;
        if (formData[name].tipe === 'number'){
            nilai = nilai.replace(/,/g, '');
        }
        setFormData(formData => ({...formData, [name] : {...formData[name], value: nilai}}))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        let validate = validateForm(formData[name], value);
        setFormData(formData => ({...formData,
            [name] : {...formData[name], 
                showError: validate.isError,
                errorMsg : validate.errorMessage
            }
        }))
    }

    const handlePagination = (page) => {
		let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
		setPageValue(pageValue => ({...pageValue, page: page, offset: myOffset}));		
		fetchData(pageValue.search, myOffset, config.itemPerPage)
	}	


    // ========================= form =========================
    const handleAdd = () => {
        setIsEdit(false);
        setModalValue(modalValue => ({...modalValue,
            show: true,
            tipe : 'create',
            title : 'Add Satuan'
        }))

    }
    const handleEdit = async(post) => {
        setIsEdit(true);
        setModalValue(modalValue => ({...modalValue,
            show: true,
            tipe : 'create',
            title : 'Add Satuan'
        }))
        setFormData(formData => ({...formData,
            nama : {...formData.nama, value : post.nama},
            id : {...formData.id, value : post.id, required: true},
            deskripsi : {...formData.deskripsi, value: post.deskripsi}
        }))        
    }

    const handleCancel = () => {
        setFormData(formData => ({...formData,
            nama : {...formData.nama, value: ''},
            deskripsi : {...formData.deskripsi, value: ''},
            id : {...formData.id, value : 0, required: false}           
        }));
        setIsEdit(false);
        handleCloseModal();
    }
    

    const handleSubmit = async() => {
        setIsLoading(true)
        try{
            let tmpForm = {}
            let countError = 0;
            let array = {...formData};

            Object.entries(array).map(([index, post]) => {
                let validate = validateForm(post, post.value)
                if (validate.isError) {
                    countError++;
                    post.showError = true;
                    post.errorMessage = validate.errorMessage;
                }else{
                    tmpForm[index] = post.value;
                }
                return true;
            });

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
                setIsLoading(false);
                setTimeout(() => {
                    handleCloseAlert();
                }, 5000);
                return;
            }

            if (tipeSatuan){
                tmpForm['tipe_satuan'] = tipeSatuan
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm))
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.satuan}${formData.id.value}/`, newForm).then(res => res.data);                
            }else{
                res = await api.post(`${config.endPoint.satuan}`, newForm).then(res => res.data);
            }
            setIsLoading(false);
            if (res){
                if (props.handleCancel){
                    props.handleCancel()
                }else{
                    fetchData();
                    handleCancel();
                    handleCloseModal();
                }
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue, 
            show: true, 
            text : `Apakah anda yakin akan menghapus kategori ${post.nama} ? `, 
            title: 'Konfirmasi',
            tipe : 'delete'
        }));
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id}
        }))        
    }

    const handleSubmitDelete = async() => {
        try{
            await api.delete(`${config.endPoint.satuan}${formData.id.value}/`).then(res => res.data);
            fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
            handleCloseModal();
        }catch(error){
            setIsLoading(false);
            setPageValue(pageValue => ({...pageValue, show: true, text: 'Unable to delete data', color : 'danger'}))
            setTimeout(() => {
                setPageValue(pageValue => ({...pageValue, show: false}))
            }, 5000)
        }
    }


    // ============ SEARCH ===============
    const handleChangeSearch = (e) => {
        const {name, value} = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}));
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage);
        setPageValue(pageValue => ({...pageValue,
            page : 1,
            offset: 0
        }))
    }

    return {
        alertValue, setAlertValue, modalValue, setModalValue, isLoading, setIsLoading,
        handleCloseAlert, handleCloseModal, listData, pageValue,
        formData, handleChange, handleBlur, handlePagination, handleEdit, handleCancel,
        isEdit, handleSubmit, handleDelete, handleSubmitDelete,
        handleAdd, handleChangeSearch, handleKeyDownSearch, handleSearch
    }
}