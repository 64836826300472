import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, FormGroup, Label, Table } from 'reactstrap';
import FormTextSingleComp from '../../../../containers/Components/FormTextSingleComp';

const SparepartSatuanFormComp = (props) => {
    const {formSatuan, handleChange, handleBlur, listSatuan, handleDeleteSatuan, satuanDefault,
        handleShowSatuanFormSatuan, handleSubmitSatuan
    } = props;
    return(
        <Fragment>
            <FormGroup row>
                <Label className='col-lg-4'>{formSatuan.satuan_display.label}</Label>
                <Col lg={8}>
                    <FormTextSingleComp
                        {...formSatuan.satuan_display}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onClick={handleShowSatuanFormSatuan}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className='col-lg-4'>{formSatuan.nilai.label}</Label>
                <Col lg={8}>
                    <FormTextSingleComp
                        {...formSatuan.nilai}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Col>
            </FormGroup>
            <Button block color="primary" onClick={handleSubmitSatuan} size="sm">Simpan Satuan</Button>
            <div style={{marginBottom: 15}}/>
            <Table className={'table-bordered table-small table-sm-padding table-header-withbackground table-center text-sm'} responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Satuan</th>
                        <th>Nilai Konversi</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    { listSatuan.map((post, index) => {
                        if (satuanDefault !== post.value){
                            return(
                                <tr key={index}>
                                    <td className="text-right">{index+1}</td>
                                    <td>{post.label}</td>
                                    <td className='text-right'>{post.nilai}</td>
                                    <td className="text-center">
                                        <Button onClick={() => handleDeleteSatuan(post)} color="danger" className='btn-edit'>
                                            <i className='fa fa-trash'></i>
                                        </Button>
                                    </td>
                                </tr>
                            )
                        }
                        return true;
                    })}
                </tbody>
            </Table>
        </Fragment>
    )
}
SparepartSatuanFormComp.propTypes = {
    formSatuan : PropTypes.object.isRequired,
    handleChange :  PropTypes.func.isRequired,
    handleBlur :  PropTypes.func.isRequired,
    listSatuan : PropTypes.array.isRequired,
    handleDeleteSatuan : PropTypes.func.isRequired,
    satuanDefault : PropTypes.number.isRequired,
    handleShowSatuanFormSatuan : PropTypes.func.isRequired,
    handleSubmitSatuan : PropTypes.func.isRequired,
}
export default SparepartSatuanFormComp;