import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import config from "../../config";
import { useGlobalFuntction } from "../../generalFunction/globalFunction";
import api from "../../services/api";
import { pageObj } from "../../utils/pageObj";
// import { useParams } from "react-router";
// import Moment from 'moment';

export function useStock(props){
    const {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, fetchAccount, fetchProdukSatuan, getPermission,
    } = useGlobalFuntction();
    const [listData, setListData] = useState([]);
    const history = useHistory();
    const lokasi_id = props && props.lokasi_id ? props.lokasi_id : null;
    
    // const date = new Date();
    // const { startDateParams, endDateParams } = useParams();
    // const [startDate, setStartDate] = useState(startDateParams ? Moment(startDateParams, 'YYYY-MM-DD').toDate() : new Date(date.getFullYear(), date.getMonth(), 1))
    // const [endDate, setEndDate] = useState(startDateParams ? Moment(endDateParams, 'YYYY-MM-DD').toDate() : new Date(date.getFullYear(), date.getMonth(), 1))
    const [listLokasi, setListLokasi] = useState([{value : '', label : '-- Semua Lokasi --'}]);    

    
    const fetchLokasi = useCallback(async() => {
        setIsLoading(true);
        try{            
            const res = await api.get(`${config.endPoint.lokasi}?with_user=yes`).then(res => res.data);
            // const res = await api.get(`${config.endPoint.lokasi}`).then(res => res.data);
            if (res){
                let tmp = [{value : '', label : '-- Semua Lokasi --'}];
                res.results.map(post => tmp.push({value : post.id, label : post.nama}));
                setListLokasi(tmp);            
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'unable to fetch data'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false, text : ''}))
            }, 5000)            
        }
    },[setIsLoading, setAlertValue]);


    const fetchData = useCallback(async(
        q='', 
        perusahaan='',
        lokasi_id='', 
        // startDatee=startDate, 
        // endDatee=endDate, 
        offset=0, 
        limit=config.itemPerPage) => {
        setIsLoading(true);
        try{
            let params = {
                q : q,
                lokasi_id : lokasi_id, 
                tipe_produk : 'dibeli',
                limit : limit,
                offset : offset,
                with_user : 'yes',
            }

            setListData([]);
            // let startDateValue = Moment(startDatee).format('YYYY-MM-DD')
            // let endDateValue = Moment(endDatee).format('YYYY-MM-DD')
            // const res = await api.get(`${config.endPoint.produk}?q=${q}&lokasi_id=${lokasi_id}&start_date=${startDateValue}&end_date=${endDateValue}`, { params : {limit: limit, offset: offset}}).then(res => res.data);
            const res = await api.get(`${config.endPoint.produk}`, { params : params}).then(res => res.data);
            setIsLoading(false);
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }

        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'unable to fetch data'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false, text : ''}))
            }, 5000)            
        }
    },[setIsLoading, setAlertValue, setPageValue]);

    const handleDetail = (post) => {        
        sessionStorage.setItem('produkId', post.id);
        history.push('/location/stock/detail')        
    }

    useEffect(() => {
        fetchLokasi();
    },[fetchLokasi])

    useEffect(() => {
        if (lokasi_id){
            fetchData('', '', lokasi_id, 0)
        }else{
            fetchData();
        }
    },[fetchData, lokasi_id])

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}));
        if (name === 'lokasi_id'){
            // fetchData(pageValue.search, value, startDate, endDate, 0, config.itemPerPage);
            fetchData(pageValue.search, pageValue.perusahaan, value,  0, config.itemPerPage);
            setPageValue(pageValue => ({...pageValue, page: 1, offset: 0}))
        }
        if (name === 'perusahaan'){
            fetchData(pageValue.search, value, pageValue.lokasi_id,  0, config.itemPerPage);
            setPageValue(pageValue => ({...pageValue, page: 1, offset: 0}))
        }
    }

    const handlePagination = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, pageValue.perusahaan, pageValue.lokasi_id, myOffset, config.itemPerPage);
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handleSearch = () => {
        fetchData(pageValue.search, pageValue.perusahaan, pageValue.lokasi_id, 0, config.itemPerPage);
        setPageValue(pageValue => ({...pageValue, page : 1, offset:0}))
    }

    const handlePrintSo = () => {
        if (pageValue.lokasi_id === ''){
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Untuk mencetak stock opname pilih salah satu lokasi'}))
            return;
        }
        localStorage.setItem(config.localStorageKey.STOCK_OPNAME_PARAMS, pageValue.lokasi_id);
        window.open(`#/laporan/cetak/stock-opname`, "_blank")        
    }

    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, fetchAccount, fetchProdukSatuan, getPermission,
        listData, listLokasi, handleChangeSearch, handlePagination, handleDetail,
        handleKeyDownSearch, handleSearch, handlePrintSo
    }
}