import { useGlobalFuntction } from "../../../generalFunction/globalFunction"
import { useEffect, useCallback, useState } from 'react';
import config from "../../../config";
import api from "../../../services/api";
import { pageObj } from "../../../utils/pageObj";


export function useAccount(props) {
    const { alertValue, setAlertValue, modalValue, setModalValue, isLoading, setIsLoading,
        handleCloseAlert, handleCloseModal, pageValue, setPageValue, getPermission
    } = useGlobalFuntction()
    const nature = props.nature || null;
    const tipe_query = props && props.tipe_query ? props.tipe_query : null
    const [listData, setListData] = useState([])
    const [openBalance, setOpenBalance] = useState(true);
    
    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        try{
            setListData([]);
            let res;
            let url = `${config.endPoint.account}?q=${q}`;
            if (tipe_query !== null){
                url += `&tipe_query=${tipe_query}`
            }
            if (nature){
                url += `&nature=${nature}`
            }
            
            res = await api.get(url, {params : {limit : limit, offset: offset}}).then(res => res.data);
            setIsLoading(false);
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj: pageObj(res.count, limit, offset)
                }));
                setOpenBalance(res.open_balance);
            }            
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setAlertValue, setPageValue, setIsLoading, tipe_query, nature])

    useEffect(() => {
        fetchData();
    },[fetchData])

    const handlePagination = (page) => {
		let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
		setPageValue(pageValue => ({...pageValue, page: page, offset: myOffset}));		
		fetchData(pageValue.search, myOffset, config.itemPerPage)
	}

    const handleChangeSearch = (e) => {
        const {name, value} = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}));
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage);
        setPageValue(pageValue => ({...pageValue,
            page : 1,
            offset: 0
        }))
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue, 
            show: true, 
            text : `Apakah anda yakin akan menghapus akun ${post.noakun} - ${post.nmakun}`, 
            id : post.id, tipe: 'delete',
            title : 'Konfirmasi'
        }));
    }

    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.account}${modalValue.id}`).then(res => res.data);
            if (res){
                fetchData();
                setPageValue(pageValue => ({...pageValue, page: 1, offset: 0, search : ''}));
                handleCloseModal();
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    // ==================== add form ============================
    const [formData, setFormData] = useState({
        id : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: false, 
            tipe: 'text', 
            label : 'ID', 
            name : 'id',
            readOnly: true,
        },
        noakun : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Nomor Akun', 
            name : 'noakun',                        
        },
        nmakun : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Nama Akun', 
            name : 'nmakun',                        
        },
        under : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Kategori Induk', 
            name : 'under',
            obj : []
        },
        nature : {
            value : '',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Nature', 
            name : 'nature',
            obj : [],
        },        
        nobukti : {
            value : '',
            required : false, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'text', 
            label : 'Nomor Bukti', 
            name : 'nobukti',            
        },
        akunnr : {
            value : 'LR',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Akun Neraca / Laba Rugi', 
            name : 'akunnr',
            obj : [
                {value: 'NR', label : 'NR'},
                {value: 'LR', label : 'LR'},
            ]
        },
        akundb : {
            value : 'D',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Akun Debet / Kredit', 
            name : 'akundb',
            obj : [
                {value: 'D', label : 'D'},
                {value: 'K', label : 'K'},
            ]
        },
        subkat : {
            value : 'No',
            required : true, 
            showError : false, 
            errorMsg : '', 
            show: true, 
            tipe: 'select', 
            label : 'Memiliki Sub Kategori', 
            name : 'subkat',            
            obj : [
                {value: 'Yes', label : 'Yes'},
                {value: 'No', label : 'No'},
            ]
        },
    }) 

    const fetchNature = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.natureList}`).then(res => res.data);
            if (res){
                setFormData(formData => ({...formData,
                    nature : {...formData.nature, 
                        obj: res.results,
                        value : res.results.length > 0 ? res.results[0].value : ''
                    }
                }))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch nature', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setIsLoading, setAlertValue])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({...formData, [name] : {...formData[name], value : value}}))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === ''){
            setFormData(formData => ({...formData, [name] : {...formData[name], showError : true}}))
        }else{
            setFormData(formData => ({...formData, [name] : {...formData[name], showError : false}}))
        }
    }

    const handleAdd = () => {        
        setModalValue(modalValue => ({...modalValue, show: true, tipe: 'add', title: 'Tambah Account', text : ''}));
        fetchNature();
        fetchAccountKategori();
        setFormData(formData => ({...formData,
            id : {...formData.id, value : ''},
            noakun : {...formData.noakun, value : ''},
            nmakun : {...formData.nmakun, value : ''},
            under : {...formData.under, value : ''},
            nature : {...formData.nature, value : ''},
            nobukti : {...formData.nobukti, value : ''},
            akunnr : {...formData.akunnr, value : 'LR'},
            akundb : {...formData.akundb, value : 'D'},
            subkat : {...formData.subkat, value : 'No'},
        }))
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let tmpForm = {}
            let objForm = {...formData};
            let countError = 0;

            Object.entries(objForm).map(([index, post]) => {
                if (post.required && post.value === ''){
                    post.showError = true;
                    countError++;
                }else{
                    tmpForm[index] = post.value
                }
                return true;
            })

            if (countError > 0){
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                return;
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            let res;
            if (modalValue.tipe === 'edit'){
                res = await api.put(`${config.endPoint.account}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.account}`, newForm).then(res => res.data);
            }
            setIsLoading(false);
            if (res){
                handleCloseModal();
                setPageValue(pageValue => ({...pageValue, offset: 0, page: 1, search: ''}));
                fetchData();
            }

        }catch(error){
            setIsLoading(false);
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const fetchAccountKategori = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.account}?only_kategori=yes`).then(res => res.data);
            let tmp = [{value : '', label : '-- Kategori Induk --'}]
            if (res){
                res.results.map(post => tmp.push({value : post.id, label : `${post.noakun} - ${post.nmakun}`}));
            }
            setFormData(formData => ({...formData,
                under : {...formData.under, obj : tmp}
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch nature', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setAlertValue, setIsLoading])

    const handleEdit = (post) => {        
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id},
            noakun : {...formData.noakun, value : post.noakun},
            nmakun : {...formData.nmakun, value : post.nmakun},
            under : {...formData.under, value : post.under},
            nature : {...formData.nature, value : post.nature},
            nobukti : {...formData.nobukti, value : post.nobukti},
            akunnr : {...formData.akunnr, value : post.akunnr},
            akundb : {...formData.akundb, value : post.akundb},
            subkat : {...formData.subkat, value : post.subkat},
        }));
        fetchAccountKategori();
        fetchNature();
        setModalValue(modalValue => ({...modalValue, show: true, tipe : 'edit', text : ''}))
    }


    return {
        alertValue, setAlertValue, modalValue, setModalValue, isLoading, setIsLoading,
        handleCloseAlert, handleCloseModal, pageValue, setPageValue,
        listData, setListData, handlePagination, handleChangeSearch, handleKeyDownSearch, openBalance,
        handleAdd, formData, handleChange, handleBlur, handleSubmit, handleEdit, getPermission,
        handleDelete, handleSubmitDelete
    }


}