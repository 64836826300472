import React from 'react';
import { Col, Row, Table } from 'reactstrap';


const AccountResumePrint = (props) => {
    let info = JSON.parse(localStorage.getItem('accountResume')) || null;
    return(
        <div className='laporan-lanscape' style={{background: '#ffffff'}}>
            {/* <div className='laporan'> */}

                <div className="header">
                    <Row>
                        <Col xs={12}>
                            <div className="text-center title">
                                Laporan Resume Account
                            </div>
                            <div className="text-center title">
                                { info && info.data && info.data.perusahanName}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className="text-center tanggal-cetak">
                                <i>Periode { info && info.data && info.data.startDate} s.d { info && info.data && info.data.startDate} </i>
                            </div>
                        </Col>
                    </Row>                
                </div>
                <div className="body">
                    <Row>
                        <Col xs={12}>
                            <Table className='table-small table-bordered table-center table-laporan'>
                                <thead>
                                    <tr>
                                        <th rowSpan={2}>#</th>
                                        <th rowSpan={2}>No Akun</th>
                                        <th rowSpan={2}>Nama Akun</th>
                                        <th rowSpan={2}>Kategori</th>
                                        <th colSpan={2}>Open Balance</th>
                                        <th colSpan={2}>Transaksi</th>
                                        <th colSpan={2}>Closing Balance</th>                                    
                                    </tr>
                                    <tr>
                                        <th>D</th>
                                        <th>K</th>
                                        <th>D</th>
                                        <th>K</th>
                                        <th>D</th>
                                        <th>K</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { info && info.listData && info.listData.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td className="text-right">{index+1}</td>
                                                <td style={{cursor: 'pointer', color:'#3c8dbc'}}>{post.noakun}</td>
                                                <td style={{cursor: 'pointer', color:'#3c8dbc'}}>{post.nmakun}</td>
                                                <td>{post.nature_display}</td>                                        
                                                <td className='text-right'>{(post.akundb === 'D' && post.open_balance) ? post.open_balance.toLocaleString() : 0}</td>
                                                <td className='text-right'>{(post.akundb === 'K' && post.open_balance) ? post.open_balance.toLocaleString() : 0}</td>                                        
                                                <td className='text-right'>{(post.akundb === 'D' && post.transaksi) ? post.transaksi.toLocaleString() : 0}</td>
                                                <td className='text-right'>{(post.akundb === 'K' && post.transaksi) ? post.transaksi.toLocaleString() : 0}</td>
                                                <td className='text-right'>
                                                    {post.akundb === 'D'  ? (parseInt(post.open_balance) + parseInt(post.transaksi)).toLocaleString() : 0}
                                                </td>
                                                <td className='text-right'>{post.akundb === 'K'  ? (parseInt(post.open_balance) + parseInt(post.transaksi)).toLocaleString() : 0}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>                            
                            </Table>
                        </Col>
                    </Row>
                </div>
            {/* </div> */}
        </div>
    )
}
export default AccountResumePrint;