import { useCallback, useContext, useEffect, useState } from "react";
import config from "../../../../config";
import { UserContext } from "../../../../context/UserContext";
import { useGlobalFuntction } from "../../../../generalFunction/globalFunction";
import { useGlobalModalFunction } from "../../../../generalFunction/globalModalFunction";
import api from "../../../../services/api";
import { pageObj } from "../../../../utils/pageObj";

export function useMasterSparePart(props){
    const { 
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, 
        handleVerificationSubmit, handleVerificationBlur, handleChangeFormData,
        fetchAccount, fetchProdukSatuan, getPermission, getStateColor,
    } = useGlobalFuntction();    
    const myState = useContext(UserContext);
    const  { 
        modalState, modalDispatch, showConfirmCallToAction, setshowConfirmCallToAction
    } = useGlobalModalFunction();
    const [alertState, alertDispatch] = myState.alertState;
    const [listData, setListData] = useState([]);
    const [view, setView] = useState('list');
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            col : 'lg-6',
            value : 0,
            label : 'ID',
            tipe: 'text',
            show: false,
            required : false,
            showError : false,
            readOnly: true,
        },
        kategori : {
            name : 'kategori',
            col : 'lg-6',
            value : '',
            label : 'Kategori',
            tipe: 'text',
            show: false,
            required : true,
            showError : false,
            readOnly: true,
        },
        kategori_display : {
            name : 'kategori_display',
            col : 'lg-6',
            value : '',
            label : 'Kategori',
            tipe: 'input_button',
            show: true,
            required : true,
            showError : false,
            readOnly: true,
        },
        kode : {
            name : 'kode',
            col : 'lg-6',
            value : '',
            label : 'Kode',
            tipe: 'text',
            show: true,
            required : true,
            showError : false
        },        
        nama : {
            name : 'nama',
            col : 'lg-6',
            value : '',
            label : 'Nama',
            tipe: 'text',
            show: true,
            required : true,
            showError : false
        },
        satuan : {
            name : 'satuan',
            col : 'lg-6',
            value : '',
            label : 'Satuan',
            tipe: 'text',
            show: false,
            required : true,
            showError : false,
            readOnly: true,
        },
        satuan_display : {
            name : 'satuan_display',
            col : 'lg-6',
            value : '',
            label : 'Satuan',
            tipe: 'input_button',
            show: true,
            required : true,
            showError : false,
            readOnly: true,
        },
        stock_minimal : {
            name : 'stock_minimal',
            col : 'lg-6',
            value : '',
            label : 'Stock Minimal',
            tipe: 'text',
            show: true,
            required : true,
            showError : false
        },        
        tipe_barang : {
            name : 'tipe_barang',
            col : 'lg-6',
            value : 1,
            label : 'Tipe Barang',
            tipe: '1',
            show: false,
            required : true,
            showError : false
        },
        account_persediaan : {
            name : 'account_persediaan',
            col : 'lg-6',
            value : '',
            label : 'Account Persediaan',
            tipe: 'text',
            show: false,
            required : false,
            showError : false,
            readOnly : true
        },
        account_persediaan_display : {
            name : 'account_persediaan_display',
            col : 'lg-6',
            value : '',
            label : 'Account Persediaan',
            tipe: 'input_button',
            show: true,
            required : false,
            showError : false,
            readOnly : true
        },
    })
    const [satuanDefault, setSatuanDefault] = useState(0);
    const [formTab, setFormTab] = useState('form');
    const [formTmpSatuan, setFormTmpSatuan] = useState({
        satuan_display : {
            name : 'satuan_display',
            value : '',
            tipe : 'input_button',
            required : true,
            label : 'Satuan',
            showError : false,
            show: true,
            readOnly : true
        },
        satuan : {
            name : 'satuan',
            value : '',
            tipe : 'text',
            required : true,
            label : 'Satuan',
            showError : false,
            show: true,
            readOnly : true
        },
        nilai : {
            name : 'nilai',
            value : '',
            tipe : 'number',
            required : true,
            label : 'Nilai Konversi',
            showError : false,
            show: true,
            className : 'text-right'
        },
    })
    const [listSatuan, setListSatuan] = useState([]);

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        try{
            let params = { 
                q : q,
                offset : offset,
                limit : limit
            }
            const res = await api.get(`${config.endPoint.sparePart}`, {params: params}).then(res => res.data);
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }));
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            alertDispatch({
                type: 'SHOW',
                color : 'danger',
                text : 'Unable to fetch data'
            })
            setTimeout(() => {
                alertDispatch({
                    type: 'HIDE',
                })
            }, config.timeOutValue)
        }
    },[alertDispatch, setIsLoading, setPageValue])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleEdit = (post) => {
        setFormData(formData => ({...formData,            
            id : {...formData.id, value : post.id},
            kategori : {...formData.kategori, value : post.kategori},
            kategori_display : {...formData.kategori_display, value : post.kategori_display.nama},
            kode : {...formData.kode, value : post.kode},
            nama : {...formData.nama, value : post.nama},
            satuan : {...formData.satuan, value : post.satuan},
            satuan_display : {...formData.satuan_display, value : post.satuan_display.nama},
            stock_minimal : {...formData.stock_minimal, value : post.stock_minimal},            
            account_persediaan : {...formData.account_persediaan, value : post.account_persediaan_display ? post.account_persediaan_display.id : ''},
            account_persediaan_display : {...formData.account_persediaan_display, value : post.account_persediaan_display ? `${post.account_persediaan_display.noakun} - ${post.account_persediaan_display.nmakun}` : ''},
            tipe_barang : {...formData.tipe_barang, value : 1}
        }));
        setIsEdit(true);
        setView('create');
        setListSatuan(post.satuan_obj);
        setSatuanDefault(post.satuan);
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            text : `Apakah anda yakin akan menghapus kategori sparepart ${post.nama} ? `,
            id: post.id,
            title: 'Konfirmasi'
        }))
    }

    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.sparePart}${modalValue.id}/`).then(res => res.data);
            if (res){
                handleCloseModal();
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            alertDispatch({
                type: 'SHOW',
                color : 'danger',
                text : 'Unable to delete data'
            })
            setTimeout(() => {
                alertDispatch({
                    type: 'HIDE',
                })
            }, config.timeOutValue)
        }
    }
    const handlePagination = (page) => {
		let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
		setPageValue(pageValue => ({...pageValue, page: page, offset: myOffset}));		
		fetchData(pageValue.search, myOffset, config.itemPerPage)
	}

    // ========== search ===========
    const handleChangeSearch = (e) => {
        const {name, value} = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}));
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage);
        setPageValue(pageValue => ({...pageValue,
            page : 1,
            offset: 0
        }))
    }

    // ========== create ===========
    const handleClear = () => {
        setFormData(formData => ({...formData,            
            id : {...formData.id, value : 0},
            kategori : {...formData.kategori, value : ''},
            kategori_display : {...formData.kategori_display, value : ''},
            kode : {...formData.kode, value : ''},
            nama : {...formData.nama, value : ''},
            satuan : {...formData.satuan, value : ''},
            satuan_display : {...formData.satuan_display, value : ''},
            stock_minimal : {...formData.stock_minimal, value : ''},            
            account_persediaan : {...formData.account_persediaan, value : ''},
            account_persediaan_display : {...formData.account_persediaan_display, value : ''},
        }));
    }
    
    const handleAdd = () => {
        handleClear();
        setView('create');
        setIsEdit(false);                
    }

    const handleChange = (e) => {
        const { name, value } = e.target;        
        setFormData(handleChangeFormData(formData, name, value))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        setFormData(handleVerificationBlur(formData, name, value));
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let myForm = handleVerificationSubmit(formData);
            if (!myForm.success) {
                setIsLoading(false);
                alertDispatch({
                    type: 'SHOW',
                    text: 'Lengkapi data',
                    color: 'danger'
                })
                setTimeout(() => {
                    alertDispatch({
                        type: 'HIDE',
                    })
                }, config.timeOutValue)
                return;
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(myForm.formData));
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.sparePart}${formData.id.value}/`, newForm).then(res => res.data);                
            }else{
                res = await api.post(`${config.endPoint.sparePart}`, newForm).then(res => res.data);                
            }
            setIsLoading(false);
            if (res) {
                alertDispatch({
                    type: 'SHOW',
                    text: isEdit ? 'Berhasil merubah kategori sparepart' : 'Berhasil menambahkan kategori sparepart',
                    color: 'success'
                })
                setTimeout(() => {
                    alertDispatch({
                        type: 'HIDE',
                    })
                }, config.timeOutValue)
                handleCancel();
                fetchData();
            }
            setFormTab('form');
            
        }catch(error){
            setIsLoading(false);
            alertDispatch({
                type: 'SHOW',
                color : 'danger',
                text : 'Unable to fetch data'
            })
            setTimeout(() => {
                alertDispatch({
                    type: 'HIDE',
                })
            }, config.timeOutValue)
        }
    }

    const handleCancel = () => {
        setIsEdit(false);
        handleClear();
        setView('list');
    }

    // ==================== custom modal =========================
    const handleShowKategori = () => {
        modalDispatch({
            type: 'KATEGORI_SPAREPART',
            onClickTr : handleClickKategori,
            handleClose : closeModalHandler(),
        })
    }
    
    const closeModalHandler = () => {
        modalDispatch({
            type: 'CLOSE',            
        })
    }

    const handleClickKategori = (post) => {        
        setFormData(formData => ({...formData,
            kategori : {...formData.kategori, value : post.id},
            kategori_display : {...formData.kategori_display, value : post.nama},
        }));
        closeModalHandler();
    }
    
    const handleShowSatuan = () => {
        modalDispatch({
            type: 'SATUAN',
            onClickTr : handleClickSatuan,
            handleClose : closeModalHandler(),
            tipeSatuan : 1
        })
    }
    
    const handleClickSatuan = (post) => {        
        setFormData(formData => ({...formData,
            satuan : {...formData.satuan, value : post.id},
            satuan_display : {...formData.satuan_display, value : post.nama},
        }));
        closeModalHandler();
    }

    const handleShowAccount = () => {
        modalDispatch({
            type: 'ACCOUNT',
            onClickTr : handleClickAccount,
            handleClose : closeModalHandler(),
            nature : 'persediaan'
        })
    }
    
    const handleClickAccount = (post) => {        
        setFormData(formData => ({...formData,
            account_persediaan : {...formData.account_persediaan, value : post.id},
            account_persediaan_display : {...formData.account_persediaan_display, value : `${post.noakun} - ${post.nmakun}`},
        }));
        closeModalHandler();
    }

    // =============== form tab ==================
    const handleChangeFormTab = (param) => {
        setFormTab(param)
    }
    // =============== form satuan ==================    
    const handleChangeFormSatuan = (e) => {
        const { name, value } = e.target;
        setFormTmpSatuan(formTmpSatuan => ({...formTmpSatuan,
            [name] : {...formTmpSatuan[name], value : value}
        }))
    }
    
    const handleBlurFormSatuan = (e) => {
        const { name, value } = e.target;
        if (formTmpSatuan[name].required && value === ''){
            setFormTmpSatuan(formTmpSatuan => ({...formTmpSatuan,
                [name] : {...formTmpSatuan[name], showError : true}
            }))
        }else{
            setFormTmpSatuan(formTmpSatuan => ({...formTmpSatuan,
                [name] : {...formTmpSatuan[name], showError : false}
            }))
        }
    }

    const handleDeleteSatuan = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show : true,
            id : post.id,
            text : `Apakah anda yakin akan menghapus satuan ${post.label} produk ${post.produk_name} ?`,
            tipe : 'delete_satuan'         
        }))
    }

    const handleSubmitDeleteSatuan = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.produkSatuan}${modalValue.id}/`).then(res => res.data);
            if (res){
                alertDispatch({
                    type: 'SHOW',
                    color : 'success',
                    text : 'Data Berhasil dihapus'
                })
                setTimeout(() => {
                    alertDispatch({
                        type: 'HIDE',
                    })
                }, config.timeOutValue);
                handleFetchProdukSatuan();
                handleCloseModal();                
            }
            setIsLoading(false);
        }catch(error){
            let msg = "Unable to fetch Data"
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setIsLoading(false);
            alertDispatch({
                type: 'SHOW',
                color : 'danger',
                text : msg
            })
            setTimeout(() => {
                alertDispatch({
                    type: 'HIDE',
                })
            }, config.timeOutValue)
        }
    }

    const handleFetchProdukSatuan = async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.produkSatuan}`, {params: {produk_id : formData.id.value}}).then(res => res.data);
            let tmp = []
            if (res){
                res.results.map(post => {
                    tmp.push({
                        label : post.satuan_display,
                        value : post.satuan,
                        nilai : post.nilai,
                        produk_name : post.produk_display,
                        id : post.id
                    })
                    return true;
                })
                setListSatuan(tmp)
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);            
            alertDispatch({
                type: 'SHOW',
                color : 'danger',
                text : 'Unable to fetch data'
            })
            setTimeout(() => {
                alertDispatch({
                    type: 'HIDE',
                })
            }, config.timeOutValue)
            return
        }
    }

    const handleClearFormSatuan = () => {
        setFormTmpSatuan(formTmpSatuan => ({...formTmpSatuan,
            satuan_display : {...formTmpSatuan.satuan_display, value : ''},
            satuan : {...formTmpSatuan.satuan, value : ''},
            nilai : {...formTmpSatuan.nilai, value : ''},
        }))
    }

    const handleSubmitSatuan = async() => {
        setIsLoading(true);
        try{
            let countError = 0;
            let obj = {...formTmpSatuan}
            let tmpForm = {}

            Object.entries(obj).map(([index, post]) => {
                if (post.value === '' && post.required){
                    countError++;
                    post.showError = true;
                }else{
                    tmpForm[index] = post.value
                }
                return true;
            })

            if (countError > 0){
                setIsLoading(false);
                setFormTmpSatuan(obj);
                alertDispatch({
                    type: 'SHOW',
                    color : 'danger',
                    text : 'Lengkapi Data'
                })
                setTimeout(() => {
                    alertDispatch({
                        type: 'HIDE',
                    })
                }, config.timeOutValue)
                return
            }

            tmpForm['produk'] = formData.id.value;

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));

            const res = await api.post(`${config.endPoint.produkSatuan}`, newForm).then(res => res.data);
            if (res){
                handleFetchProdukSatuan();
                handleClearFormSatuan();
            }
        }catch(error){
            let msg = "Unable to save Data"
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setIsLoading(false);
            alertDispatch({
                type: 'SHOW',
                color : 'danger',
                text : msg
            })
            setTimeout(() => {
                alertDispatch({
                    type: 'HIDE',
                })
            }, config.timeOutValue)
        }
    }

    const handleShowSatuanFormSatuan = () => {
        modalDispatch({
            type: 'SATUAN',
            onClickTr : handleClickSatuanFormSatuan,
            handleClose : closeModalHandler,
            tipeSatuan : 1
        })        
    }

    const handleClickSatuanFormSatuan = (post) => {
        setFormTmpSatuan(formTmpSatuan => ({...formTmpSatuan,
            satuan_display : {...formTmpSatuan.satuan_display, value : post.nama},
            satuan : {...formTmpSatuan.satuan, value : post.id}
        }));
        closeModalHandler();
    }
    
    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal, 
        handleVerificationSubmit, handleVerificationBlur, handleChangeFormData,
        fetchAccount, fetchProdukSatuan, getPermission, getStateColor,
        handleEdit, handleAdd, alertState, listData, handleSubmit, handleDelete, handleSubmitDelete,
        handleChange, handleBlur, handleChangeSearch, handleKeyDownSearch, view, handleCancel,
        handleSearch, formData,
        modalState, modalDispatch, showConfirmCallToAction, setshowConfirmCallToAction,
        handleShowKategori, closeModalHandler, handleShowSatuan, handleShowAccount,
        formTab, handleChangeFormTab, formTmpSatuan, handleChangeFormSatuan, handleBlurFormSatuan,
        listSatuan, handleDeleteSatuan, satuanDefault, handleSubmitSatuan, 
        handleShowSatuanFormSatuan, handleClickSatuanFormSatuan, handleSubmitDeleteSatuan,
        isEdit, handlePagination
    }
}