import React, { Fragment } from 'react';
import { Alert, Button, ButtonGroup, Card, CardBody, CardHeader, CardFooter, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row,  FormGroup, Label } from 'reactstrap';
import PagePagination from '../../../containers/Components/PagePagination';
import { useKendaraan } from './kendaraanFunction';
import SearchComp from '../../../containers/Components/SearchComp';
import FormTextSingleComp from '../../../containers/Components/FormTextSingleComp';

import LoaderComponent from '../../../containers/Components/LoaderComponent';
import { CustomModal } from '../../../containers/Components/CustomModal';
import KendaraanTableComp from './KendaraanTableComp';


const KendaraanPage = (props) => {
    const {  
        alertValue,  handleCloseAlert, modalValue,  isLoading, 
        pageValue, handleCloseModal,
        handleChangeSearch, handleSearch, handleKeyDownSearch, handlePagination,
        formData, handleChange, handleBlur,listData, view,  handleAdd,       
        handleSubmit, handleCancel, handleChangeDate, handleDelete,
        handleEdit, handleSubmitDelete,  modalState, handleShowKendaraan,
        closeModalHandler, 
    } = useKendaraan();

    
    return(
        <Fragment>
            { isLoading && <LoaderComponent/>}
            <Alert isOpen={alertValue.show} toggle={handleCloseAlert} color={alertValue.color}>
                <div className="content" dangerouslySetInnerHTML={{__html: alertValue.text}}></div>                
            </Alert>
            <Modal isOpen={modalValue.show} size='lg' toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>{modalValue.title}</ModalHeader>
                <ModalBody>
                    <Alert isOpen={alertValue.show} color={alertValue.color} toggle={handleCloseAlert}>
                        <div className="content" dangerouslySetInnerHTML={{__html: alertValue.text}}></div>
                    </Alert>
                    { modalValue.text }
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                        <Button color='danger' onClick={handleSubmitDelete} size='sm'>Hapus</Button>
                        <Button color='secondary' onClick={handleCloseModal} size='sm'>Cancel</Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
            <CustomModal
                show={modalState.show}
                size={modalState.size}
                close={closeModalHandler}
                confirmButtonText={modalState.confirmButtonText}
                confirmButtonAction={modalState.confirmButtonAction}
                withCard={modalState.withCard}
                heading={modalState.heading}
            >
                { modalState.withCard ?
                    <>
                        <ModalHeader toggle={closeModalHandler}>
                            {modalState.heading}
                        </ModalHeader>
                        <ModalBody>
                            <>
                                <p>{modalState.content}</p>
                            </>
                        </ModalBody>
                    </>
                    :
                    <>
                        {modalState.content}
                    </>
                }
            </CustomModal>
            <Card>
                <CardHeader>
                    <Row>
                        <Col lg={6}>Kendaraan</Col>
                    </Row>
                </CardHeader>
                <CardBody>
                { ['list'].includes(view) &&
                    <Fragment>
                        <Row>
                            <Col lg={8}>
                                <SearchComp
                                    onChange={handleChangeSearch}
                                    onClick={handleSearch}
                                    onKeyDown={handleKeyDownSearch}
                                    value={pageValue.search}
                                />
                            </Col>
                            <Col lg={4}>
                                <Button color='primary' onClick={handleAdd}><i className='fa fa-plus'></i></Button>
                            </Col>
                        </Row>                        
                        <KendaraanTableComp
                            listData={listData}
                            pageOffset={pageValue.offset}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                            clickTr={props.clickTr}
                        />
                        <PagePagination
                            data={pageValue.obj}
                            lastPage={pageValue.lastPage}
                            page={pageValue.page}
                            handlePagination={handlePagination}
                        />
                    </Fragment>
                }
                { ['create', 'edit'].includes(view) &&
                    <Row>
                        <Col lg={6}>
                            <FormGroup>
                                <Label>{formData.kategori.label}</Label>
                                <FormTextSingleComp
                                    {...formData.kategori}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>{formData.nopol.label}</Label>
                                <FormTextSingleComp
                                    {...formData.nopol}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>{formData.merk.label}</Label>
                                <FormTextSingleComp
                                    {...formData.merk}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>{formData.tipe.label}</Label>
                                <FormTextSingleComp
                                    {...formData.tipe}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>{formData.tahun_perakitan.label}</Label>
                                <FormTextSingleComp
                                    {...formData.tahun_perakitan}
                                    onChange={handleChange}
                                    disableSaparator={true}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>{formData.chasis.label}</Label>
                                <FormTextSingleComp
                                    {...formData.chasis}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <Label>{formData.engine.label}</Label>
                                <FormTextSingleComp
                                    {...formData.engine}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>{formData.kapasitas.label}</Label>
                                <FormTextSingleComp
                                    {...formData.kapasitas}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>{formData.tanggal_kir.label}</Label>
                                <FormTextSingleComp
                                    {...formData.tanggal_kir}
                                    onChangeDate={handleChangeDate}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>{formData.tanggal_pkb.label}</Label>
                                <FormTextSingleComp
                                    {...formData.tanggal_pkb}
                                    onChangeDate={handleChangeDate}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>{formData.tangki_display.label}</Label>
                                <FormTextSingleComp
                                    {...formData.tangki_display}
                                    onClick={handleShowKendaraan}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                }
                </CardBody>
                {
                    ['create', 'edit'].includes(view) &&
                    <CardFooter>
                        <ButtonGroup>
                            <Button size='sm' color='primary' onClick={handleSubmit}>Simpan</Button>
                            <Button size='sm' color='secondary' onClick={handleCancel}>Cancel</Button>
                        </ButtonGroup>
                    </CardFooter>
                }
            </Card>
        </Fragment>
    )
}
export default KendaraanPage;